import { ContainerSpinner } from '@topremit/shared-web/components/elements';
import dynamic from 'next/dynamic';

export { default as CalculatorAirtime } from './CalculatorAirtime/CalculatorAirtime';
export { default as CalculatorRemit } from './CalculatorRemit';
export { default as ChooseRecipientCard } from './ChooseRecipientCard';
export { default as ChooseServiceCard } from './ChooseServiceCard';
export { default as ChooseServiceModalBody } from './ChooseServiceModalBody';
export { default as ChooseServiceModalFooter } from './ChooseServiceModalFooter';
export { default as DealingRateField } from './DealingRateField';
export { default as RecipientPayersMaintenanceNote } from './RecipientPayersMaintenanceNote';
export * from './modal-body';

export const QuotationOtherDetailsModalBody = dynamic(
  () => import('./QuotationOtherDetailsModalBody'),
  { loading: () => <ContainerSpinner /> },
);
export const SoftLimitVolumeModalBody = dynamic(
  () => import('./SoftLimitVolumeModalBody'),
  { loading: () => <ContainerSpinner /> },
);
export const HardLimitVolumeModalBody = dynamic(
  () => import('./HardLimitVolumeModalBody'),
  { loading: () => <ContainerSpinner /> },
);
export const SoftLimitFrequencyModalBody = dynamic(
  () => import('./SoftLimitFrequencyModalBody'),
  { loading: () => <ContainerSpinner /> },
);
export const HardLimitFrequencyModalBody = dynamic(
  () => import('./HardLimitFrequencyModalBody'),
  { loading: () => <ContainerSpinner /> },
);
export const RateHistoryModal = dynamic(() => import('./RateHistoryModal'));
