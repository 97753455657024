import { useShallow } from 'zustand/react/shallow';

import { useDomesticFlowStore } from './use-domestic-flow-store';
import useDomesticServices from './use-domestic-services';
import { getIdByDeliverySpeed } from '../helper';

export default function useDomesticRecipientIdAndPayerId() {
  const { recipient, deliverySpeed } = useDomesticFlowStore(
    useShallow((s) => s),
  );

  const services = useDomesticServices();
  const servicesData = services.data?.data;

  const recipientIdByDeliverySpeed = getIdByDeliverySpeed({
    recipient,
    idType: 'recipientId',
    services: servicesData,
    routingChannel: deliverySpeed.routingChannel,
  });

  const payerIdByDeliverySpeed = getIdByDeliverySpeed({
    recipient,
    idType: 'payerId',
    services: servicesData,
    routingChannel: deliverySpeed.routingChannel,
  });

  return {
    recipientIdByDeliverySpeed,
    payerIdByDeliverySpeed,
  };
}
