import { useGetMe } from '@api-hooks/common';
import { useGetCountriesCurrencies } from '@api-hooks/transaction';
import { cx } from '@emotion/css';
import { ServiceId } from '@topremit/shared-web/api-hooks/common';
import { PersonalQuotationFormValues } from '@topremit/shared-web/api-hooks/transaction';
import { isStringEmpty } from '@topremit/shared-web/common/helper';
import {
  ChooseCountryCurrency,
  Divider,
  CountryCurrencyFlag,
  ErrorMessage,
  NumberInput,
  Flex,
} from '@topremit/shared-web/components/elements';
import { ICountryCurrencyValue } from '@topremit/shared-web/components/elements/fields/choose-country-currency/types';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useFormContext, useWatch } from 'react-hook-form';

import { calculatorAmountField as styled } from './styled';

interface ICalculatorAmountField {
  destinationCountry?: string;
  isDisabled?: boolean;
  errorMessage?: string;

  onChangeAmountSend?: () => void;
  onChangeAmountReceive?: () => void;

  onFocusAmountSend?: () => void;
  onFocusAmountReceive?: () => void;
}

/** @deprecated use `CurrencyExchangeField` component instead. */
export default function CalculatorAmountField({
  isDisabled,
  errorMessage,
  onChangeAmountSend = () => {},
  onChangeAmountReceive = () => {},
  onFocusAmountSend = () => {},
  onFocusAmountReceive = () => {},
}: ICalculatorAmountField) {
  const { t } = useTranslation();
  const router = useRouter();

  const isLandingSwift = router.pathname === '/swift-transfer';

  const countryAndCurrency = (useWatch<PersonalQuotationFormValues>({
    name: 'countryAndCurrency',
  }) || {
    country: undefined,
    currency: undefined,
  }) as ICountryCurrencyValue;

  const { setValue } = useFormContext<PersonalQuotationFormValues>();

  const isDashboardCalculator = router.pathname.includes('/quote');

  const { data: countriesAndCurrencies } = useGetCountriesCurrencies(
    {
      serviceId: isLandingSwift ? ServiceId.SWIFT : undefined,
      currency: isLandingSwift ? 'USD' : undefined,
    },
    {
      queryKey: isLandingSwift
        ? 'countries-currencies-swift'
        : 'countries-currencies-single',
    },
  );

  const { data: me } = useGetMe();

  // use recent countries because it's single remit
  const { recentTransCountries: recentCountriesOptions } = me?.data || {};

  return (
    <>
      <div
        className={cx(
          styled.currencyWrapper(!isStringEmpty(errorMessage)),
          'calculator-remit-amount-wrapper',
        )}
      >
        <div className={styled.sourceAmountWrapper(isDisabled)}>
          <CountryCurrencyFlag
            currencyCode="IDR"
            flagCode="id"
            className={styled.currencyFieldFlag}
          />
          <NumberInput
            name="amountSend"
            label={t('send_amount')}
            disabled={isDisabled}
            withThousandSeparator
            bordered={false}
            allowDecimal={false}
            className={styled.rightAlign}
            onChange={onChangeAmountSend}
            onFocus={onFocusAmountSend}
          />
        </div>

        {/** quickfix: divider margin show outside the parent */}
        <Flex style={{ padding: '0 16px' }}>
          <Divider variant="dashed" my={0} />
        </Flex>

        <div className={styled.receiveAmountWrapper}>
          <ChooseCountryCurrency
            value={countryAndCurrency}
            onValueChange={(val) => setValue('countryAndCurrency', val)}
            disabled={isDisabled}
            options={countriesAndCurrencies?.data}
            label={t('choose_country')}
            className={styled.selectCountry(
              !!countryAndCurrency.country,
              isDashboardCalculator,
            )}
            bordered={false}
            recentCountries={recentCountriesOptions}
            showLabelWhenHasValue={false}
          />
          <div className={styled.receiveAmountInputWrapper}>
            <NumberInput
              name="amountReceive"
              label={t('receive_amount')}
              disabled={isDisabled}
              bordered={false}
              onChange={onChangeAmountReceive}
              withThousandSeparator
              decimalScale={2}
              className={cx(
                styled.rightAlign,
                styled.currencyFieldLanding,
                styled.receiveAmount,
              )}
              onFocus={onFocusAmountReceive}
            />
          </div>
        </div>
      </div>

      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
}
