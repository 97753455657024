import { TransactionMode } from '@topremit/shared-web/api-hooks/common';
import { useShallow } from 'zustand/react/shallow';

import {
  IRecipientDocumentStore,
  useDomesticFlowStore,
} from './use-domestic-flow-store';

export default function useDomesticType() {
  const { setField, type } = useDomesticFlowStore(useShallow((s) => s));

  const isBulk = type === TransactionMode.BULK;

  function handleTypeChange(type: IRecipientDocumentStore['type']) {
    setField('type', type);
  }

  return {
    type,
    isBulk,
    handleTypeChange,
  };
}
