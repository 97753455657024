import { css } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { Button } from '@topremit/shared-web/components/elements';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

import ChangeAmount from './ChangeAmount';
import SpeedInfoCard from './SpeedInfoCard';

interface IDeliverySpeedChangeConfirmationModalBodyProps {
  routingChannel: RoutingChannelType;
  minPayerLimit: number;
  payerLimit: number;
}

const PAYER_ROUTING_CHANNEL_TYPE = 'PAYER_ROUTING_CHANNEL_TYPE';

export default function DeliverySpeedChangeConfirmationModalBody({
  routingChannel,
  minPayerLimit,
  payerLimit,
}: IDeliverySpeedChangeConfirmationModalBodyProps) {
  const { t } = useTranslation('calculator');
  const { addNotification } = useNotification();

  const pushDialog = useDialogStore((store) => store.push);
  const closeDialog = useDialogStore((store) => store.close);

  const {
    quotation,
    setStep,
    setPayerId,
    setSelectedRecipientId,
    setHasPayerSpeed,
  } = useRecipient();

  const speed = t(
    `conflict_modal.routing_channel_type.${quotation.routingChannel.toLowerCase()}`,
  );

  function onChangeDeliverySpeed() {
    closeDialog();
    addNotification({
      message: t('conflict_modal.speed_changed.speed_changed_toast'),
      type: 'success',
    });
    setPayerId(null);
    setStep(ChooseRecipientStep.CHOOSE);
    setSelectedRecipientId(0);
    setPayerId(null);
    setHasPayerSpeed(false);
    localStorage.setItem(PAYER_ROUTING_CHANNEL_TYPE, routingChannel);
  }

  function onChangeAmount() {
    pushDialog({
      body: (
        <ChangeAmount
          channel={routingChannel}
          minPayerLimit={minPayerLimit}
          payerLimit={payerLimit}
        />
      ),
    });
  }

  return (
    <div className={styled.root}>
      <h5 className="title">
        {t('conflict_modal.change_speed_confirmation.title')}
      </h5>
      <div className="description">
        {t('conflict_modal.change_speed_confirmation.description', {
          currency: quotation.destinationCurrency,
          amount: new Intl.NumberFormat('en-US').format(payerLimit),
          speed,
        })}
      </div>
      <SpeedInfoCard availableChannel={routingChannel} />
      <div className="action-button">
        <Button fullWidth onClick={onChangeDeliverySpeed}>
          {t(
            'conflict_modal.change_speed_confirmation.action_button.change_delivery_speed',
          )}
        </Button>
        <Button fullWidth type="secondary" onClick={onChangeAmount}>
          {t(
            'conflict_modal.change_speed_confirmation.action_button.change_amount',
          )}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    .title {
      margin-bottom: 0.5rem;
    }
    .description {
      color: var(--neutral-500);
      margin-bottom: 1rem;
    }
    .action-button {
      margin-top: 1.5rem;
      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  `,
};
