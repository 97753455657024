import { RoutingChannel } from '@api-hooks/transaction';
import { Status } from '@topremit/shared-web/components/elements';
import { DelayFill, Thunder } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';

export default function BankSpeedPills({
  routingChannel,
}: {
  routingChannel: RoutingChannel;
}) {
  const { t } = useTranslation();

  const { isMaintenance, type } = routingChannel || {};

  const icon = getIcon();
  const message = getMessage();

  function getIcon() {
    if (isMaintenance) {
      return <DelayFill width={16} height={16} />;
    }
    if (type === 'INSTANT') {
      return <Thunder />;
    }
    return null;
  }

  function getMessage() {
    if (type === 'INSTANT') {
      return t('instant_speed');
    }
    return t('normal_speed');
  }

  return (
    <Status
      key={type}
      icon={icon}
      size="small"
      message={message}
      color={isMaintenance ? 'yellow' : 'blue'}
    />
  );
}
