/* eslint-disable no-case-declarations */
import { useGetCountry } from '@api-hooks/common';
import { useValidateRecipientDetails } from '@api-hooks/recipient';
import { DynamicForm, Recipient } from '@api-hooks/transaction';
import { queryClient } from '@common/client';
import { SYNC_FORM_KEY } from '@common/storage';
import { css, cx } from '@emotion/css';
import { ChooseRecipientStep, usePermission, useRecipient } from '@hooks';
import { useCurrentAccountStore } from '@stores';
import { ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  PayersModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  getNonEmptyStringObject,
  isEmptyObject,
} from '@topremit/shared-web/common/helper';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import {
  Button,
  ContainerSpinner,
  DynamicInput,
  Flex,
  Notes,
  parseHtml,
  PhoneNumberInputV2,
  RadioInput,
  Text,
} from '@topremit/shared-web/components/elements';
import { InterrogationOutline } from '@topremit/shared-web/components/shapes';
import {
  useGlobalState,
  useNotification,
  useShallowEffect,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import humps from 'humps';
import _get from 'lodash/get';
import Link from 'next/link';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { ConflictDialogModalBody } from '..';
import { getCanShowField } from '../helper';
import { RecipientFormDetails, RecipientType } from '../types';

const PREV_STEP = 'PREV_STEP';
const PAYER_ROUTING_CHANNEL = 'PAYER_ROUTING_CHANNEL';
export interface IRecipientDetailsProps {
  form?: DynamicForm;
  isFetching: boolean;
}

export default function RecipientDetails({
  form,
  isFetching,
}: IRecipientDetailsProps) {
  const { t } = useTranslation('calculator');
  const { addNotification } = useNotification();
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { isBusinessAccount, isPersonalAccount } = usePermission();

  const { sections } = form || {};

  const showDialog = useDialogStore((store) => store.show);
  const pushDialog = useDialogStore((store) => store.push);
  const popDialog = useDialogStore((store) => store.pop);

  const formContext = useFormContext<RecipientFormDetails>();
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = formContext;

  const currentAccount = useCurrentAccountStore((s) => s.currentAccount);
  const senderSegment = currentAccount?.type;

  const { beneficiary, segment: recipientSegment } = watch();

  const strValues = JSON.stringify(watch());

  const errorFieldsList = useRef<string[]>([]);

  const _values = useMemo(
    () => getNonEmptyStringObject(watch()) as Recipient,
    [strValues],
  );

  const { data: country, refetch: refetchCountry } = useGetCountry(
    watch('destinationCountry'),
  );

  const {
    quotation,
    isValidatingBankCode,
    setStep,
    setPayerId,
    setRecipient,
    setHasPayerSpeed,
    setSelectedRecipientId,
  } = useRecipient();

  const { referenceId, destinationAmount, routingChannel } = quotation || {};

  const beneficiarySegment = globalState.get('beneficiary-segment');
  const syncFormValues = globalState.get(SYNC_FORM_KEY);
  const payerRoutingChannel = JSON.parse(
    localStorage.getItem(PAYER_ROUTING_CHANNEL) as string,
  );

  const strSyncFormValues = JSON.stringify(syncFormValues);

  const {
    mutateAsync: validateRecipientDetails,
    isLoading: isLoadingValidateRecipientDetails,
  } = useValidateRecipientDetails(referenceId, {
    onSuccess: () => {
      setHasPayerSpeed(true);
      setStep(ChooseRecipientStep.ADDITIONAL);
      setRecipient(_values);
    },
    onError: ({ message, errors }) => {
      clearErrors();
      errorFieldsList.current = [];
      if (!isEmptyObject(errors)) {
        Object.entries(errors).forEach(([key, value]: any[]) => {
          if (typeof value === 'object') {
            Object.entries(value).forEach(([childKey]) => {
              errorFieldsList.current.push(`${key}.${childKey}`);
              setError(`${key}.${childKey}`, {
                message: value[childKey],
              });
            });
          } else if (typeof key === 'string') {
            errorFieldsList.current.push(key);
            setError(key, { message: value });
          }
        });
      }
      if (isNativeApp()) {
        sendNativeMessage({
          name: NativeWebViewBridgeEventName.ALERT,
          data: {
            message,
            variant: 'danger',
          },
        });
        return;
      }
      addNotification({ type: 'danger', message });
    },
  });

  const payers = queryClient.getQueryData<ApiResult<PayersModel[]>>([
    'payers',
    'payers',
    {
      url: 'payers',
      service_id: quotation.serviceId,
      destination_country: quotation.destinationCountry,
      destination_currency: quotation.destinationCurrency,
    },
  ]);

  function onSelectItemPhoneNumberField(value) {
    const { beneficiary, dialCodeCountry, ...formRestProps } = watch();
    const _beneficiary = {
      ...{
        ...beneficiary,
        ...(dialCodeCountry !== value?.dialCode ? { contactNumber: '' } : {}),
      },
    };
    dispatchGlobalState({
      type: 'ADD',
      key: SYNC_FORM_KEY,
      payload: {
        ...formRestProps,
        ...syncFormValues,
        dialCodeCountry: value?.dialCode,
        beneficiary: _beneficiary,
      },
    });
  }

  function onSelectItemDynamicField(fieldName: string, value: string) {
    dispatchGlobalState({
      type: 'ADD',
      key: SYNC_FORM_KEY,
      payload: {
        ...syncFormValues,
        ...watch(),
        [fieldName]: value,
      },
    });
  }

  function renderContent() {
    if (isFetching && sections?.length === 0) {
      return <ContainerSpinner />;
    }

    return sections?.map(({ title, note, fields }, idx) => (
      <Flex key={idx} column className={styled.section}>
        <Text>{title}</Text>
        {renderSectionNote(note)}
        <Flex column>{renderRowFields(fields)}</Flex>
      </Flex>
    ));
  }

  function renderRowFields(fields) {
    return fields.map((field, idx) => {
      const fieldName = humps.camelize(field.name);
      const helperText =
        beneficiary?.segment === 'BUSINESS' && field?.businessNote
          ? field?.businessNote
          : field?.note;
      const apiParams =
        field.type === 'api'
          ? field.fields.reduce((prev, next) => {
              const fieldName = humps.camelize(next);
              const _value = watch(fieldName);

              return {
                ...prev,
                [fieldName]: _value,
              };
            }, {})
          : null;

      const isFieldHidden = field.hidden;
      const renderSenderField = isBusinessAccount
        ? ['ALL', 'BUSINESS']
        : isPersonalAccount
          ? ['ALL', 'PERSONAL']
          : [];
      const renderRecipientField = ['ALL', beneficiary.segment];

      if (isFieldHidden) {
        return;
      }

      // Note: handled sender segment according to current account info
      // Note: also handle recipient segment according choosen beneficiarySegment
      if (
        !(
          renderSenderField.includes(field.senderSegment) &&
          renderRecipientField.includes(field.recipientSegment)
        )
      ) {
        return;
      }

      if (field?.conditions) {
        const canShowField = getCanShowField({
          conditions: field.conditions,
          senderSegment: senderSegment as RecipientType,
          recipientSegment,
          values: watch(),
        });

        if (!canShowField) {
          return null;
        }
      }

      switch (field.type) {
        case 'radio':
          if (!_get(watch(), humps.camelize(fieldName), '')) {
            setValue(fieldName, field.options[0].value);
          }
          return (
            <RadioInput
              optionType="card"
              name={fieldName}
              key={idx}
              id={fieldName}
              size="small"
              {...field}
            />
          );
        case 'phone':
          const dialCodeDefaultCountry = field.isoCode;

          return (
            <PhoneNumberInputV2
              {...field}
              id={fieldName}
              key={idx}
              name={fieldName}
              className="dynamic-field"
              helperText={helperText && parseHtml(helperText)}
              dialCodeOptions={country?.data}
              disabledSelectCountry={!field.selectable}
              value={watch(fieldName)}
              defaultValue={{
                dialCodeCountry: dialCodeDefaultCountry,
              }}
              control={formContext.control}
              onChangeCountry={onSelectItemPhoneNumberField}
            />
          );
        default:
          const className = cx('dynamic-field', {
            'change-class': fieldName === 'payerId',
          });
          const rightSection = (() => {
            if (!field.faqLink) {
              return;
            }
            return (
              <Link
                href={field.faqLink}
                target="_blank"
                prefetch={false}
                className="field-right-icon"
              >
                <InterrogationOutline />
              </Link>
            );
          })();

          return (
            <DynamicInput
              {...field}
              id={fieldName}
              key={idx}
              name={fieldName}
              params={apiParams}
              className={className}
              rightSection={rightSection}
              autoCloseBottomSheet={false}
              isOptional={!field.required}
              showSearch={field.searchable}
              helperText={helperText && parseHtml(helperText)}
              onSelectItem={(value) =>
                onSelectItemDynamicField(fieldName, value)
              }
              {...(humps.camelize(field.name) === 'payerId' && {
                onClick: () => {
                  setStep(ChooseRecipientStep.PAYERS);
                },
              })}
              control={formContext?.control}
            />
          );
      }
    });
  }

  function renderSectionNote(note) {
    const showNotes =
      note
        ?.filter(
          ({ senderSegment }) =>
            senderSegment === 'ALL' || senderSegment === 'PERSONAL',
        )
        .map(({ recipientSegment, senderSegment, text }, index) => ({
          recipientSegment,
          senderSegment,
          text,
          index,
        })) || [];

    if (showNotes.length === 0) {
      return;
    }

    return showNotes.map(
      ({ recipientSegment, text, index }) =>
        (recipientSegment === watch().beneficiary?.segment ||
          recipientSegment === 'ALL') && (
          <Notes
            showIcon
            key={index}
            title={parseHtml(text)}
            status="info"
            className="notes"
          />
        ),
    );
  }

  async function onContinue() {
    const routingChannels: RoutingChannelType[] = [];
    const payerLimits: number[] = [];
    let isExceedInstantLimit = false;
    let isExceedRegularLimit = false;
    let isExceedAllSpeedLimit = false;

    payerRoutingChannel?.forEach((channel) => {
      const { limit, type } = channel || {};

      if (!payerLimits.includes(limit)) {
        payerLimits.push(limit);
      }

      if (type === RoutingChannelType.INSTANT) {
        isExceedInstantLimit = limit < destinationAmount;
      } else if (type === RoutingChannelType.REGULAR) {
        isExceedRegularLimit = limit < destinationAmount;
      }

      routingChannels.push(type);
    });

    if (isExceedInstantLimit && isExceedRegularLimit) {
      isExceedAllSpeedLimit = true;
    }

    const isRoutingChannelMatched = routingChannels.includes(routingChannel);

    await validateRecipientDetails(_values);

    renderConflictDialogModal({
      routingChannels,
      payerLimits,
      isExceedAllSpeedLimit,
      isRoutingChannelMatched,
    });
  }

  function onCancelConflictDialog() {
    popDialog();
    setPayerId(null);
    setRecipient(null);
    setSelectedRecipientId(0);
    setStep(ChooseRecipientStep.CHOOSE);
  }

  function renderConflictDialogModal({
    routingChannels,
    payerLimits,
    isExceedAllSpeedLimit,
    isRoutingChannelMatched,
  }: {
    routingChannels: RoutingChannelType[];
    payerLimits: number[];
    isExceedAllSpeedLimit: boolean;
    isRoutingChannelMatched: boolean;
  }) {
    const selectedRoutingChannelIdx = routingChannels.findIndex(
      (routingChannel) => routingChannel === quotation.routingChannel,
    );

    if (payerLimits.length === 1 || isExceedAllSpeedLimit) {
      // show limit or speed not supported modal if limit exceeded
      // or speed not supported by selected payer
      if (destinationAmount <= payerLimits[0] && isRoutingChannelMatched) {
        return;
      }

      pushDialog({
        body: (
          <ConflictDialogModalBody
            routingChannel={routingChannels}
            payerLimit={payerLimits[selectedRoutingChannelIdx]}
          />
        ),
        onClose: onCancelConflictDialog,
      });
    }
  }

  useShallowEffect(() => {
    if (isEmptyObject(country)) {
      refetchCountry();
    }
  }, [watch('destinationCountry')]);

  useEffect(() => {
    setValue('beneficiary.segment', beneficiarySegment);
    localStorage.setItem(PREV_STEP, ChooseRecipientStep.ADD);
  }, []);

  useShallowEffect(() => {
    if (!isEmptyObject(errors) && errorFieldsList.current.length > 0) {
      const fieldError = document.getElementById(errorFieldsList.current[0]);
      if (fieldError) {
        fieldError.scrollIntoView({
          block: 'center',
        });
      }
    }
  }, [errors]);

  useEffect(
    function syncFormValuesFromBottomSheet() {
      if (syncFormValues) {
        Object.keys(syncFormValues).forEach((key) => {
          setValue(key, syncFormValues[key]);
        });
      }

      return () => {
        dispatchGlobalState({ type: 'REMOVE', key: SYNC_FORM_KEY });
      };
    },
    [strSyncFormValues],
  );

  useEffect(() => {
    if (payers?.data && payers.data.length === 1) {
      const _routingChannels = JSON.stringify(payers.data[0].routingChannels);
      localStorage.setItem(PAYER_ROUTING_CHANNEL, _routingChannels);
      setValue('payerId', payers.data[0].id);
      setPayerId(payers.data[0].id);
    }
  }, [payers?.data]);

  useEffect(() => {
    showDialog((props) => ({
      ...props,
      footer: (
        <Button
          fullWidth
          disabled={isValidatingBankCode || !watch('payerId')}
          isLoading={isLoadingValidateRecipientDetails}
          onClick={onContinue}
        >
          {t('common:continue')}
        </Button>
      ),
    }));
  }, [isLoadingValidateRecipientDetails, watch(), isValidatingBankCode]);

  return (
    <div className={styled.root}>
      <Text as="h5">{t('add_new_recipient.title')}</Text>
      {renderContent()}
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    h5 {
      margin: 0 1.125rem 1.5rem 0;
    }
    .change-class {
      > .input-wrapper {
        background-color: transparent;
        .form-control {
          .ant-input-suffix {
            > span {
              transform: rotate(-90deg) !important;
            }
          }
        }
      }
    }
  `,
  section: css`
    .notes {
      margin-bottom: 1rem;
    }
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .text {
      margin-bottom: 1rem;
      font-weight: var(--bold-font-weight);
    }
    .form-group {
      :not(:last-child) {
        margin: 0 0 1rem;
      }
      :last-child {
        :not(.select-searchbar) {
          margin: 0;
        }
      }
      .input-wrapper {
        .field-right-icon {
          display: flex;
          align-items: center;
          padding-right: 20px;
          height: 100%;
          z-index: 1;
        }
      }
    }
  `,
};
