import { useGetRatesHistory } from '@api-hooks/transaction';
import { mulish } from '@common/font';
import { css } from '@emotion/css';
import { useRecipient } from '@hooks';
import { Arrow } from '@shapes';
import { HolidayComparisonRateInfo } from '@topremit/shared-web/api-hooks/transaction';
import { formatDate } from '@topremit/shared-web/common/helper';
import { Notes, parseHtml } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

import { HolidayType } from './RateWrapper';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface IRateHistoryModalProps {
  currency: string;
  holidayComparisonRateInfo?: HolidayComparisonRateInfo;
}

export default function RateHistoryModal({
  currency,
  holidayComparisonRateInfo,
}: IRateHistoryModalProps) {
  const { t } = useTranslation('calculator');
  const [labels, setLabels] = useState<string[]>([]);
  const [chartData, setChartData] = useState<number[]>([]);
  const { destinationCurrency } = useRecipient();

  const isRenderNotes =
    holidayComparisonRateInfo &&
    holidayComparisonRateInfo.type === HolidayType.SUPERIOR;

  const mulishFont = mulish.style.fontFamily; //needed for mulish font in canvas

  const { data: rates } = useGetRatesHistory();
  const ratesHistory = rates?.data;

  useEffect(() => {
    if (ratesHistory) {
      const label: string[] = [];
      const datas: number[] = [];
      ratesHistory.forEach(({ fxRate, createdAt }) => {
        label.push(
          formatDate({ date: new Date(createdAt), formatDate: 'dd MMM' }),
        );
        datas.push(fxRate);
      });
      setLabels(label);
      setChartData(datas);
    }
  }, [ratesHistory]);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: chartData,
        fill: false,
        borderColor: color.blue500,
        tension: 0.4,
        pointRadius: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 5,
          maxRotation: 0,
          color: '#8295B5',
          font: {
            family: mulishFont,
          },
        },
        grid: { display: false },
      },
      y: {
        display: true,
        ticks: {
          maxTicksLimit: 5,
          padding: 10,
          color: '#8295B5',
          font: {
            family: mulishFont,
          },
        },
        offset: true,
        grid: {
          drawTicks: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        displayColors: false,
        callbacks: {
          label(context) {
            return `${destinationCurrency} ${new Intl.NumberFormat(
              'en-US',
            ).format(parseFloat(String(context.parsed.y)))}`;
          },
        },
      },
    },
  };
  return (
    <div className={styled.root}>
      <h4 className="title">
        {parseHtml(t('rate_history_modal.title', { currency }))}
      </h4>
      {isRenderNotes && (
        <Notes
          showIcon
          title={holidayComparisonRateInfo.note}
          status="info"
          icon={
            <div>
              <Arrow
                size="small"
                direction="up"
                fill="var(--blue-500)"
                width={16}
                height={16}
              />
            </div>
          }
        />
      )}
      <Line data={data} options={options} />
      <div className="desc sm-text">
        <span>{t('rate_history_modal.description')}</span>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    > .title {
      margin: 1rem 0;
      padding: 0 0.75rem;
    }
    > .desc {
      text-align: center;
      color: var(--text-secondary);
      margin-top: 1rem;
    }
  `,
};
