import { requestFn } from '@common/client';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { useMutation, UseMutationOptions } from 'react-query';

import {
  ICreateRecipientParams,
  IValidateRecipientBatchesParams,
  IValidateRecipientBatchesResponse,
  IValidateRecipientParams,
  IValidateRecipientResponse,
} from './domestic.model';

export function useValidateRecipient(
  options?: UseMutationOptions<
    ApiResult<IValidateRecipientResponse>,
    ApiError,
    IValidateRecipientParams
  >,
) {
  return useMutation<
    ApiResult<IValidateRecipientResponse>,
    ApiError,
    IValidateRecipientParams
  >(
    async (param) =>
      await requestFn(
        { path: 'recipients/validate/account', method: 'post' },
        { json: param },
      ),
    {
      retry: false,
      ...options,
    },
  );
}

export function useCreateRecipient(
  options?: UseMutationOptions<ApiResult, ApiError, ICreateRecipientParams>,
) {
  return useMutation<ApiResult, ApiError, ICreateRecipientParams>(
    async (param) =>
      await requestFn(
        { path: 'recipients/domestic', method: 'post' },
        { json: param },
      ),
    {
      retry: false,
      ...options,
    },
  );
}

export function useHideDomesticCoachMark(
  options?: UseMutationOptions<ApiResult, ApiError>,
) {
  return useMutation<ApiResult, ApiError>(
    async () =>
      await requestFn({ path: 'me/show-domestic-coachmark', method: 'put' }),
    {
      retry: false,
      ...options,
    },
  );
}

export function useValidateRecipientBatches(
  options?: UseMutationOptions<
    ApiResult<IValidateRecipientBatchesResponse>,
    ApiError,
    IValidateRecipientBatchesParams
  >,
) {
  return useMutation<
    ApiResult<IValidateRecipientBatchesResponse>,
    ApiError,
    IValidateRecipientBatchesParams
  >(
    async (param) =>
      await requestFn(
        { path: 'recipient-batches/domestic/validate', method: 'post' },
        { json: param },
      ),
    {
      retry: false,
      ...options,
    },
  );
}
