import { QuotationNote } from '@elements';
import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  COUNTRY_IDN,
  CURRENCY_IDR,
  getValidationSchema,
  useDomesticFlow,
} from '@modules';
import { RecipientPayersMaintenanceNote } from '@modules/landing/jumbotron';
import {
  QuotationNoteModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { MutableRefObject } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import BulkAmountSummaryCard from './BulkAmountSummaryCard';
import ChoosePurpose from './ChoosePurpose';
import DeliverySpeedSelector from './DeliverySpeedSelector';
import HolidayMaintenanceNote from './HolidayMaintenanceNote';
import RecipientCard from './RecipientCard';
import SingleAmountSummaryCard from './SingleAmountSummaryCard';
import TotalAmountButton from './TotalAmountButton';
import { hasMaintenance } from '../helper';
import useCorridorNote from '../use-corridor-note';
import useDomesticRecreate from './hooks/use-domestic-recreate';

interface CalculatorDomesticDashboardProps {
  targetRef: MutableRefObject<null>;
}

export default function CalculatorDomesticDashboard({
  targetRef,
}: CalculatorDomesticDashboardProps) {
  const { t } = useTranslation('transaction');

  useDomesticRecreate();

  const {
    errors: { setError },
    values: {
      amount,
      isBulk,
      recipient,
      deliverySpeed,
      maxLimitAmount,
      minLimitAmount,
      hasChooseRecipient,
      validatedRecipientBatches,
    },
  } = useDomesticFlow();

  const hasChooseDeliverySpeed = !!deliverySpeed.serviceId;
  const hasChooseRecipientAndSpeed =
    hasChooseRecipient && hasChooseDeliverySpeed;

  const { hasCorridorNote } = useCorridorNote({
    destinationCountry: COUNTRY_IDN,
    destinationCurrency: CURRENCY_IDR,
    serviceId: deliverySpeed.serviceId,
    routingChannel: deliverySpeed.routingChannel,
  });

  const amountErrorMessage = t(`domestic.error.amount_limit`, {
    minLimit: formatToIDRCurrency(minLimitAmount),
    maxLimit: formatToIDRCurrency(maxLimitAmount),
  });

  const validationSchema = getValidationSchema({
    t,
    isBulk,
    minLimitAmount,
    maxLimitAmount,
    amountErrorMessage,
  });

  const methods = useForm({
    defaultValues: {
      amount,
    },
    resolver: yupResolver(validationSchema) as any,
    mode: 'onChange',
  });

  const { trigger } = methods;

  const isShowRecipientMaintenanceNote = hasMaintenance(
    recipient?.routingChannels,
  );

  const canShowRecipientMaintenanceNoteSingle =
    !isBulk &&
    hasChooseRecipient &&
    isShowRecipientMaintenanceNote &&
    !hasCorridorNote;

  const canShowRecipientMaintenanceNoteBulk =
    isBulk &&
    hasChooseRecipient &&
    validatedRecipientBatches?.maintenancePayers &&
    validatedRecipientBatches.maintenancePayers.length > 0 &&
    !hasCorridorNote;

  const canShowBulkNote = isBulk && hasChooseRecipientAndSpeed;
  const canShowNormalSpeedNote =
    hasChooseRecipientAndSpeed &&
    deliverySpeed.routingChannel === RoutingChannelType.REGULAR;

  const bulkNote: QuotationNoteModel[] = [
    {
      note: t('domestic.bulk_notes_fee'),
      content: null,
    },
  ];

  const normalNote: QuotationNoteModel[] = [
    {
      note: t('domestic.notes_normal_speed'),
      content: null,
    },
  ];

  function handleClickDeliverySpeedCard() {
    if (!hasChooseRecipient) {
      setError({
        type: 'recipient',
        error: t('domestic.error.choose_recipient_first'),
      });
      return;
    }
    if (!amount) {
      trigger('amount');
    }
  }

  return (
    <FormProvider {...methods}>
      <Flex column>
        {!isBulk && <RecipientCard />}
        {canShowRecipientMaintenanceNoteSingle && (
          <RecipientPayersMaintenanceNote />
        )}
        {isBulk ? <BulkAmountSummaryCard /> : <SingleAmountSummaryCard />}
        {canShowRecipientMaintenanceNoteBulk && (
          <RecipientPayersMaintenanceNote
            isMultiple
            maintenanceBankpayers={validatedRecipientBatches?.maintenancePayers}
          />
        )}
        {hasChooseRecipientAndSpeed && <HolidayMaintenanceNote />}
        <DeliverySpeedSelector onClick={handleClickDeliverySpeedCard} />
        {canShowBulkNote && (
          <QuotationNote className={styles.quotationNote} data={bulkNote} />
        )}
        {canShowNormalSpeedNote && (
          <QuotationNote className={styles.quotationNote} data={normalNote} />
        )}
        {amount && hasChooseRecipientAndSpeed && <ChoosePurpose />}
        <TotalAmountButton targetRef={targetRef} />
      </Flex>
    </FormProvider>
  );
}

const styles = {
  quotationNote: css`
    margin-top: 24px;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin-top: 16px;
    }
  `,
};
