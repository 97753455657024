import { useGetServices } from '@api-hooks/transaction';

import { useDomesticFlowStore } from './use-domestic-flow-store';
import { COUNTRY_IDN, CURRENCY_IDR } from '../constants';

export default function useDomesticServices() {
  const type = useDomesticFlowStore((s) => s.type);

  return useGetServices(
    {
      key: 'domestic-services',
      destinationCountry: COUNTRY_IDN,
      destinationCurrency: CURRENCY_IDR,
      transactionType: type,
    },
    { enabled: true },
  );
}
