import { useGetPurposes } from '@api-hooks/domestic';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';

import { BI_FAST_ID, COUNTRY_IDN, CURRENCY_IDR, Segment } from './constants';
import TransactionPurpose from '../TransactionPurpose';
import useDomesticFlow from './hooks/use-domestic-flow';

function ChoosePurpose() {
  const purposes = useGetPurposes({
    url: 'purposes',
    serviceId: BI_FAST_ID,
    destinationCountry: COUNTRY_IDN,
    destinationCurrency: CURRENCY_IDR,
    beneficiarySegment: Segment.PERSONAL,
  });

  const {
    handlers: { handlePurposeChange },
  } = useDomesticFlow();

  return (
    <TransactionPurpose
      className={styled.root}
      options={purposes?.data?.data}
      isLoading={purposes.isLoading}
      onValueChange={handlePurposeChange}
    />
  );
}

const styled = {
  root: css`
    margin: 24px 0;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin: 16px 0;
    }
  `,
};

export default ChoosePurpose;
