import { useGetAccountInfo } from '@api-hooks/common';
import { useGetDealingCodeQuotation } from '@api-hooks/transaction';
import DealRateTimer from '@modules/deal-rate-banner/DealRateTimer';
import {
  DealRateQuotationModel,
  PersonalQuotationFormValues,
} from '@topremit/shared-web/api-hooks/transaction';
import { IAddOnTextFieldProps } from '@topremit/shared-web/components/elements/AddOnTextField';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useEffect, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useBanner } from './use-banner';
import { useRecipient } from './use-recipient';

interface IDealingRate {
  methods: UseFormReturn<PersonalQuotationFormValues>;
}
interface IDealingRateResponse {
  dealRateData?: DealRateQuotationModel;
  refetchDealingCode: () => void;
  dealingCodeStatus: 'success' | 'disable' | 'active';
  isAppliedDealCode: boolean;
  isLoadingDealingCode: boolean;
}

export function useDealingRate({
  methods,
}: IDealingRate): IDealingRateResponse {
  const { addNotification } = useNotification();
  const { watch, getValues, setValue, setError } = methods;
  const { t, lang } = useTranslation('transaction');
  const { setQuotation } = useRecipient();
  const [isAppliedDealCode, setIsAppliedDealCode] = useState<boolean>(false);

  const {
    showBanner,
    hideBanner,
    changeColor: changeBannerColor,
  } = useBanner();
  const { data: accountInfo } = useGetAccountInfo();
  const hasDealingCode = accountInfo?.data.hasDealingCode;
  const {
    data,
    refetch: refetchDealingCode,
    isLoading,
    isFetching,
  } = useGetDealingCodeQuotation(watch('dealingCode') as string, {
    onSuccess: ({ data }) => {
      const {
        serviceId,
        sourceAmount,
        expirationDate,
        destinationAmount,
        destinationCountry,
        destinationCurrency,
      } = data || {};

      setQuotation((prev) => ({
        ...prev,
        ...data,
        serviceId,
        destinationCurrency,
        destinationCountry,
      }));

      setValue('isSendAmount', true);
      setValue('destinationCountry', destinationCountry);
      setValue('destinationCurrency', destinationCurrency);
      setValue('countryAndCurrency', {
        country: destinationCountry,
        currency: destinationCurrency,
      });
      setValue('amountSend', sourceAmount);
      setValue('amountReceive', destinationAmount);
      setValue('serviceId', serviceId);

      setIsAppliedDealCode(true);

      const expiredTime = expirationDate || '';
      changeBannerColor('neutral');
      showBanner({
        message: <DealRateTimer expiredTime={expiredTime} />,
      });
    },

    onError: ({ message, statusCode }) => {
      if (statusCode === 422) {
        setError('dealingCode', { message });
        return;
      }
      addNotification({ message, type: 'danger' });
    },
  });

  const dealRateData = data?.data;

  useEffect(() => {
    if (!hasDealingCode) {
      hideBanner();
      return;
    }

    if (isAppliedDealCode) {
      const expiredTime = dealRateData?.expirationDate || '';
      showBanner({
        message: <DealRateTimer expiredTime={expiredTime} />,
      });
      return;
    }

    changeBannerColor('neutral');
    showBanner({
      message: t('transaction:deal_rate.active_deal_code'),
    });
  }, [isAppliedDealCode, lang, hasDealingCode]);

  const dealingCodeStatus = ((): IAddOnTextFieldProps['status'] => {
    if (isAppliedDealCode) {
      return 'success';
    }
    if (getValues('dealingCode')) {
      return 'active';
    }
    return 'disable';
  })();

  return {
    dealRateData,
    dealingCodeStatus,
    refetchDealingCode,
    isAppliedDealCode,
    isLoadingDealingCode: isLoading || isFetching,
  };
}
