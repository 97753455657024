import { css, cx } from '@emotion/css';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import { Flex } from '@topremit/shared-web/components/elements';
import { Angle, User, Users } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';

interface RecipientTypeSelectorCardProps {
  className?: string;
  type: 'single' | 'multiple';
  onClick?: () => void;
}

/**
 * A card component for selecting recipient types (single or multiple).
 *
 * @component
 * @example
 * <RecipientTypeSelectorCard
 *   type="single"
 *   onClick={() => console.log('Card clicked')}
 *   className="custom-class"
 * />
 */
function RecipientTypeSelectorCard({
  className,
  type,
  onClick,
}: RecipientTypeSelectorCardProps) {
  const { t } = useTranslation('transaction');

  const recipientData = {
    single: {
      icon: <User size={32} fill="var(--blue-500)" />,
      title: t('domestic.recipient_type.single_recipient'),
      description: t('domestic.recipient_type.single_description'),
    },
    multiple: {
      icon: <Users size={32} fill="var(--blue-500)" />,
      title: t('domestic.recipient_type.multiple_recipient'),
      description: t('domestic.recipient_type.multiple_description'),
    },
  };

  const { icon, title, description } = recipientData[type];

  return (
    <Flex
      align="center"
      onClick={onClick}
      justify="space-between"
      className={cx(styled.root, className)}
    >
      <Flex column>
        <Flex justify="center" align="center" className={styled.icon}>
          {icon}
        </Flex>
        <Flex className={styled.title}>{title}</Flex>
        <Flex className={styled.description}>{description}</Flex>
      </Flex>
      <Angle direction="right" />
    </Flex>
  );
}

const styled = {
  root: css`
    padding: 16px;
    border-radius: 16px;
    background-color: ${color.neutral0};
    border: 1px solid ${color.neutral200};
    cursor: pointer;
    transition:
      box-shadow 0.2s ease,
      border-color 0.2s ease;

    &:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      border-color: ${color.blue200};
    }
  `,
  icon: css`
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: ${color.blue100};
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  title: css`
    margin-top: 12px;
    font-size: ${typography.body2Bold.fontSize}rem;
    font-weight: ${typography.body2Bold.fontWeight};
    line-height: ${typography.body2Bold.lineHeight}px;

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: ${typography.body3Bold.fontSize}rem;
      line-height: ${typography.body3Bold.lineHeight}px;
    }
  `,
  description: css`
    color: ${color.neutral500};
    font-size: ${typography.body3Medium.fontSize}rem;
    line-height: ${typography.body3Medium.lineHeight}px;

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: ${typography.smallMedium.fontSize}rem;
      line-height: ${typography.smallMedium.lineHeight}px;
    }
  `,
};

export default RecipientTypeSelectorCard;
