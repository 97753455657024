/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGetCountries } from '@api-hooks/common';
import {
  useEditRecipient,
  usePostRecipientDetails,
} from '@api-hooks/recipient';
import {
  DynamicFormSection,
  Recipient,
  RoutingChannel,
} from '@api-hooks/transaction';
import { queryClient } from '@common/client';
import { SYNC_FORM_KEY } from '@common/storage';
import { acceptTypeConvert } from '@elements/drag-drop-input/helper';
import { CustomFileType } from '@elements/drag-drop-input/types';
import { css } from '@emotion/css';
import { ChooseRecipientStep, usePermission, useRecipient } from '@hooks';
import { useCurrentAccountStore } from '@stores';
import { ServiceId } from '@topremit/shared-web/api-hooks/common';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import {
  getNonEmptyStringObject,
  isEmptyObject,
  isStringEmpty,
} from '@topremit/shared-web/common/helper';
import {
  Button,
  ContainerSpinner,
  DynamicInput,
  Flex,
  Notes,
  parseHtml,
  PhoneNumberInputV2,
  RadioInput,
  Text,
} from '@topremit/shared-web/components/elements';
import {
  useGlobalState,
  useNotification,
  useShallowEffect,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import humps from 'humps';
import _get from 'lodash/get';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { useUploadRecipientDocument } from '..';
import CheckInvoiceNumberModal from '../CheckInvoiceNumberModal';
import UploadInvoice from '../UploadInvoice';
import {
  DeliverySpeedChangeConfirmationModalBody,
  SpeedChangedModalBody,
} from '../conflict-modal';
import { getCanShowField } from '../helper';
import { RecipientFormDetails, RecipientType } from '../types';
import { RecipientDocumentType } from '../use-upload-recipient-document';

const PREV_STEP = 'PREV_STEP';
const PAYER_ROUTING_CHANNEL = 'PAYER_ROUTING_CHANNEL';
const RECIPIENT_FORM_VALUES = 'RECIPIENT_FORM_VALUES';
const ERRORS = 'ERRORS';

enum Purpose {
  PURCHASE_GOODS_AND_SERVICE = '1',
  PAYROLL_OR_SALARIES = '2',
  SHIPPING_FEE = '12',
}

export interface IAdditionalDetailsProps {
  sections?: DynamicFormSection[];
  isFetching: boolean;
}

export default function AdditionalDetails({
  sections,
  isFetching,
}: IAdditionalDetailsProps) {
  const { t } = useTranslation();
  const {
    quotation,
    isInvoiceChecked,
    selectedRecipientId,
    setStep,
    setPayerId,
    setRecipient,
    setIsSubmitted,
    setIsInvoiceChecked,
    setAdditionalDetails,
    setSelectedRecipientId,
    setPurposeLimit,
    setPayerLimit,
  } = useRecipient();

  const { addNotification } = useNotification();

  const popDialog = useDialogStore((store) => store.pop);
  const showDialog = useDialogStore((store) => store.show);
  const pushDialog = useDialogStore((store) => store.push);
  const closeDialog = useDialogStore((store) => store.close);

  const currentAccount = useCurrentAccountStore((s) => s.currentAccount);
  const senderSegment = currentAccount?.type;

  const formContext = useFormContext<RecipientFormDetails>();
  const { isBusinessAccount, isPersonalAccount } = usePermission();

  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = formContext;

  const {
    description,
    sender,
    beneficiary,
    destinationCountry,
    segment: recipientSegment,
    invoiceFile,
  } = watch();
  const strValues = JSON.stringify(watch());
  const _values = useMemo(
    () =>
      getNonEmptyStringObject(watch()) as Recipient & {
        invoiceFile: CustomFileType[];
      },
    [strValues],
  );

  const errorFieldsList = useRef<string[]>([]);

  const isShowingInvoiceBlockingModal =
    destinationCountry === 'IND' &&
    (beneficiary?.purposeCode === Purpose.PURCHASE_GOODS_AND_SERVICE ||
      beneficiary?.purposeCode === Purpose.SHIPPING_FEE) &&
    beneficiary?.invoiceNumber &&
    !isInvoiceChecked; // IND: India, 1: purchase goods and service, 12: shippping fee

  const { referenceId, routingChannel, destinationAmount, serviceId } =
    quotation || {};

  const [globalState, dispatchGlobalState] = useGlobalState();
  const syncFormValues = globalState.get(SYNC_FORM_KEY);

  const payerRoutingChannel = JSON.parse(
    localStorage.getItem(PAYER_ROUTING_CHANNEL) as string,
  );
  const [isUploading, setIsUploading] = useState(false);
  const [isFromInvoice, setIsFromInvoice] = useState(false);

  const recipientDocumentType: RecipientDocumentType =
    beneficiary?.purposeCode === Purpose.PAYROLL_OR_SALARIES
      ? 'payslip'
      : 'invoice';

  const { file, content, setFile } = useUploadRecipientDocument({
    type: recipientDocumentType,
  });

  const { title, uploaderTitle, description: descDocument, notes } = content;

  const { data: countries } = useGetCountries({
    available: true,
  });

  const {
    mutateAsync: mutateAsyncEditRecipient,
    isLoading: isLoadingEditRecipient,
  } = useEditRecipient(
    {
      id: selectedRecipientId,
      refId: referenceId,
    },
    {
      onMutate: () => {
        clearErrors();
      },
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: ({ message, errors }) => {
        onError(message, errors);
      },
    },
  );

  const {
    mutateAsync: postRecipientDetails,
    isLoading: isLoadingPostRecipientDetails,
  } = usePostRecipientDetails(referenceId, {
    onMutate: () => {
      clearErrors();
    },
    onSuccess: ({ data }) => {
      onSuccess(data);
    },
    onError: ({ message, errors }) => {
      onError(message, errors);
    },
  });

  const isDisabled =
    isStringEmpty(beneficiary?.purposeCode) ||
    isStringEmpty(sender?.fundSource) ||
    isUploading ||
    invoiceFile?.[0]?.state === 'rejected';

  const isLoadingBtnContinue =
    isLoadingEditRecipient || isLoadingPostRecipientDetails || isFromInvoice;

  function onSuccess(data) {
    queryClient.invalidateQueries('available-recipients');
    queryClient.invalidateQueries('recipient');
    const indexPayerLimit = (
      data.routingChannels as RoutingChannel[]
    ).findIndex((value) => value.type === quotation.routingChannel);
    const payerLimit = (data.routingChannels as RoutingChannel[])[
      indexPayerLimit === -1 ? 0 : indexPayerLimit
    ];

    setPurposeLimit(data.purposeLimit ? { maxLimit: data.purposeLimit } : null);
    setPayerLimit({
      ...(payerLimit?.limit && { maxLimit: Number(payerLimit.limit) }),
      ...(payerLimit?.minLimit && { minLimit: Number(payerLimit.minLimit) }),
    });
    setRecipient(data);
    handleSuccess(data);
    setIsSubmitted(true);
    dispatchGlobalState({
      type: 'REMOVE',
      key: RECIPIENT_FORM_VALUES,
    });
  }

  function onError(message: string, errors: { [key: string]: string }) {
    errorFieldsList.current = [];
    if (!isEmptyObject(errors)) {
      Object.entries(errors).forEach(([key, value]) => {
        if (typeof value === 'object') {
          Object.entries(value).forEach(([childKey]) => {
            errorFieldsList.current.push(`${key}.${childKey}`);
            setError(`${key}.${childKey}`, {
              message: value[childKey],
            });
          });
        } else if (typeof key === 'string') {
          errorFieldsList.current.push(key);
          setError(key, { message: value });
        }
      });
    }
    addNotification({ message, type: 'danger' });
  }

  function onSelectItemDynamicField(fieldName: string, value: string) {
    const _syncForm = globalState.get(SYNC_FORM_KEY);

    dispatchGlobalState({
      type: 'ADD',
      key: SYNC_FORM_KEY,
      payload: {
        ..._syncForm,
        ...watch(),
        [fieldName]: value,
      },
    });
  }

  function renderSectionNote(notes) {
    if (!notes || notes.length === 0) {
      return;
    }

    const filteredNotes = notes
      .filter(({ senderSegment }) =>
        ['ALL', beneficiary.segment].includes(senderSegment),
      )
      .map(({ recipientSegment, senderSegment, text }, index) => ({
        text,
        index,
        senderSegment,
        recipientSegment,
      }));

    if (filteredNotes.length === 0) {
      return;
    }

    return filteredNotes.map(
      ({ recipientSegment, text, index }) =>
        ['ALL', beneficiary.segment].includes(recipientSegment) && (
          <Notes
            showIcon
            key={index}
            status="info"
            className={styled.notes}
            title={parseHtml(text)}
          />
        ),
    );
  }

  async function handleMutate() {
    const prevStep = localStorage.getItem(PREV_STEP);
    const toSubmitData = {
      ..._values,
      ...(!!file && {
        invoiceFile: {
          fileName: file.name,
          url: file.url,
          type: file.type,
        },
      }),
      isBatch: false,
    };
    if (prevStep === ChooseRecipientStep.EDIT) {
      await mutateAsyncEditRecipient(toSubmitData);
      return;
    }
    await postRecipientDetails(toSubmitData);
  }

  function handleContinue() {
    if (isShowingInvoiceBlockingModal) {
      setIsInvoiceChecked(true);
      pushDialog({
        body: (
          <CheckInvoiceNumberModal onSuccess={onSuccess} values={_values} />
        ),
        onClose: popDialog,
      });
      return;
    }
    handleMutate();
  }

  function handleSuccess(data) {
    const recipientDetails = {
      description,
      fundSource: sender?.fundSource,
      recipientId: selectedRecipientId,
      purposeCode: beneficiary?.purposeCode,
    };

    dispatchGlobalState({
      type: 'REMOVE',
      key: RECIPIENT_FORM_VALUES,
    });

    setAdditionalDetails({
      ...recipientDetails,
      recipientId: data.id || selectedRecipientId,
    });

    let payerLimit = 0;
    let minPayerLimit = 0;
    let isExceedOtherPayerLimit = false;
    let isExceedSelectedPayerLimit = false;
    const routingChannels: RoutingChannelType[] = [];

    if (payerRoutingChannel && payerRoutingChannel.length > 0) {
      payerRoutingChannel.forEach(({ type, limit, minLimit }) => {
        const isExistRoutingChannelType = routingChannels.includes(type);

        if (type === routingChannel) {
          payerLimit = limit;
          minPayerLimit = minLimit;
          isExceedSelectedPayerLimit = limit < destinationAmount;
        } else {
          isExceedOtherPayerLimit = limit < destinationAmount;
        }

        if (!isExistRoutingChannelType) {
          routingChannels.push(type);
        }
      });

      const isExceedAllSpeedPayer =
        isExceedSelectedPayerLimit && isExceedOtherPayerLimit;

      const otherRoutingChannelOrder = routingChannels.find(
        (_routingChannel) => routingChannel !== _routingChannel,
      );
      const otherRoutingChannelOrderIdx = routingChannels.indexOf(
        otherRoutingChannelOrder as RoutingChannelType,
      );
      if (!isExceedAllSpeedPayer) {
        //show change speed confirmation modal when selected payer limit exceeded
        if (
          isExceedSelectedPayerLimit &&
          serviceId === ServiceId.BANK_TRANSFER
        ) {
          pushDialog({
            body: (
              <DeliverySpeedChangeConfirmationModalBody
                payerLimit={payerLimit}
                minPayerLimit={minPayerLimit}
                routingChannel={routingChannels[otherRoutingChannelOrderIdx]}
              />
            ),
          });
          return;
        }

        //show modal to notify user that speed has changed when selected routing channel not supported by the selected payer
        if (
          !routingChannels.includes(routingChannel) &&
          serviceId === ServiceId.BANK_TRANSFER
        ) {
          pushDialog({
            body: (
              <SpeedChangedModalBody
                routingChannel={routingChannels[otherRoutingChannelOrderIdx]}
              />
            ),
          });
          return;
        }
      }
    }
    closeDialog();
    setPayerId(null);
    setSelectedRecipientId(0);
    setStep(ChooseRecipientStep.CHOOSE);
    setValue('segment', 'PERSONAL');
  }

  function renderContent() {
    if (isFetching && sections?.length === 0) {
      return <ContainerSpinner />;
    }

    return sections?.map((section, idx_section) => (
      <Flex column key={idx_section}>
        {renderSectionNote(section.note)}
        <Flex column className={styled.row}>
          {renderRowFields(section.fields)}
        </Flex>
      </Flex>
    ));
  }

  function renderRowFields(fields) {
    return fields.map((field, idx) => {
      const fieldName = humps.camelize(field.name);
      const helperText = field?.note;
      const apiParams =
        field.type === 'api'
          ? field.fields.reduce((prev, next) => {
              const fieldName = humps.camelize(next);
              const _value = watch(fieldName);
              return {
                ...prev,
                [fieldName]: _value,
              };
            }, {})
          : null;

      const isFieldHidden = field.hidden;
      const renderSenderField = isBusinessAccount
        ? ['ALL', 'BUSINESS']
        : isPersonalAccount
          ? ['ALL', 'PERSONAL']
          : [];
      const renderRecipientField = ['ALL', beneficiary?.segment];

      if (isFieldHidden) {
        return;
      }

      // Note: handled sender segment according to current account info
      // Note: also handle recipient segment according choosen recipient segment
      if (
        !(
          renderSenderField.includes(field.senderSegment) &&
          renderRecipientField.includes(field.recipientSegment)
        )
      ) {
        return;
      }

      if (field?.conditions) {
        const canShowField = getCanShowField({
          conditions: field.conditions,
          senderSegment: senderSegment as RecipientType,
          recipientSegment,
          values: watch(),
        });

        if (!canShowField) {
          return null;
        }
      }

      switch (field.type) {
        case 'radio':
          if (!_get(watch(), humps.camelize(fieldName), '')) {
            setValue(fieldName, field.options[0].value);
          }
          return (
            <RadioInput
              optionType="card"
              name={fieldName}
              key={idx}
              size="small"
              {...field}
            />
          );
        case 'phone':
          return (
            <PhoneNumberInputV2
              {...field}
              key={idx}
              name={fieldName}
              className="dynamic-field"
              dialCodeOptions={countries?.data}
              defaultValue={{
                dialCodeCountry: field.isoCode,
              }}
              control={formContext?.control}
            />
          );
        case 'file':
          const accept = acceptTypeConvert(field?.accept);

          return (
            <UploadInvoice
              value={file}
              title={title}
              notes={notes}
              key={fieldName}
              accept={accept}
              name={fieldName}
              className={styled.invoice}
              description={descDocument}
              uploaderTitle={uploaderTitle}
              maxFileSize={field.maxFileSize}
              control={formContext.control}
              onValueChange={(value) => {
                setFile(value);
                clearErrors(fieldName);
              }}
              onUploading={(value) => {
                setIsUploading(value);
              }}
              onValid={() => {
                clearErrors(fieldName);
              }}
            />
          );
        default:
          const isOptional =
            !field.required &&
            (typeof field.showOptionalLabel !== 'undefined'
              ? field.showOptionalLabel
              : true);
          return (
            <DynamicInput
              {...field}
              key={idx}
              name={fieldName}
              params={apiParams}
              isOptional={isOptional}
              maxLength={field.limit}
              className="dynamic-field"
              autoCloseBottomSheet={false}
              showSearch={field.searchable}
              helperText={helperText && parseHtml(helperText)}
              showCounter={Boolean(field.limit)}
              onSelectItem={(value) =>
                onSelectItemDynamicField(fieldName, value)
              }
              control={formContext?.control}
            />
          );
      }
    });
  }

  useEffect(() => {
    if (!isEmptyObject(errors) && errorFieldsList.current) {
      const fieldError = document.getElementById(errorFieldsList.current[0]);
      if (fieldError) {
        fieldError?.scrollIntoView({ block: 'center' });
      }
    }
  }, [errors]);

  useEffect(() => {
    //handle mutate from check invoice modal
    if (localStorage.getItem(ERRORS)) {
      setIsFromInvoice(true);
      setTimeout(() => {
        handleMutate();
        setIsFromInvoice(false);
      }, 500);
      localStorage.removeItem(ERRORS);
    }
  }, []);

  useShallowEffect(() => {
    if (syncFormValues) {
      Object.keys(syncFormValues).forEach((key) => {
        setValue(key, syncFormValues[key]);
      });
    }

    return () => {
      dispatchGlobalState({ type: 'REMOVE', key: SYNC_FORM_KEY });
    };
  }, [syncFormValues]);

  useEffect(() => {
    showDialog((props) => ({
      ...props,
      footer: (
        <Button
          fullWidth
          disabled={isDisabled}
          isLoading={isLoadingBtnContinue}
          onClick={handleContinue}
        >
          {t('continue')}
        </Button>
      ),
    }));
  }, [isLoadingBtnContinue, isUploading, isDisabled, watch()]);

  return (
    <Flex column className={styled.root}>
      <Text as="h5">{t('calculator:additional_details.title')}</Text>
      {renderContent()}
    </Flex>
  );
}

const styled = {
  root: css`
    height: 100%;
    h5 {
      margin-bottom: 1.5rem;
    }
  `,
  row: css`
    .form-group {
      :not(:last-child) {
        margin: 0 0 1rem;
      }
      :last-child {
        :not(.select-searchbar) {
          margin: 0;
        }
      }
    }
  `,
  notes: css`
    margin-bottom: 1.5rem;
  `,
  invoice: css`
    margin-top: 8px;
  `,
};
