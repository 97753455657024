import { ServiceModel } from '@topremit/shared-web/api-hooks/common';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

import { popUpDeliverySpeedChangedAutomatically } from '../helper';

export default function usePopUpDeliverySpeedChange() {
  const { t } = useTranslation();

  const showDialog = useDialogStore((s) => s.push);
  const popDialog = useDialogStore((s) => s.pop);

  function handlePopUpDeliverySpeedChanged({
    LLGService,
    BIFastService,
  }: {
    LLGService: ServiceModel | undefined;
    BIFastService: ServiceModel | undefined;
  }) {
    popUpDeliverySpeedChangedAutomatically({
      t,
      popDialog,
      showDialog,
      LLGService,
      BIFastService,
    });
  }

  return {
    popUpDeliverySpeedChanged: handlePopUpDeliverySpeedChanged,
  };
}
