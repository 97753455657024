import { css } from '@emotion/css';
import { PayersModel } from '@topremit/shared-web/api-hooks/transaction';
import { isStringEmpty } from '@topremit/shared-web/common/helper';
import { BankOutline } from '@topremit/shared-web/components/shapes';
import Image from 'next/image';
import { useState } from 'react';

export default function BankWithLogo({ bank }: { bank: PayersModel }) {
  const [hasLoadedLogo, setHasLoadedLogo] = useState(false);

  const hasLogo = !isStringEmpty(bank.logo);
  return (
    <div className="bank-with-logo">
      <div className="bank-image">
        {hasLogo ? (
          <>
            <Image
              src={bank.logo}
              width={32}
              height={32}
              alt={`logo-${bank.name}`}
              onLoadingComplete={() => setHasLoadedLogo(true)}
            />
            {!hasLoadedLogo && <div className={styled.logoSkeleton} />}
          </>
        ) : (
          <BankOutline fill="var(--neutral-500)" />
        )}
      </div>
      <div className="bank-list-item-title">{bank.name}</div>
    </div>
  );
}

const styled = {
  logoSkeleton: css`
    width: 2rem;
    height: 2rem;
    margin-left: -2rem;
    border-radius: 100%;
    background: var(--neutral-200);
    animation: blink 1s infinite;
    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.6;
      }
    }
  `,
};
