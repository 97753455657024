import { TransactionType } from '@topremit/shared-web/api-hooks/transaction';
import { NextRouter, useRouter } from 'next/router';

import { DomesticRecipientStep } from '../types';

export default function useDomesticStepper() {
  const router = useRouter();
  const step = router.query.step as DomesticRecipientStep;

  function handleStepChange(
    router: NextRouter,
    step: DomesticRecipientStep,
    additionalQuery?: { [key: string]: string },
  ) {
    const updatedQuery = { step, ...additionalQuery };
    router.push(
      {
        pathname: `/activity/transaction/recipient/${TransactionType.DOMESTIC}`,
        query: updatedQuery,
      },
      undefined,
      {
        shallow: true,
      },
    );
  }

  return {
    step,
    handleStepChange,
  };
}
