import { useGetLiveRate } from '@api-hooks/transaction';
import { css, cx } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import {
  RemitQuotationModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import { isStringEmpty } from '@topremit/shared-web/common/helper';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Button,
  Flag,
  NumberInput,
  Divider,
} from '@topremit/shared-web/components/elements';
import {
  useDebounce,
  useGlobalState,
  useNotification,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

interface IChangeAmountProps {
  channel: RoutingChannelType;
  minPayerLimit: number;
  payerLimit: number;
}

export default function ChangeAmount({
  channel,
  minPayerLimit,
  payerLimit,
}: IChangeAmountProps) {
  const { t } = useTranslation('calculator');

  const showDialog = useDialogStore((store) => store.show);
  const popDialog = useDialogStore((store) => store.pop);
  const closeDialog = useDialogStore((store) => store.close);
  const { addNotification } = useNotification();

  const {
    quotation,
    setStep,
    setPayerId,
    setQuotation,
    setRecipient,
    setChangedAmount,
    setSelectedRecipientId,
    setHasChangedDestinationAmount,
  } = useRecipient();

  const methods = useForm({
    defaultValues: {
      amountSend: 0,
      amountReceive: 0,
    },
  });

  const { setValue, watch } = methods;

  const [changeCount, setChangeCount] = useState(0);
  const [isSendAmount, setIsSendAmount] = useState(true);
  const [quotationError, setQuotationError] = useState<string>();
  const changeAmountData = useRef<RemitQuotationModel>();
  const [isLiveRateSuccess, setIsLiveRateSuccess] = useState(false);
  const [, dispatchGlobalState] = useGlobalState();

  const {
    serviceId,
    sourceAmount,
    routingChannel,
    countryFlagCode,
    destinationAmount,
    destinationCountry,
    destinationCurrency,
  } = quotation || {};

  const strValues = JSON.stringify(watch());

  const amount = isSendAmount ? watch('amountSend') : watch('amountReceive');
  const _minPayerLimit = new Intl.NumberFormat('en-US').format(minPayerLimit);
  const _payerLimit = new Intl.NumberFormat('en-US').format(payerLimit);

  const speed = t(
    `conflict_modal.routing_channel_type.${quotation?.routingChannel?.toLowerCase()}`,
  );

  const sendAmountLeftElement = useMemo(
    () => (
      <div className={styled.currencyFieldFlag}>
        <div className="flag">
          <Flag code="id" size={28} />
        </div>
        <span>IDR</span>
      </div>
    ),
    [],
  );

  const receiveAmountLeftElement = useMemo(
    () => (
      <div className={styled.currencyFieldFlag}>
        <div className="flag">
          {countryFlagCode && <Flag code={countryFlagCode} size={28} />}
        </div>
        <span>{destinationCurrency}</span>
      </div>
    ),
    [],
  );

  const {
    isFetching: isFetchingLiveRate,
    isLoading: isLoadingLiveRate,
    refetch: refetchGetLiveRate,
  } = useGetLiveRate(
    {
      amount,
      serviceId,
      isSendAmount,
      routingChannel,
      destinationCountry,
      destinationCurrency,
    },
    'change-amount',
    {
      onSuccess: ({ data }) => {
        const { destinationAmount, destinationCurrency, sourceAmount } =
          data || {};
        changeAmountData.current = data;
        setValue('amountSend', sourceAmount);
        setValue('amountReceive', destinationAmount);
        if (
          destinationAmount < minPayerLimit ||
          destinationAmount > payerLimit
        ) {
          setQuotationError(
            t('conflict_modal.change_amount.error_message', {
              currency: destinationCurrency,
              minPayerLimit: _minPayerLimit,
              payerLimit: _payerLimit,
            }),
          );
        } else {
          setIsLiveRateSuccess(true);
        }
      },
      onError: ({ message, data }) => {
        const { type } = data || {};

        if (!type) {
          addNotification({ message, type: 'danger' });
          return;
        }

        if (type === 'RANGE') {
          setQuotationError(
            t('conflict_modal.change_amount.error_message', {
              currency: destinationCurrency,
              minPayerLimit: _minPayerLimit,
              payerLimit: _payerLimit,
            }),
          );
          return;
        }
        setQuotationError(message);
      },
    },
  );

  const isDisabled = isLoadingLiveRate || isFetchingLiveRate;

  function onBack() {
    popDialog();
  }

  function closeChangeAmountModal() {
    closeDialog();
    setPayerId(null);
    setSelectedRecipientId(0);
    setPayerId(null);
    setStep(ChooseRecipientStep.CHOOSE);
    setValue('segment' as any, 'PERSONAL');
  }

  function resetRecipientData() {
    setRecipient(null);
    setPayerId(null);
  }

  function onClickCloseModal() {
    closeChangeAmountModal();
    resetRecipientData();
  }

  function handleContinueButton() {
    if (quotationError) {
      return;
    }

    if (!isLiveRateSuccess) {
      setQuotationError(
        t('conflict_modal.change_amount.error_message', {
          currency: destinationCurrency,
          minPayerLimit: _minPayerLimit,
          payerLimit: _payerLimit,
        }),
      );
    } else {
      setQuotation({
        ...(changeAmountData.current as RemitQuotationModel),
        countryFlagCode: String(countryFlagCode || ''),
      });
      closeChangeAmountModal();
      setChangedAmount(true);
      setHasChangedDestinationAmount(false);
    }
  }

  useEffect(() => {
    // quickfix : modal not rerender input causing value not set
    setTimeout(() => {
      setValue('amountSend', sourceAmount);
      setValue('amountReceive', destinationAmount);
    }, 500);
  }, [sourceAmount, destinationAmount]);

  useEffect(
    function syncFormToGlobalState() {
      dispatchGlobalState({
        type: 'ADD',
        payload: {
          amountReceive: watch('amountReceive'),
          destinationCountry,
          destinationCurrency,
          serviceId,
          routingChannel,
          isSendAmount,
        },
        key: 'changed-quotation-amount',
      });
    },
    [strValues],
  );

  useDebounce(
    () => {
      if (changeCount > 0) {
        setQuotationError(undefined);
        refetchGetLiveRate();
      }
    },
    [changeCount],
    1500,
  );

  useEffect(() => {
    showDialog((props) => ({
      ...props,
      onBack,
      onClose: onClickCloseModal,
    }));
  }, [minPayerLimit, payerLimit, channel]);

  return (
    <FormProvider {...methods}>
      <div className={styled.root}>
        <h5>{t('conflict_modal.change_amount.title')}</h5>
        <div className="description">
          {t('conflict_modal.change_amount.description', {
            speed,
            currency: quotation?.destinationCurrency,
            minAmount: _minPayerLimit,
            maxAmount: _payerLimit,
          })}
        </div>
        <div className={styled.currencyWrapper(!isStringEmpty(quotationError))}>
          <NumberInput
            name="amountSend"
            withThousandSeparator
            label={t('home:jumbotron.calculator.remit.send_amount')}
            className={styled.rightAlign}
            onChange={() => setChangeCount((prev) => prev + 1)}
            disabled={isDisabled}
            bordered={false}
            leftSection={sendAmountLeftElement}
            onFocus={() => setIsSendAmount(true)}
          />

          <Divider variant="dashed" />

          <NumberInput
            name="amountReceive"
            withThousandSeparator
            label={t('home:jumbotron.calculator.remit.receive_amount')}
            onChange={() => setChangeCount((prev) => prev + 1)}
            className={styled.rightAlign}
            disabled={isDisabled}
            bordered={false}
            leftSection={receiveAmountLeftElement}
            onFocus={() => setIsSendAmount(false)}
          />
        </div>
        {quotationError && (
          <div className={cx('error-text', styled.errorMessage)}>
            <span>{quotationError}</span>
          </div>
        )}
        <Button
          className="action-button"
          onClick={handleContinueButton}
          isLoading={isDisabled}
        >
          {t('common:continue')}
        </Button>
      </div>
    </FormProvider>
  );
}

const styled = {
  root: css`
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 3.5rem;
    }
    .description {
      color: var(--text-secondary);
      margin-bottom: 1rem;
    }
    .action-button {
      width: 100%;
    }
  `,
  currencyFieldFlag: css`
    display: flex;
    padding: 1rem 0 1rem 0;
    align-items: center;
    .flag {
      display: flex;
      margin-right: 0.625rem;
      width: 1.75rem;
    }
  `,
  rightAlign: css`
    text-align: right !important;
    margin: 0 !important;
    .form-label {
      left: 0 !important;
      @media (min-width: ${screenSize.tabletMd}px) {
        width: 100% !important;
        padding-right: 20px;
      }
    }
    input {
      text-align: right !important;
    }
  `,
  currencyWrapper: (isError: boolean) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${isError
      ? '0.125rem solid var(--red-500)'
      : '0.0625rem solid var(--neutral-200)'} !important;
    border-radius: 0.75rem;
    padding: 0.125rem;
    margin-bottom: ${isError ? 0 : '1.5rem'};
    .flag-field-group {
      margin: unset;
    }
    .flag-field-group-wrapper {
      > div {
        border: unset;
        > .form-label {
          left: unset;
        }
      }
    }
  `,
  errorMessage: css`
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  `,
};
