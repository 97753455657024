import { ContainerSpinner } from '@topremit/shared-web/components/elements';
import dynamic from 'next/dynamic';

export { default as CorridorHolidayMaintenanceNote } from '../calculator/CorridorHolidayMaintenanceNote';
export { default as RecipientModal } from '../calculator/RecipientModal';
export { default as UploadInvoice } from '../calculator/UploadInvoice';
export { default as useCorridorNote } from '../calculator/use-corridor-note';
export { default as AccountNumberChecker } from './AccountNumberChecker';
export * from './add-recipient';
export { default as ButtonWithPortal } from './ButtonWithPortal';
export { default as ChooseRecipientHeader } from './ChooseRecipientHeader';
export * from './domestic';
export * from './edit-recipient';
export { default as TransactionPurpose } from './TransactionPurpose';
export { default as useUploadRecipientDocument } from './use-upload-recipient-document';

export const DashboardCalculator = dynamic(
  () => import('./DashboardCalculator'),
);
export const RecipientListEmptyState = dynamic(
  () => import('./RecipientListEmptyState'),
);
export const RecipientList = dynamic(() => import('./RecipientList'), {
  loading: () => <ContainerSpinner />,
});
export const ConflictDialogModalBody = dynamic(
  () => import('./ConflictDialogModalBody'),
  {
    loading: () => <ContainerSpinner />,
  },
);
export const ConfirmationDeleteRecipientModal = dynamic(
  () => import('./ConfirmationDeleteRecipientModal'),
  {
    loading: () => <ContainerSpinner />,
  },
);
export const BulkRemitConfirmTransactionDetails = dynamic(
  () => import('./bulk-remit/BulkRemitConfirmTransactionDetails'),
  {
    loading: () => <ContainerSpinner />,
  },
);

export const DiscountFeeBanner = dynamic(() => import('./DiscountFeeBanner'));
