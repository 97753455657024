import { Status } from '@topremit/shared-web/components/elements';
import { DelayFill } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';

export default function BankMaintenancePill() {
  const { t } = useTranslation();
  return (
    <Status
      size="small"
      color="yellow"
      message={t('maintenance')}
      icon={<DelayFill width={16} height={16} />}
    />
  );
}
