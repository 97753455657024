import { DragDropInput } from '@elements';
import { AcceptType } from '@elements/drag-drop-input/types';
import { css, cx } from '@emotion/css';
import { convertFromByte } from '@topremit/shared-web/common/helper';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import { Flex, Notes, Text } from '@topremit/shared-web/components/elements';
import { UploadedFile } from '@topremit/shared-web/components/elements/fields/types';
import { Close, DocumentFill } from '@topremit/shared-web/components/shapes';
import { color } from '@topremit/shared-web/styles/color';
import { ReactNode } from 'react';
import { Control } from 'react-hook-form';

interface UploadInvoiceProps {
  name: string;
  title?: string;
  accept?: AcceptType;
  notes?: ReactNode;
  className?: string;
  description?: string;
  maxFileSize?: number;
  uploaderTitle?: ReactNode;
  value: UploadedFile | null;
  control: Control<any, any>;
  onValid: () => void;
  onValueChange: (value: UploadedFile | null) => void;
  onUploading: (value: boolean) => void;
}

interface UploadedInvoiceProps {
  name: string;
  size: number;
  className?: string;
  onRemove: () => void;
}

const MINIMUM_SHOW_AS_MB = 0.01;
const lineSvg = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2330A6FFFF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='12' stroke-linecap='round'/%3e%3c/svg%3e")`;

/**
 * UploadInvoice component to upload and display invoice files.
 *
 * @component
 * @example
 *  <UploadInvoice
 *    name="invoice"
 *    title="Upload Invoice"
 *    notes="Please upload the invoice file."
 *    value={invoiceFile}
 *    onValueChange={(value)=> setInvoiceFile(value)}
 * />
 */
export default function UploadInvoice({
  name,
  value,
  title,
  notes,
  accept,
  className,
  maxFileSize,
  description,
  uploaderTitle,
  onValid,
  control,
  onValueChange,
  onUploading,
}: UploadInvoiceProps) {
  return (
    <Flex column className={className}>
      {Boolean(title) && <Text className={styled.title}>{title}</Text>}
      {Boolean(description) && (
        <Text className={styled.description}>{description}</Text>
      )}
      {Boolean(notes) && (
        <Notes showIcon status="info" title={notes} className={styled.notes} />
      )}
      {value ? (
        <UploadedInvoice
          name={value.name}
          size={value.size || 0}
          onRemove={() => onValueChange(null)}
          className={styled.uploadedInvoice}
        />
      ) : (
        <DragDropInput
          name={name}
          accept={accept}
          title={uploaderTitle}
          maxSize={maxFileSize}
          className={styled.uploader}
          useApi
          control={control}
          onValid={() => {
            onValid?.();
          }}
          onChange={(files) => {
            if (files?.length > 0 && files?.[0]?.state === 'uploaded') {
              onValueChange(files[0]);
            }
          }}
          onUploading={onUploading}
        />
      )}
    </Flex>
  );
}

/**
 * Component to display the details of the uploaded invoice.
 */
function UploadedInvoice({
  name,
  onRemove,
  size,
  className,
}: UploadedInvoiceProps) {
  const fileSizeInKB = convertFromByte(size, 'KB');
  const fileSizeInMB = convertFromByte(size, 'MB');

  const sizeText =
    fileSizeInMB >= MINIMUM_SHOW_AS_MB
      ? `${fileSizeInMB.toFixed(2)} MB`
      : `${fileSizeInKB.toFixed(2)} KB`;

  return (
    <Flex
      align="center"
      justify="space-between"
      className={cx(styled.itemWrapper, className)}
    >
      <div>
        <Flex
          align="center"
          justify="center"
          className={styled.itemFileIcon}
          aria-label="File icon"
        >
          <DocumentFill fill={color.blue500} width={20} height={20} />
        </Flex>
      </div>
      <Flex column>
        <Flex as="span" className={styled.itemName}>
          {name}
        </Flex>
        <Flex as="span" className={styled.itemSize}>
          {sizeText}
        </Flex>
      </Flex>
      <div>
        <Flex
          as="button"
          align="center"
          justify="center"
          className={styled.itemCloseIcon}
          onClick={onRemove}
          aria-label="Remove file"
        >
          <Close width={20} height={20} fill={color.neutral500} />
        </Flex>
      </div>
    </Flex>
  );
}

const styled = {
  title: css`
    font-weight: ${typography.body1Bold.fontWeight};
  `,
  description: css`
    margin-top: 8px;
    color: ${color.neutral500};
    font-size: ${typography.smallMedium.fontSize}rem;
  `,
  notes: css`
    margin-top: 16px;
  `,
  uploader: css`
    margin-top: 8px;
  `,
  uploadedInvoice: css`
    margin: 16px 0;
  `,
  itemSize: css`
    margin-top: 0.25rem;
    font-size: var(--sm-font-size);
    color: var(--neutral-500);
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--xs-font-size);
    }
  `,
  itemCloseIcon: css`
    min-width: 2rem;
    min-height: 2rem;
    background: ${color.neutral100};
    border-radius: 50%;
    cursor: pointer;
  `,
  itemWrapper: css`
    width: 100%;
    gap: 12px;
    background-color: var(--neutral-0);
    padding: 1rem;
    background-image: ${lineSvg};
    background-position: center;
    border-radius: 8px;
    height: 78px;
  `,
  itemFileIcon: css`
    min-width: 2rem;
    min-height: 2rem;
    background: ${color.blue100};
    border-radius: 50%;
  `,
  itemName: css`
    font-size: 1rem;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: var(--sm-font-size);
    }
  `,
};
