/* eslint-disable no-case-declarations */
import { useGetCountry } from '@api-hooks/common';
import {
  EditRecipientDetailsParams,
  useValidateRecipientDetails,
} from '@api-hooks/recipient';
import { DynamicForm, Recipient } from '@api-hooks/transaction';
import { SYNC_FORM_KEY } from '@common/storage';
import { css, cx } from '@emotion/css';
import { ChooseRecipientStep, usePermission, useRecipient } from '@hooks';
import {
  PayersModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  getNonEmptyStringObject,
  isEmptyObject,
} from '@topremit/shared-web/common/helper';
import {
  isNativeApp,
  sendNativeMessage,
} from '@topremit/shared-web/common/native-web-view-bridge';
import {
  Button,
  ContainerSpinner,
  DynamicInput,
  Flex,
  Notes,
  parseHtml,
  PhoneNumberInputV2,
  RadioInput,
  Text,
} from '@topremit/shared-web/components/elements';
import { InterrogationOutline } from '@topremit/shared-web/components/shapes';
import {
  useGlobalState,
  useNotification,
  useShallowEffect,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { NativeWebViewBridgeEventName } from '@topremit/shared-web/typings/native-web-view-bridge.model';
import humps from 'humps';
import _get from 'lodash/get';
import Link from 'next/link';
import { useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';

import { ConflictDialogModalBody } from '..';

interface IEditRecipientDetailsProps {
  form?: DynamicForm;
  payers?: PayersModel[];
  isFetching: boolean;
}

const PREV_STEP = 'PREV_STEP';
const PAYER_ROUTING_CHANNEL = 'PAYER_ROUTING_CHANNEL';

export default function EditRecipientDetails({
  form,
  payers,
  isFetching,
}: IEditRecipientDetailsProps) {
  const { t } = useTranslation('calculator');
  const [globalState, dispatchGlobalState] = useGlobalState();
  const { isBusinessAccount, isPersonalAccount } = usePermission();

  const formContext = useFormContext<EditRecipientDetailsParams>();
  const {
    watch,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
  } = formContext;

  const { sections } = form || {};

  const strValues = JSON.stringify(watch());

  const {
    payerId,
    prevStep,
    recipient,
    quotation,
    isValidatingBankCode,
    setStep,
    setPayerId,
    setRecipient,
    setHasPayerSpeed,
    setSelectedRecipientId,
  } = useRecipient();

  const { referenceId, destinationAmount, routingChannel } = quotation || {};

  const { addNotification } = useNotification();
  const showDialog = useDialogStore((store) => store.show);
  const pushDialog = useDialogStore((store) => store.push);
  const popDialog = useDialogStore((store) => store.pop);

  const payerRoutingChannel = JSON.parse(
    localStorage.getItem(PAYER_ROUTING_CHANNEL) as string,
  );
  const syncFormValues = globalState.get(SYNC_FORM_KEY);

  const strSyncFormValues = JSON.stringify(syncFormValues);

  const errorFieldsList = useRef<string[]>([]);

  const _values = useMemo(
    () => getNonEmptyStringObject(watch()) as Recipient,
    [strValues],
  );

  const { data: country, refetch: refetchCountry } = useGetCountry(
    quotation.destinationCountry,
  );

  const {
    mutateAsync: validateRecipientDetails,
    isLoading: isLoadingValidateRecipientDetails,
  } = useValidateRecipientDetails(referenceId, {
    onSuccess: () => {
      setHasPayerSpeed(true);
      setStep(ChooseRecipientStep.ADDITIONAL);
      setRecipient(watch() as Recipient);
    },
    onError: ({ message, errors }) => {
      clearErrors();
      errorFieldsList.current = [];
      if (!isEmptyObject(errors)) {
        Object.entries(errors).forEach(([key, value]) => {
          if (typeof value === 'object') {
            Object.entries(value).forEach(([childKey]) => {
              errorFieldsList.current.push(`${key}.${childKey}`);
              setError(
                `${key}.${childKey}` as keyof EditRecipientDetailsParams,
                {
                  message: value[childKey],
                },
              );
            });
          } else if (typeof key === 'string') {
            errorFieldsList.current.push(key);
            setError(key as keyof EditRecipientDetailsParams, {
              message: value,
            });
          }
        });
      }
      if (isNativeApp()) {
        sendNativeMessage({
          name: NativeWebViewBridgeEventName.ALERT,
          data: {
            message,
            variant: 'danger',
          },
        });
        return;
      }
      addNotification({ type: 'danger', message });
    },
  });

  function renderContent() {
    if (isFetching && form?.sections.length === 0) {
      return <ContainerSpinner />;
    }

    return sections?.map((section, idx) => {
      return (
        <Flex key={idx} column className={styled.section}>
          <Text>{section.title}</Text>
          {renderSectionNote(section.note)}
          <Flex column>{renderRowFields(section.fields)}</Flex>
        </Flex>
      );
    });
  }

  function renderRowFields(fields) {
    return fields.map((field, idx) => {
      const fieldName = humps.camelize(field.name);
      const helperText =
        watch().beneficiary?.segment === 'BUSINESS' && field?.businessNote
          ? field?.businessNote
          : field?.note;
      const apiParams =
        field.type === 'api'
          ? field.fields.reduce((prev, next) => {
              const fieldName = humps.camelize(next);
              const _value = watch(fieldName);
              return {
                ...prev,
                [fieldName]: _value,
              };
            }, {})
          : null;

      const isFieldHidden = field.hidden;
      const renderSenderField = isBusinessAccount
        ? ['ALL', 'BUSINESS']
        : isPersonalAccount
          ? ['ALL', 'PERSONAL']
          : [];
      const renderRecipientField = ['ALL', watch().beneficiary?.segment];

      if (isFieldHidden) {
        return;
      }

      // Note: handled sender segment according to current account info
      // Note: also handle recipient segment according choosen recipient segment
      if (
        !(
          renderSenderField.includes(field.senderSegment) &&
          renderRecipientField.includes(field.recipientSegment)
        )
      ) {
        return;
      }

      switch (field.type) {
        case 'radio':
          if (!_get(watch(), humps.camelize(fieldName), '')) {
            setValue(fieldName, field.options[0].value);
          }
          return (
            <RadioInput
              optionType="card"
              name={fieldName}
              key={idx}
              id={fieldName}
              size="small"
              {...field}
            />
          );
        case 'phone':
          const dialCodeDefaultCountry =
            syncFormValues?.dialCodeCountry || field.isoCode;

          return (
            <PhoneNumberInputV2
              {...field}
              id={fieldName}
              key={idx}
              name={fieldName}
              className="dynamic-field"
              value={watch(fieldName)}
              onChangeCountry={onSelectItemPhoneNumberField}
              helperText={helperText && parseHtml(helperText)}
              disabledSelectCountry={!field.selectable}
              dialCodeOptions={country?.data}
              defaultValue={{
                dialCodeCountry: dialCodeDefaultCountry,
              }}
              control={formContext?.control}
            />
          );
        default:
          const className = cx('dynamic-field', {
            'change-class': fieldName === 'payerId',
          });
          const rightIcon = (() => {
            if (!field.faqLink) {
              return;
            }
            return (
              <Link href={field.faqLink} target="_blank" prefetch={false}>
                <div className="field-right-icon">
                  <InterrogationOutline />
                </div>
              </Link>
            );
          })();

          return (
            <DynamicInput
              {...field}
              id={fieldName}
              key={idx}
              name={fieldName}
              params={apiParams}
              className={className}
              rightIcon={rightIcon}
              autoCloseBottomSheet={false}
              isOptional={!field.required}
              showSearch={field.searchable}
              helperText={helperText && parseHtml(helperText)}
              onSelectItem={(value) =>
                onSelectItemDynamicField(fieldName, value)
              }
              {...(humps.camelize(field.name) === 'payerId' && {
                onClick: () => {
                  setStep(ChooseRecipientStep.PAYERS);
                },
              })}
              control={formContext?.control}
            />
          );
      }
    });
  }

  function onSelectItemPhoneNumberField(value) {
    const { beneficiary, ...formRestProps } = watch();
    const _beneficiary = {
      ...{
        ...beneficiary,
        contactNumber: '',
      },
    };
    dispatchGlobalState({
      type: 'ADD',
      key: SYNC_FORM_KEY,
      payload: {
        ...formRestProps,
        ...syncFormValues,
        dialCodeCountry: value?.dialCode,
        beneficiary: _beneficiary,
      },
    });
  }

  function onSelectItemDynamicField(fieldName: string, value: string) {
    dispatchGlobalState({
      type: 'ADD',
      key: SYNC_FORM_KEY,
      payload: {
        ...syncFormValues,
        ...watch(),
        [fieldName]: value,
      },
    });
  }

  function renderSectionNote(note) {
    const showNotes =
      note
        ?.filter(
          ({ senderSegment }) =>
            senderSegment === 'ALL' || senderSegment === 'PERSONAL',
        )
        .map(({ recipientSegment, senderSegment, text }, index) => ({
          recipientSegment,
          senderSegment,
          text,
          index,
        })) || [];

    if (showNotes.length === 0) {
      return;
    }

    return showNotes.map(
      ({ recipientSegment, text, index }) =>
        (recipientSegment === watch().beneficiary?.segment ||
          recipientSegment === 'ALL') && (
          <Notes
            showIcon
            key={index}
            title={parseHtml(text)}
            status="info"
            className="notes"
          />
        ),
    );
  }

  async function onContinue() {
    const routingChannels: RoutingChannelType[] = [];
    const payerLimits: number[] = [];
    let isExceedInstantLimit = false;
    let isExceedRegularLimit = false;
    let isExceedAllSpeedLimit = false;

    payerRoutingChannel?.forEach((channel) => {
      const { limit, type } = channel || {};

      if (!payerLimits.includes(limit)) {
        payerLimits.push(limit);
      }

      if (type === RoutingChannelType.INSTANT) {
        isExceedInstantLimit = limit < destinationAmount;
      } else if (type === RoutingChannelType.REGULAR) {
        isExceedRegularLimit = limit < destinationAmount;
      }

      routingChannels.push(type);
    });

    if (isExceedInstantLimit && isExceedRegularLimit) {
      isExceedAllSpeedLimit = true;
    }

    const isRoutingChannelMatched = routingChannels.includes(routingChannel);

    await validateRecipientDetails(_values);

    renderConflictDialogModal({
      routingChannels,
      payerLimits,
      isExceedAllSpeedLimit,
      isRoutingChannelMatched,
    });
  }

  function onCancelConflictDialog() {
    popDialog();
    setPayerId(null);
    setRecipient(null);
    setSelectedRecipientId(0);
    setStep(ChooseRecipientStep.CHOOSE);
  }

  function renderConflictDialogModal({
    routingChannels,
    payerLimits,
    isExceedAllSpeedLimit,
    isRoutingChannelMatched,
  }: {
    routingChannels: RoutingChannelType[];
    payerLimits: number[];
    isExceedAllSpeedLimit: boolean;
    isRoutingChannelMatched: boolean;
  }) {
    const selectedRoutingChannelIdx = routingChannels.findIndex(
      (routingChannel) => routingChannel === quotation.routingChannel,
    );

    if (payerLimits.length === 1 || isExceedAllSpeedLimit) {
      // show limit or speed not supported modal if limit exceeded
      // or speed not supported by selected payer
      if (destinationAmount <= payerLimits[0] && isRoutingChannelMatched) {
        return;
      }

      pushDialog({
        body: (
          <ConflictDialogModalBody
            routingChannel={routingChannels}
            payerLimit={payerLimits[selectedRoutingChannelIdx]}
          />
        ),
        onClose: onCancelConflictDialog,
      });
    }
  }

  useEffect(() => {
    if (recipient && prevStep !== ChooseRecipientStep.ADDITIONAL) {
      const availablePayers: number[] = [];

      payers?.forEach(({ id }) => {
        availablePayers.push(id);
      });

      Object.entries(recipient).forEach(([key, value]) => {
        if (key === 'payerId') {
          if (prevStep === ChooseRecipientStep.PAYERS) {
            setValue(key, payerId as any);
            return;
          }

          if (availablePayers.includes(value)) {
            setPayerId(value);
            return;
          }

          setValue(key, 0);
        }
        setValue(key as keyof EditRecipientDetailsParams, value);
      });
    }

    localStorage.setItem(PREV_STEP, ChooseRecipientStep.EDIT);
  }, []);

  useEffect(
    function syncFormToGlobalState() {
      dispatchGlobalState({
        type: 'ADD',
        payload: watch().beneficiary?.segment,
        key: 'beneficiary-segment',
      });
    },
    [watch().beneficiary?.segment],
  );

  useEffect(
    function syncFormValuesFromBottomSheet() {
      if (syncFormValues?.recipientId) {
        /** quickfix: trigger rerender using setvalue */
        /** issue: stale data when choose personal and business recipient */
        /** reproduce: choose any recipient (personal) -> back -> choose any recipient (business)  */
        /** then the data still show previous */
        setValue(
          'sync' as keyof EditRecipientDetailsParams,
          syncFormValues.recipientId as number,
        );
      }

      return () => dispatchGlobalState({ type: 'REMOVE', key: SYNC_FORM_KEY });
    },
    [strSyncFormValues],
  );

  useShallowEffect(() => {
    if (isEmptyObject(country)) {
      refetchCountry();
    }
  }, [quotation?.destinationCountry]);

  useEffect(() => {
    if (!isEmptyObject(errors) && errorFieldsList.current.length > 0) {
      const fieldError = document.getElementById(errorFieldsList.current[0]);
      if (fieldError) {
        fieldError?.scrollIntoView({ block: 'center' });
      }
    }
  }, [errors]);

  useEffect(() => {
    showDialog((props) => ({
      ...props,
      footer: (
        <Button
          fullWidth
          disabled={isValidatingBankCode || !watch('payerId')}
          isLoading={isLoadingValidateRecipientDetails}
          onClick={onContinue}
        >
          {t('common:continue')}
        </Button>
      ),
    }));
  }, [isLoadingValidateRecipientDetails, watch(), isValidatingBankCode]);

  return (
    <div className={styled.root}>
      <Text as="h5">{t('choose_recipient')}</Text>
      {renderContent()}
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    h5 {
      margin: 0 1.125rem 1.5rem 0;
    }
    .change-class {
      > .input-wrapper {
        background-color: transparent;
        .form-control {
          .ant-input-suffix {
            > span {
              transform: rotate(-90deg) !important;
            }
          }
        }
      }
    }
  `,
  section: css`
    .notes {
      margin-bottom: 1rem;
    }
    :not(:last-child) {
      margin-bottom: 1.5rem;
    }
    .text {
      margin-bottom: 1rem;
      font-weight: var(--bold-font-weight);
    }
    .form-group {
      :not(:last-child) {
        margin: 0 0 1rem;
      }
      :last-child {
        :not(.select-searchbar) {
          margin: 0;
        }
      }
      .input-wrapper {
        .field-right-icon {
          display: flex;
          align-items: center;
          padding-right: 20px;
        }
      }
    }
  `,
};
