import { TransactionMode } from '@topremit/shared-web/api-hooks/common';
import { PayersModel } from '@topremit/shared-web/api-hooks/transaction';

import { IRecipientDocumentStore } from './hooks/use-domestic-flow-store';

export enum DomesticRecipientStep {
  TYPE = 'TYPE',
  CHOOSE = 'CHOOSE',
  AMOUNT = 'AMOUNT',
  ADD = 'ADD',
}

export interface SelectBankProps {
  error?: string;
  className?: string;
  disabled?: boolean;
  showSpeedPills: boolean;
  transactionType: TransactionMode;
  value: PayersModel | undefined;
  onValueChange: (value: PayersModel | undefined) => void;
}

export interface RecipientAmountFormModel {
  recipientAmounts: {
    payerId: number;
    recipientId: number;
    amount: number | null;
  }[];
}

export type SetErrorParams =
  | {
      type: 'recipient';
      error: IRecipientDocumentStore['errorRecipientMessage'];
    }
  | {
      type: 'services';
      error: IRecipientDocumentStore['errorServicesMessage'];
    }
  | {
      type: 'recipientBatches';
      error: IRecipientDocumentStore['errorRecipientBatchesIds'];
    }
  | {
      type: 'hardLimit';
      error: IRecipientDocumentStore['hardLimitMessage'];
    };
