import { css } from '@emotion/css';
import { SVGProps } from 'react';

function getDegree(direction: string) {
  if (direction === 'right') return 0;
  else if (direction === 'down') return 90;
  else if (direction === 'left') return 180;
  else if (direction === 'up') return -90;
}

function Arrow({
  direction,
  size = 'default',
  fill = 'var(--neutral-500)',
  ...props
}: SVGProps<SVGSVGElement> & {
  direction: 'left' | 'right' | 'up' | 'down';
  size?: 'default' | 'small';
  fill?: string;
}) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
      className={styled.rotate(getDegree(direction))}
      {...props}
    >
      {size === 'small' ? (
        <path d="M13.121 6.293C12.9335 6.48053 12.8282 6.73484 12.8282 7C12.8282 7.26516 12.9335 7.51947 13.121 7.707L16.413 11L6 11.007C5.73478 11.007 5.48043 11.1124 5.29289 11.2999C5.10536 11.4874 5 11.7418 5 12.007C5 12.2722 5.10536 12.5266 5.29289 12.7141C5.48043 12.9016 5.73478 13.007 6 13.007L16.414 13L13.121 16.293C13.0255 16.3852 12.9493 16.4956 12.8969 16.6176C12.8445 16.7396 12.8169 16.8708 12.8157 17.0036C12.8146 17.1364 12.8399 17.2681 12.8902 17.391C12.9405 17.5138 13.0147 17.6255 13.1086 17.7194C13.2025 17.8133 13.3141 17.8875 13.437 17.9378C13.5599 17.9881 13.6916 18.0134 13.8244 18.0123C13.9572 18.0111 14.0884 17.9835 14.2104 17.9311C14.3324 17.8787 14.4428 17.8025 14.535 17.707L18.121 14.122C18.3996 13.8434 18.6207 13.5127 18.7715 13.1487C18.9222 12.7847 18.9999 12.3945 18.9999 12.0005C18.9999 11.6065 18.9222 11.2163 18.7715 10.8523C18.6207 10.4883 18.3996 10.1576 18.121 9.879L14.535 6.293C14.3475 6.10553 14.0932 6.00021 13.828 6.00021C13.5628 6.00021 13.3085 6.10553 13.121 6.293Z" />
      ) : (
        <>
          <g clipPath="url(#clip0_308_9993)">
            <path d="M23.124 9.90699L19.245 6.02899C19.0575 5.84152 18.8032 5.73621 18.538 5.73621C18.2728 5.73621 18.0185 5.84152 17.831 6.02899C17.6435 6.21652 17.5382 6.47083 17.5382 6.73599C17.5382 7.00116 17.6435 7.25546 17.831 7.44299L21.386 11H1C0.734784 11 0.48043 11.1053 0.292893 11.2929C0.105357 11.4804 0 11.7348 0 12H0C0 12.2652 0.105357 12.5196 0.292893 12.7071C0.48043 12.8946 0.734784 13 1 13H21.447L17.831 16.615C17.738 16.7079 17.6643 16.8182 17.6139 16.9396C17.5636 17.061 17.5377 17.1911 17.5377 17.3225C17.5377 17.4539 17.5636 17.584 17.6139 17.7054C17.6643 17.8268 17.738 17.9371 17.831 18.03C18.0185 18.2175 18.2728 18.3228 18.538 18.3228C18.8032 18.3228 19.0575 18.2175 19.245 18.03L23.125 14.151C23.6859 13.5872 24.0007 12.8241 24.0006 12.0288C24.0004 11.2335 23.6852 10.4706 23.124 9.90699Z" />
          </g>
          <defs>
            <clipPath id="clip0_308_9993">
              <rect width={24} height={24} fill="white" />
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
}

const styled = {
  rotate: (degree) => css`
    -ms-transform: rotate(${degree}deg); /* IE 9 */
    transform: rotate(${degree}deg);
  `,
};

export default Arrow;
