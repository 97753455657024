import {
  AccountInfoBusiness,
  KycStatus,
  UpdateDataStatusType,
  useGetAccountInfo,
  useGetMe,
} from '@api-hooks/common';
import { css } from '@emotion/css';
import { clientOnboardingPageType } from '@modules/client-onboarding/types';
import { BlockingModal } from '@modules/landing/jumbotron';
import bolyBlacklistImage from '@public/images/svg/boly-blacklist.svg';
import bolyFailedImage from '@public/images/svg/boly-failed.svg';
import bolyVerificationImage from '@public/images/svg/boly-verification.svg';
import bolyVerifyingKycImage from '@public/images/svg/boly-verifying-kyc.svg';
import {
  Button,
  Flex,
  ProgressBar,
  Text,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { color } from '@topremit/shared-web/styles/color';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import usePermission from './use-permission';
import { useClientOnboardingStore } from '../stores/use-client-onboarding-store';

const kycTotalStep = 3;

type NeedActionProps =
  | {
      type: 'info' | 'danger';
      title: string;
      description: string;
      labelActionButton?: string;
      hasActionButton?: boolean;
      onClickActionButton?: () => void;
    }
  | {
      content: JSX.Element;
    };
export function useClientKycProps() {
  const { t } = useTranslation('client-onboarding');
  const router = useRouter();
  const closeDialog = useDialogStore((store) => store.close);
  const setCurrentPage = useClientOnboardingStore(
    (store) => store.setCurrentPage,
  );
  const { data: accountInfo } = useGetAccountInfo<AccountInfoBusiness>();
  const { data: meData } = useGetMe();
  const { isBusinessAccount } = usePermission();
  const [needActionList, setNeedActionList] = useState<NeedActionProps[]>([]);

  const {
    kycStatus,
    rejectedError,
    completedKyc,
    wasApproved,
    wasRejected,
    dashboardStatus,
  } = accountInfo?.data || {};

  const showDialog = useDialogStore((store) => store.show);
  const isNotValidAccount = !(
    kycStatus?.value === KycStatus.DATA_APPROVED && dashboardStatus
  );
  const isShowClientBlockingModal = isBusinessAccount && isNotValidAccount;

  const kycCard = (): NeedActionProps | undefined => {
    const page = localStorage.getItem('CLIENT_ABAD');
    switch (kycStatus?.value) {
      case null:
        if (typeof completedKyc === 'undefined') {
          return;
        } else if (completedKyc === null) {
          return {
            type: 'info' as 'info',
            title: t('dashboard:card.not_verified.title'),
            description: t('dashboard:card.not_verified.description'),
            labelActionButton: t('common:kyc_status.verify_now'),
            hasActionButton: true,
            onClickActionButton: () => {
              router.push({
                pathname: '/client-onboarding',
                query: { step: 1 },
              });
            },
          };
        }
        return {
          content: (
            <Flex column className={styled.ongoing}>
              <Text as="h5">
                {t('dashboard:card.not_verified.incomplete_title')}
              </Text>
              <Text>
                {t('dashboard:card.not_verified.incomplete_description')}
              </Text>

              <Flex className="progress-wrapper">
                <ProgressBar
                  percent={(completedKyc / kycTotalStep) * 100}
                  strokeColor={{ '0%': 'var(--blue-500)' }}
                />
                <Text
                  breakWord={false}
                >{`${completedKyc}/${kycTotalStep}`}</Text>
              </Flex>
              <Button
                fullWidth
                onClick={() => {
                  setCurrentPage((page as clientOnboardingPageType) || 'ADD');
                  router.push({
                    pathname: '/client-onboarding',
                    query: { step: completedKyc + 1 },
                  });
                }}
              >
                {t('common:continue_verification')}
              </Button>
            </Flex>
          ),
        };

      case KycStatus.DATA_REJECTED:
      case KycStatus.DATA_SUBMITTED:
      case KycStatus.DOCUMENT_SUBMITTED:
        return {
          type: 'info',
          title: t('dashboard:card.verifying.title'),
          description: t('dashboard:card.verifying.description'),
        };
      case KycStatus.PENDING:
        if (rejectedError) {
          if (
            (rejectedError.includes('BANK_ACCOUNT') ||
              rejectedError.includes('REGISTRATION_DOCUMENTS')) &&
            rejectedError.includes('ADDITIONAL_DOCUMENT')
          ) {
            const step = rejectedError.includes('BANK_ACCOUNT') ? 2 : 3;
            return {
              type: wasRejected ? 'info' : 'danger',
              title: wasRejected
                ? t('dashboard:card.re_kyc.title')
                : t('dashboard:card.need_revision.title'),
              description: wasRejected
                ? t('dashboard:card.re_kyc.description')
                : t(
                    'dashboard:card.need_revision.description_error_with_additional',
                  ),
              labelActionButton: wasRejected
                ? t('common:abad.re_verify')
                : t('common:revise_now'),
              hasActionButton: true,
              onClickActionButton: () => {
                router.push({
                  pathname: '/client-onboarding',
                  query: { step },
                });
              },
            };
          } else if (
            rejectedError.includes('BANK_ACCOUNT') &&
            rejectedError.includes('REGISTRATION_DOCUMENTS')
          ) {
            return {
              type: wasRejected ? 'info' : 'danger',
              title: wasRejected
                ? t('dashboard:card.re_kyc.title')
                : t('dashboard:card.need_revision.title'),
              description: wasRejected
                ? t('dashboard:card.re_kyc.description')
                : t('dashboard:card.need_revision.description_bank_document'),
              labelActionButton: wasRejected
                ? t('common:abad.re_verify')
                : t('common:revise_now'),
              hasActionButton: true,
              onClickActionButton: () => {
                router.push({
                  pathname: '/client-onboarding',
                  query: { step: 2 },
                });
              },
            };
          } else if (rejectedError.includes('BANK_ACCOUNT')) {
            return {
              type: wasRejected ? 'info' : 'danger',
              title: wasRejected
                ? t('dashboard:card.re_kyc.title')
                : t('dashboard:card.need_revision.title'),
              description: wasRejected
                ? t('dashboard:card.re_kyc.description')
                : t('dashboard:card.need_revision.description_bank'),
              labelActionButton: wasRejected
                ? t('common:abad.re_verify')
                : t('common:revise_now'),
              hasActionButton: true,
              onClickActionButton: () => {
                router.push({
                  pathname: '/client-onboarding',
                  query: { step: 2 },
                });
              },
            };
          } else if (
            rejectedError.includes('REGISTRATION_DOCUMENTS') ||
            rejectedError.includes('ADDITIONAL_DOCUMENT')
          ) {
            return {
              type: wasRejected ? 'info' : 'danger',
              title: wasRejected
                ? t('dashboard:card.re_kyc.title')
                : t('dashboard:card.need_revision.title'),
              description: wasRejected
                ? t('dashboard:card.re_kyc.description')
                : t(
                    rejectedError.includes('REGISTRATION_DOCUMENTS')
                      ? 'dashboard:card.need_revision.description_document'
                      : 'dashboard:card.need_revision.description_additional',
                  ),
              labelActionButton: wasRejected
                ? t('common:abad.re_verify')
                : t('common:revise_now'),
              hasActionButton: true,
              onClickActionButton: () => {
                router.push({
                  pathname: '/client-onboarding',
                  query: { step: 3 },
                });
              },
            };
          }
        }
        return;
      case KycStatus.COMPLIANCE_REJECTED:
        return {
          type: 'danger',
          title: wasApproved
            ? t('dashboard:card.verification_rejected_was_approved.title')
            : t('dashboard:card.verification_rejected.title'),
          description: wasApproved
            ? t('dashboard:card.verification_rejected_was_approved.description')
            : t('dashboard:card.verification_rejected.description'),
        };
      case KycStatus.DATA_APPROVED:
        return {
          type: 'danger',
          title: t('dashboard:card.verification_rejected_was_approved.title'),
          description: t(
            'dashboard:card.verification_rejected_was_approved.description',
          ),
        };
    }
  };

  function clientModalProps() {
    switch (kycStatus?.value) {
      case null:
        return {
          title: t('dashboard:blocking_modal.not_verified.title'),
          description: t('dashboard:blocking_modal.not_verified.description'),
          buttonLabel: t('common:kyc_status.verify_now'),
          imgSrc: bolyVerificationImage,
          imgStyle: { width: 200, height: 200 },
          onClick: () => {
            router.push('/profile');
            closeDialog();
          },
        };
      case KycStatus.DATA_REJECTED:
      case KycStatus.DATA_SUBMITTED:
      case KycStatus.DOCUMENT_SUBMITTED:
        return {
          title: t('dashboard:blocking_modal.verifying.title'),
          description: t('dashboard:blocking_modal.verifying.description'),
          buttonLabel: t('common:okay_got_it'),
          imgSrc: bolyVerifyingKycImage,
          imgStyle: { width: 200, height: 200 },
          onClick: () => closeDialog(),
        };
      case KycStatus.PENDING:
        return {
          title: t('dashboard:blocking_modal.need_revision.title'),
          description: t('dashboard:blocking_modal.need_revision.description'),
          buttonLabel: t('common:go_to_profile'),
          imgSrc: bolyFailedImage,
          imgStyle: { width: 200, height: 200 },
          onClick: () => {
            router.push('/profile');
            closeDialog();
          },
        };
      case KycStatus.COMPLIANCE_REJECTED:
        return {
          title: wasApproved
            ? t(
                'dashboard:blocking_modal.verification_rejected_was_approved.title',
              )
            : t('dashboard:blocking_modal.verification_rejected.title'),
          description: wasApproved
            ? t(
                'dashboard:blocking_modal.verification_rejected_was_approved.description',
              )
            : t('dashboard:blocking_modal.verification_rejected.description'),
          buttonLabel: t('common:okay_got_it'),
          imgSrc: bolyBlacklistImage,
          imgStyle: { width: 200, height: 200 },
          onClick: () => closeDialog(),
        };
      case KycStatus.DATA_APPROVED:
        return {
          title: t(
            'dashboard:blocking_modal.verification_rejected_was_approved.title',
          ),
          description: t(
            'dashboard:blocking_modal.verification_rejected_was_approved.description',
          ),
          buttonLabel: t('common:okay_got_it'),
          imgSrc: bolyBlacklistImage,
          imgStyle: { width: 200, height: 200 },
          onClick: () => closeDialog(),
        };
      default:
        return null;
    }
  }

  function clientBlockingModal() {
    const props = clientModalProps();
    if (!props) {
      return null;
    }
    return showDialog({
      body: <BlockingModal {...props} />,
    });
  }

  //to handle client need action card
  useEffect(() => {
    const cardProps = kycCard();

    const updateDataDraftCard: NeedActionProps = {
      title: t('profile:draft_card.title'),
      description: t('profile:draft_card.subtitle'),
      labelActionButton: t('profile:review_now'),
      hasActionButton: true,
      type: 'info',
      onClickActionButton: () => {
        router.push('/profile/update-data');
      },
    };

    const rejectedDataCard: NeedActionProps = {
      title: t('profile:rejected_card.title'),
      description: t('profile:rejected_card.subtitle'),

      labelActionButton: t('profile:rejected_card.update_again'),
      hasActionButton: true,
      type: 'danger',
      onClickActionButton: () => {
        router.push('/profile/update-data');
      },
    };

    if (isNotValidAccount && cardProps) {
      setNeedActionList((prev) => [...prev, cardProps]);
    }
    if (meData?.data?.updateData?.status === UpdateDataStatusType.DRAFT) {
      setNeedActionList((prev) => [...prev, updateDataDraftCard]);
    }
    if (meData?.data?.updateData?.status === UpdateDataStatusType.REJECTED) {
      setNeedActionList((prev) => [...prev, rejectedDataCard]);
    }
  }, [isNotValidAccount, meData?.data?.updateData?.status]);

  return {
    isShowClientBlockingModal,
    clientBlockingModal,
    clientCardProps: needActionList,
  };
}

const styled = {
  ongoing: css`
    button {
      margin-top: 1rem;
    }
    > .progress-wrapper {
      align-items: center;
      margin-top: 1rem;
      color: ${color.neutral500};
      > .ant-progress {
        margin-right: 8px;
      }
    }
  `,
};
