import { css, cx } from '@emotion/css';
import { useRecipient } from '@hooks';
import circleBackgroundArrowImage from '@public/images/svg/circle-background-arrow.svg';
import { RoutingChannelModel } from '@topremit/shared-web/api-hooks/common';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import { Divider } from '@topremit/shared-web/components';
import SpeedInfo from '@topremit/shared-web/components/elements/speed-info/SpeedInfo';
import { useGlobalState, useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';

const SELECTED_SERVICE_DETAIL = 'SELECTED_SERVICE_DETAIL';
const QUOTATION_FORM = 'quotation-form';

export default function SpeedInfoCard({ availableChannel }) {
  const { t } = useTranslation('calculator');
  const { quotation } = useRecipient();
  const [globalState] = useGlobalState();
  const { amountSend } = globalState.get(QUOTATION_FORM);
  const selectedServiceDetail = globalState.get(SELECTED_SERVICE_DETAIL);

  const { hasDiscountFee, discountValues } = getDiscountFee(
    selectedServiceDetail,
  );

  const suggestedSpeedTitle = generateSpeedTitle('change_to');
  const currentSpeedTitle = generateSpeedTitle('current_speed');

  const currentSpeedDescription = getServiceDetailsDescription(
    quotation.routingChannel,
  );
  const suggestedSpeedDescription =
    getServiceDetailsDescription(availableChannel);

  const currentSpeedFee = t('conflict_modal.change_speed_confirmation.fee', {
    fee: getServiceDetailsFee(quotation.routingChannel),
  });
  const suggestedSpeedFee = t('conflict_modal.change_speed_confirmation.fee', {
    fee: getServiceDetailsFee(availableChannel),
  });

  const { fee: currentFee, bottomElement: currentBottomElement } =
    getFeeAndBottomElement(0, quotation.routingChannel, false);
  const { fee: suggestedFee, bottomElement: suggestedBottomElement } =
    getFeeAndBottomElement(1, availableChannel, true);

  function getServiceDetails(channel: RoutingChannelType) {
    return (
      selectedServiceDetail?.find(
        (serviceDetail) => channel === serviceDetail.type,
      ) || { description: '', fee: 0 }
    );
  }

  function getServiceDetailsDescription(channel: RoutingChannelType) {
    return getServiceDetails(channel).description;
  }

  function getServiceDetailsFee(channel: RoutingChannelType) {
    return new Intl.NumberFormat('en-US').format(
      getServiceDetails(channel).fee,
    );
  }

  function getDeliverySpeed(channel: RoutingChannelType) {
    const isInstantSpeed = channel === RoutingChannelType.INSTANT;
    const routingChannelType = isInstantSpeed ? 'instant' : 'regular';
    const deliverySpeed = t(
      `conflict_modal.routing_channel_type.${routingChannelType}`,
    );
    return deliverySpeed;
  }

  function generateSpeedTitle(key: string) {
    return t(`conflict_modal.change_speed_confirmation.detail_card.${key}`);
  }

  function getDiscountFee(routingChannels: RoutingChannelModel[]) {
    const discountValues = routingChannels?.map(({ discount }) => {
      return isNaN(Number(discount?.value)) ? 0 : Number(discount?.value);
    });

    const isAmountSendExceed = routingChannels?.map(({ discount }) => {
      return amountSend >= Number(discount?.minimumAmount);
    });

    const hasDiscountFee = isAmountSendExceed.map(
      (isAmountSendExceed, index) => {
        return isAmountSendExceed && discountValues[index] > 0;
      },
    );

    return {
      discountValues,
      hasDiscountFee,
      isAmountSendExceed,
    };
  }

  function getFeeAndBottomElement(
    index: number,
    channel: RoutingChannelType,
    isSuggested: boolean,
  ) {
    const hasDiscount = hasDiscountFee[index];
    let fee: string;

    if (hasDiscount) {
      fee = `${t('common:fee')} ${formatToIDRCurrency(
        getServiceDetails(channel).fee - discountValues[index],
      )}`;
    } else if (isSuggested) {
      fee = suggestedSpeedFee;
    } else {
      fee = currentSpeedFee;
    }

    const bottomElement = hasDiscount && (
      <s className="secondary-text xs-bold-text">
        IDR {getServiceDetailsFee(channel)}
      </s>
    );

    return { fee, bottomElement };
  }

  return (
    <div className={cx(styled.root, 'card card-secondary')}>
      <SpeedInfo
        fee={currentFee}
        title={currentSpeedTitle}
        bottomElement={currentBottomElement}
        arrivalTime={currentSpeedDescription}
        speed={getDeliverySpeed(quotation.routingChannel)}
      />
      <div className="dashed-divider">
        <Divider variant="dashed" my={0}>
          <Image
            width={24}
            height={24}
            alt="circle-background-arrow"
            src={circleBackgroundArrowImage}
          />
        </Divider>
      </div>
      <SpeedInfo
        fee={suggestedFee}
        title={suggestedSpeedTitle}
        bottomElement={suggestedBottomElement}
        arrivalTime={suggestedSpeedDescription}
        speed={getDeliverySpeed(availableChannel)}
      />
    </div>
  );
}

const styled = {
  root: css`
    padding: 1rem;
    .dashed-divider {
      position: relative;
      display: flex;
      margin: 1.25rem 0;
      justify-content: center;
    }
  `,
};
