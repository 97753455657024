import {
  useEditRecipient,
  usePostRecipientDetails,
} from '@api-hooks/recipient';
import { Recipient } from '@api-hooks/transaction';
import { css } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import { BolyNotFound } from '@shapes';
import { Button, parseHtml } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useState } from 'react';

const ERRORS = 'ERRORS';
const PREV_STEP = 'PREV_STEP';

interface ICheckInvoiceNumberModal {
  onSuccess: (data) => void;
  values: Recipient;
}

export default function CheckInvoiceNumberModal({
  onSuccess,
  values,
}: ICheckInvoiceNumberModal) {
  const { t } = useTranslation('calculator');

  const popDialog = useDialogStore((store) => store.pop);

  const [isLoading, setIsLoading] = useState(false);

  const { quotation, selectedRecipientId, setSelectedRecipientId } =
    useRecipient();

  const { referenceId } = quotation || {};

  const { mutateAsync: mutateAsyncEditRecipient } = useEditRecipient(
    {
      id: selectedRecipientId,
      refId: referenceId,
    },
    {
      onSuccess: ({ data }) => {
        onSuccess(data);
      },
      onError: () => {
        localStorage.setItem('ERRORS', 'true');
        popDialog();
      },
    },
  );

  const { mutateAsync: postRecipientDetails } = usePostRecipientDetails(
    referenceId,
    {
      onSuccess: ({ data }) => {
        onSuccess(data);
        setSelectedRecipientId(data.id);
      },
      onError: () => {
        localStorage.setItem(ERRORS, 'true');
        popDialog();
      },
    },
  );

  function onBack() {
    popDialog();
  }

  async function handleMutate() {
    const prevStep = localStorage.getItem(PREV_STEP);

    if (prevStep === ChooseRecipientStep.EDIT) {
      await mutateAsyncEditRecipient(values);
      return;
    }
    await postRecipientDetails(values);
  }

  return (
    <div className={styled.root}>
      <div className="image-wrapper">
        <BolyNotFound />
      </div>
      <h5>{t('check_invoice_number.title')}</h5>
      <div className="description">
        <span>{parseHtml(t('check_invoice_number.description'))}</span>
      </div>
      <div className="btn-wrapper">
        <Button
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            onBack();
          }}
          className="btn-check"
        >
          {t('check_invoice_number.btn_check')}
        </Button>
        <Button
          type="invert"
          onClick={() => {
            setIsLoading(true);
            handleMutate();
          }}
          isLoading={isLoading}
          className="btn-continue"
        >
          {t('check_invoice_number.btn_continue')}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    text-align: center;
    > .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 12.5rem;
      margin-top: 2.5rem;
      margin-bottom: 0.5rem;
    }
    .description {
      color: var(--text-secondary);
      margin-bottom: 1rem;
    }
    > .btn-wrapper {
      > .btn-continue {
        width: 100%;
      }
      > .btn-check {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  `,
};
