import { useGetAvailableRecipients } from '@api-hooks/recipient';
import { css } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import {
  ConfirmationDeleteRecipientModal,
  RecipientList,
  useUploadRecipientDocument,
} from '@modules';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { isStringEmpty } from '@topremit/shared-web/common/helper';
import {
  Button,
  ContainerSpinner,
  Flex,
  Tabs,
  Text,
} from '@topremit/shared-web/components/elements';
import {
  useDebounce,
  useIntersectionObserver,
  useNotification,
  usePersistentState,
  useShallowEffect,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { Searchbar } from '@topremit-ui/searchbar';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { RecipientType, SearchRecipientList } from './types';

interface IChooseRecipientProps {
  disabledSegments?: string[];
  countryAndCurrency?: string;
  selectedRoutingChannel: RoutingChannelType;
}

export default function ChooseRecipient({
  disabledSegments,
  countryAndCurrency,
  selectedRoutingChannel,
}: IChooseRecipientProps) {
  const { t } = useTranslation('calculator');

  const {
    step,
    quotation,
    isSubmitted,
    isDeleteRecipient,
    prevStep,
    deleteSelectedRecipientIdxs,
    setStep,
    setIsSubmitted,
    setIsLimitExceed,
    setIsDeleteRecipient,
    setDeleteSelectedRecipientIdxs,
  } = useRecipient();

  const [isTriggerSearch, setIsTriggerSearch] = useState(false);
  const [isLoadingSearchRecipients, setIsLoadingSearchRecipients] =
    useState(false);

  const [isEmptyRecipients, setIsEmptyRecipients] = usePersistentState(
    'isEmptyRecipients',
    false,
  );

  const { setFile: setRecipientDocument } = useUploadRecipientDocument();

  const { watch, setValue, reset, clearErrors } =
    useFormContext<SearchRecipientList>();

  const { segment, searchRecipient } = watch();
  const { serviceId, destinationCountry, destinationCurrency } =
    quotation || {};

  const { addNotification } = useNotification();
  const showDialog = useDialogStore((store) => store.show);
  const pushDialog = useDialogStore((store) => store.push);

  const hasRenderedRecipientList = useRef<boolean>(false);
  const viewportLoadMoreRef = useRef<HTMLDivElement>(null);
  const prevCountryAndCurrency = useRef<string>();
  const prevSearchRecipient = useRef<string>('');

  const personalRecipients = useGetAvailableRecipients(
    {
      key: 'PERSONAL',
      filter: {
        serviceId,
        searchRecipient,
        segment: 'PERSONAL',
        countryIsoCode: destinationCountry,
        currencyIsoCode: destinationCurrency,
      },
    },
    {
      onSuccess: onSuccessGetAvailableRecipients,
      onError: ({ message }) => onErrorGetAvailableRecipients(message),
      getNextPageParam: (param) => {
        const { currentPage, lastPage } = param?.meta || {};
        return currentPage < lastPage ? currentPage + 1 : false;
      },
    },
  );

  const businessRecipients = useGetAvailableRecipients(
    {
      key: 'BUSINESS',
      filter: {
        serviceId,
        searchRecipient,
        segment: 'BUSINESS',
        countryIsoCode: destinationCountry,
        currencyIsoCode: destinationCurrency,
      },
    },
    {
      onSuccess: onSuccessGetAvailableRecipients,
      onError: ({ message }) => onErrorGetAvailableRecipients(message),
      getNextPageParam: (param) => {
        const { currentPage, lastPage } = param?.meta || {};
        return currentPage < lastPage ? currentPage + 1 : false;
      },
    },
  );

  const {
    data: recipients,
    isFetching: isFetchingRecipients,
    hasNextPage: hasNextPageRecipients,
    refetch: refetchRecipients,
    fetchNextPage: fetchNextPageRecipients,
  } = segment === 'PERSONAL' ? personalRecipients : businessRecipients;

  const tabItems = [
    {
      label: t('personal_individual'),
      name: 'PERSONAL',
    },
    {
      label: t('company_institution'),
      name: 'BUSINESS',
    },
  ];

  const disabledTabs = (() => {
    if (isFetchingRecipients) {
      return [0, 1];
    }

    return disabledSegments;
  })();

  const isEmptyData =
    (isEmptyRecipients &&
      isStringEmpty(searchRecipient) &&
      isStringEmpty(prevSearchRecipient.current)) ||
    (isStringEmpty(searchRecipient) &&
      recipients?.pages[0].data.length === 0) ||
    (!isFetchingRecipients && recipients === undefined);
  const isLoadingRenderRecipients =
    isLoadingSearchRecipients && !hasRenderedRecipientList.current;
  const isDisabledBtnAddRecipients =
    isLoadingSearchRecipients || isFetchingRecipients;

  const isDisabledSearchbar =
    isTriggerSearch ||
    isLoadingRenderRecipients ||
    isFetchingRecipients ||
    (isEmptyRecipients && isStringEmpty(searchRecipient));

  const isDisabledBtnDelete =
    isEmptyData || isLoadingRenderRecipients || isFetchingRecipients;
  const isDisabledBtnSelectedRecipient =
    deleteSelectedRecipientIdxs.length === 0;
  const canShowBtnCancel =
    !isEmptyData && !isLoadingSearchRecipients && isDeleteRecipient;
  const canSearchRecipient = prevSearchRecipient.current !== searchRecipient;

  function onSuccessGetAvailableRecipients() {
    setIsTriggerSearch(false);
    setIsLoadingSearchRecipients(false);
  }

  function onErrorGetAvailableRecipients(message: string) {
    addNotification({ message, type: 'danger' });
    setIsLoadingSearchRecipients(false);
  }

  function onClickTab(name: string) {
    setValue('segment', name as RecipientType);
    setDeleteSelectedRecipientIdxs([]);
    if (isDeleteRecipient) {
      setIsDeleteRecipient(false);
    }
  }

  function onClickBtnCancel() {
    setIsDeleteRecipient(false);
    setDeleteSelectedRecipientIdxs([]);
  }

  function onClickBtnDelete() {
    setIsDeleteRecipient(true);
  }

  function onAddRecipient() {
    setStep(ChooseRecipientStep.TYPE);
    setIsLimitExceed(false);
    setRecipientDocument(null);
    reset();
  }

  function onFetchRecipient() {
    if (
      prevStep === ChooseRecipientStep.TYPE ||
      prevStep === ChooseRecipientStep.ADDITIONAL
    ) {
      setIsLoadingSearchRecipients(false);
      return;
    }

    refetchRecipients();
  }

  function onRefetchRecipient() {
    if (
      prevStep === ChooseRecipientStep.TYPE ||
      prevStep === ChooseRecipientStep.ADDITIONAL ||
      (prevStep === ChooseRecipientStep.CHOOSE && !isTriggerSearch)
    ) {
      setIsLoadingSearchRecipients(false);
      return;
    }

    refetchRecipients();
  }

  function onDeleteRecipient() {
    pushDialog({
      body: <ConfirmationDeleteRecipientModal segment={segment} />,
    });
  }

  function renderActionButtons() {
    if (canShowBtnCancel) {
      return (
        <Button size="small" type="invert" onClick={onClickBtnCancel}>
          {t('common:cancel')}
        </Button>
      );
    }

    return (
      <Button
        size="small"
        type="invert"
        disabled={isDisabledBtnDelete}
        onClick={onClickBtnDelete}
      >
        {t('common:delete')}
      </Button>
    );
  }

  function renderContent() {
    if (isLoadingRenderRecipients) {
      return <ContainerSpinner />;
    }

    return renderRecipients();
  }

  function renderRecipients() {
    hasRenderedRecipientList.current = true;
    return (
      <Flex column className={styled.recipientList}>
        <Searchbar
          value={searchRecipient}
          disabled={isDisabledSearchbar}
          placeholder={t('search_recipient')}
          onChange={(value) => setValue('searchRecipient', value)}
        />
        <Tabs
          items={tabItems}
          current={segment}
          className={styled.tabs}
          disabledTabs={disabledTabs}
          onClick={onClickTab}
        />
        <RecipientList
          recipients={recipients}
          isDeleteRecipient={isDeleteRecipient}
          viewportLoadMoreRef={viewportLoadMoreRef}
          isFetchingRecipients={isFetchingRecipients}
          hasNextPageRecipients={hasNextPageRecipients}
          deleteSelectedRecipientIdxs={deleteSelectedRecipientIdxs}
          setDeleteSelectedRecipientIdxs={setDeleteSelectedRecipientIdxs}
          selectedRoutingChannel={selectedRoutingChannel}
        />
      </Flex>
    );
  }

  function renderFooter() {
    if (!isDeleteRecipient) {
      return (
        <Button
          fullWidth
          disabled={isDisabledBtnAddRecipients}
          onClick={onAddRecipient}
        >
          {t('add_new')}
        </Button>
      );
    }

    return (
      <Button
        fullWidth
        type="danger"
        disabled={isDisabledBtnSelectedRecipient}
        onClick={onDeleteRecipient}
      >
        {t('delete_recipient')}
        {!isDisabledBtnSelectedRecipient &&
          ` (${deleteSelectedRecipientIdxs.length})`}
      </Button>
    );
  }

  useDebounce(
    () => {
      if (canSearchRecipient) {
        onRefetchRecipient();
        setIsTriggerSearch(true);
        setIsLoadingSearchRecipients(true);
      }
    },
    [searchRecipient],
    500,
  );

  useIntersectionObserver({
    target: viewportLoadMoreRef,
    onIntersect: fetchNextPageRecipients,
    enabled: hasNextPageRecipients && !isTriggerSearch,
    threshold: 0.75,
  });

  useEffect(() => {
    if (
      countryAndCurrency !== prevCountryAndCurrency.current &&
      prevStep !== ChooseRecipientStep.TYPE
    ) {
      setIsEmptyRecipients(false);
      setIsLoadingSearchRecipients(true);
      onFetchRecipient();
    }
    prevCountryAndCurrency.current = countryAndCurrency;
  }, [countryAndCurrency]);

  useEffect(() => {
    if (prevStep !== ChooseRecipientStep.TYPE) {
      setIsEmptyRecipients(false);
    }
    prevSearchRecipient.current = searchRecipient;
  }, [searchRecipient]);

  useEffect(() => {
    onRefetchRecipient();
  }, [segment]);

  useEffect(() => {
    if (isTriggerSearch) {
      onRefetchRecipient();
    }
  }, [isTriggerSearch]);

  useEffect(() => {
    if (isSubmitted) {
      onRefetchRecipient();
      setIsSubmitted(false);
    }
  }, [isSubmitted, step]);

  useEffect(
    function resetFormErrors() {
      clearErrors();
    },
    [clearErrors],
  );

  useShallowEffect(() => {
    const { pages } = recipients || {};
    const _isEmptyRecipients =
      pages?.length === 1 && pages[0].data.length === 0;
    setIsEmptyRecipients(_isEmptyRecipients);
  }, [recipients?.pages]);

  useShallowEffect(() => {
    showDialog((props) => ({
      ...props,
      footer: renderFooter(),
    }));
  }, [
    isDeleteRecipient,
    isDisabledBtnAddRecipients,
    deleteSelectedRecipientIdxs,
  ]);

  return (
    <Flex column className={styled.root}>
      <Flex className={styled.header}>
        <Text as="h5">{t('choose_recipient')}</Text>
        {renderActionButtons()}
      </Flex>
      {renderContent()}
    </Flex>
  );
}

const styled = {
  root: css`
    height: 100%;
  `,
  header: css`
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    h5 {
      margin-bottom: 0;
    }
  `,
  recipientList: css`
    height: 100%;
    .form-group {
      margin: 0 0 1rem;
    }
  `,
  tabs: css`
    margin-bottom: 0.5rem;
  `,
};
