import { DynamicFormSection } from '@api-hooks/transaction';
import { css, cx } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import {
  ContainerSpinner,
  Flex,
  ListMenu,
  Text,
} from '@topremit/shared-web/components/elements';
import { useGlobalState, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

interface IChooseRecipientProps {
  section?: DynamicFormSection;
  isFetching: boolean;
}

interface BeneficiarySegment {
  beneficiary: { segment: string };
}

export default function RecipientType({
  section,
  isFetching,
}: IChooseRecipientProps) {
  const { t } = useTranslation('calculator');

  const showDialog = useDialogStore((store) => store.show);

  const formContext = useFormContext<BeneficiarySegment>();
  const { setValue } = formContext;
  const [, dispatchGlobalState] = useGlobalState();
  const { setStep } = useRecipient();

  const { title, fields } = section || {};
  const { options } = fields?.[0] || {};

  function onContinue(value) {
    setValue('beneficiary.segment', value);
    dispatchGlobalState({
      type: 'ADD',
      key: 'beneficiary-segment',
      payload: value,
    });
    setStep(ChooseRecipientStep.ADD);
  }

  function renderContent() {
    if (isFetching) {
      return <ContainerSpinner />;
    }

    return (
      <>
        <Text as="h5">{title}</Text>
        {options?.map(({ value, label, disabled, description }, idx) => (
          <ListMenu
            key={idx}
            hasDivider
            type="arrow"
            title={label}
            isDisabled={disabled}
            description={description}
            descriptionClassName="sm-text"
            className={cx('list-menu-no-bg', styled.listItem)}
            onClick={() => onContinue(value)}
          />
        ))}
      </>
    );
  }

  useEffect(() => {
    showDialog((props) => ({
      ...props,
      footer: undefined,
    }));
  }, []);

  return (
    <Flex column className={styled.root}>
      <Text as="h5">{t('add_new_recipient.title')}</Text>
      {renderContent()}
    </Flex>
  );
}

const styled = {
  root: css`
    h5 {
      margin-bottom: 1.25rem;
    }
  `,
  listItem: css`
    margin-top: 0.25rem;
    padding: 0.75rem 0;
  `,
};
