import { useGetRecipient } from '@api-hooks/transaction';
import { removeQuoteQueryParams } from '@modules/landing/jumbotron/helper';
import {
  RoutingChannelType,
  TransactionRecipientType,
} from '@topremit/shared-web/api-hooks/transaction';
import { useNotification } from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useDomesticFlow from './use-domestic-flow';

export default function useDomesticRecreate() {
  const router = useRouter();
  const { addNotification } = useNotification();

  const {
    amount,
    service,
    recipient: recipientId,
    routingChannel,
  } = router.query;

  const {
    data: { services },
    values: { recipient: recipientValue },
    handlers: { handleRecipientChange, handleDeliverySpeedChange },
  } = useDomesticFlow();

  const servicesData = services?.data;

  const { refetch: refetchRecipient } = useGetRecipient(
    {
      recipientId: String(recipientId || ''),
      searchParams: {
        transactionType: TransactionRecipientType.SINGLE,
      },
    },
    {
      enabled: false,
      onSuccess: ({ data }) => {
        handleRecipientChange(data);
      },
      onError: ({ message }) => {
        addNotification({ message, type: 'danger' });
      },
    },
  );

  useEffect(() => {
    if (!recipientId) {
      return;
    }
    refetchRecipient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientId]);

  useEffect(() => {
    if (
      !amount ||
      !service ||
      !recipientId ||
      !servicesData ||
      !routingChannel ||
      !recipientValue
    ) {
      return;
    }

    handleDeliverySpeedChange(
      {
        routingChannel: routingChannel as RoutingChannelType,
        serviceId: Number(service),
      },
      Number(amount),
    );
    removeQuoteQueryParams();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amount,
    service,
    recipientId,
    routingChannel,
    recipientValue,
    servicesData,
  ]);
}
