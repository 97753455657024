import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';

import { COUNTRY_IDN, CURRENCY_IDR } from './constants';
import CorridorHolidayMaintenanceNote from '../CorridorHolidayMaintenanceNote';
import useDomesticFlow from './hooks/use-domestic-flow';

function HolidayMaintenanceNote() {
  const {
    values: { deliverySpeed },
  } = useDomesticFlow();

  return (
    <CorridorHolidayMaintenanceNote
      className={styled.corridorNotes}
      destinationCountry={COUNTRY_IDN}
      destinationCurrency={CURRENCY_IDR}
      serviceId={deliverySpeed.serviceId}
      routingChannel={deliverySpeed.routingChannel}
    />
  );
}

const styled = {
  corridorNotes: css`
    margin-top: 24px;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin-top: 16px;
    }
  `,
};

export default HolidayMaintenanceNote;
