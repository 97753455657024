import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

interface IDomesticNewTabCoachMarkBody {
  className?: string;
  onClick: () => void;
}

function DomesticNewTabCoachMarkBody({
  className,
  onClick,
}: IDomesticNewTabCoachMarkBody) {
  const { t } = useTranslation('transaction');
  return (
    <div className={cx(styled.root, className)}>
      <h5 className="title">{t('domestic.coach_mark.new_tab.title')}</h5>
      <p className="desc">{t('domestic.coach_mark.new_tab.desc')}</p>
      <div className="btn-wrapper">
        <Button size="small" onClick={onClick}>
          {t('domestic.coach_mark.new_tab.btn_label')}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    flex-direction: column;
    text-align: left;
    .title {
      margin-bottom: 0.25rem;
      @media (max-width: ${screenSize.tabletSm}px) {
        font-size: 1rem;
      }
    }
    .desc {
      margin: 0 0 0.5rem;
      @media (max-width: ${screenSize.tabletSm}px) {
        font-size: var(--xs-font-size);
      }
    }
    .btn-wrapper {
      display: flex;
      justify-content: end;
    }
  `,
};

export default DomesticNewTabCoachMarkBody;
