import { ContentCard } from '@elements';
import { css } from '@emotion/css';
import RecipientTypeSelectorCard from '@modules/recipient/RecipientTypeSelectorCard';
import { TransactionMode } from '@topremit/shared-web/api-hooks/common';
import { screenSize } from '@topremit/shared-web/common/size';
import { Flex, Text } from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import useDomesticFlow from './hooks/use-domestic-flow';
import { DomesticRecipientStep } from './types';

function RecipientType() {
  const { t } = useTranslation();
  const router = useRouter();

  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const {
    values: { type: currentType, isBulk, amount },
    stepper: { handleStepChange },
    errors: { setError },
    handlers: {
      handleTypeChange,
      handleAmountChange,
      handleRecipientChange,
      handleDeliverySpeedChange,
      handleBulkRecipientsChange,
    },
  } = useDomesticFlow();

  function handleClickType(type: TransactionMode) {
    const isChoosingDifferentType = type !== currentType;
    if (isChoosingDifferentType) {
      handleDeliverySpeedChange({
        serviceId: undefined,
        routingChannel: undefined,
      });
      setError({ type: 'hardLimit', error: '' });
      handleAmountChange(undefined);
      if (type === TransactionMode.BULK) {
        // reset single recipient if choosing bulk
        handleRecipientChange(null);
      } else {
        // reset bulk recipient if choosing single
        handleBulkRecipientsChange(undefined, 'decrement');
      }
    }
    handleTypeChange(type);
    handleStepChange(router, DomesticRecipientStep.CHOOSE);
  }

  useEffect(() => {
    // reset type to SINGLE if user back to this page without fill the recipient amount
    if (isBulk && !amount) {
      handleTypeChange(TransactionMode.SINGLE);
      handleBulkRecipientsChange(undefined, 'decrement');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ContentCard fullHeight>
      <ContentCard.Header
        hasChatUs={isMobileSize}
        onBack={() => router.back()}
      />
      <ContentCard.Body>
        <Text as="h4" className={styles.title}>
          {t('calculator:choose_recipient')}
        </Text>
        <Flex column className={styles.typeWrapper}>
          <RecipientTypeSelectorCard
            type="single"
            onClick={() => handleClickType(TransactionMode.SINGLE)}
          />
          <RecipientTypeSelectorCard
            type="multiple"
            onClick={() => handleClickType(TransactionMode.BULK)}
          />
        </Flex>
      </ContentCard.Body>
    </ContentCard>
  );
}

const styles = {
  header: css`
    @media (max-width: ${screenSize.tabletMd}px) {
      padding-bottom: 0;
    }
  `,
  title: css`
    margin: 0;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 1.125rem;
    }
  `,
  typeWrapper: css`
    gap: 24px;
    margin: 16px 0;
    @media (max-width: ${screenSize.tabletMd}px) {
      gap: 16px;
    }
  `,
};

export default RecipientType;
