import { useGetMe } from '@api-hooks/common';
import { useHideDomesticCoachMark } from '@api-hooks/domestic';
import { screenSize } from '@topremit/shared-web/common/size';
import { useMediaQuery, useMounted } from '@topremit/shared-web/hooks';
import { useEffect, useRef, useState } from 'react';
import type { Step, Styles } from 'react-joyride';
import ReactJoyride from 'react-joyride';

import DomesticNewTabCoachMarkBody from './DomesticNewTabCoachMarkBody';

const SPOTLIGHT_PADDING = 20;

export default function DomesticNewTabCoachmark() {
  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletSm}px)`);

  const [run, setRun] = useState<boolean>(false);
  const mounted = useMounted();
  const updatePosition = useRef<(() => void) | null>(null);

  const { data: me, refetch: refetchMe } = useGetMe();

  const { mutate: mutateHideDomesticCoachMark } = useHideDomesticCoachMark({
    onSuccess: () => {
      refetchMe();
    },
  });

  const styles: Partial<Styles> = {
    tooltip: {
      borderRadius: '0.875rem',
      padding: '1rem',
      width: isMobileSize ? '19.5rem' : '21.875rem',
    },
    tooltipContent: { padding: 0 },
    spotlight: {
      borderRadius: '0.5rem',
    },
    options: {
      zIndex: 1000,
    },
  };

  const steps: Step[] = [
    {
      offset: -12,
      hideFooter: true,
      spotlightPadding: 1,
      disableBeacon: true,
      hideCloseButton: true,
      target: '.domestic-tab',
      disableOverlayClose: true,
      content: <DomesticNewTabCoachMarkBody onClick={handleClickButtonOk} />,
    },
  ];

  function handleClickButtonOk() {
    mutateHideDomesticCoachMark();
    setRun(false);
  }

  useEffect(() => {
    if (me?.data.showDomesticCoachmark && mounted) {
      setRun(true);
    }
  }, [me?.data.showDomesticCoachmark, mounted]);

  /**
   * @note this function to detect shifting of target coachmark and update position of the coachmark
   */
  useEffect(function tabPositionListener() {
    const tabElement = document?.getElementsByClassName('domestic-tab')[0];
    function getPosition() {
      const { x, y } = tabElement?.getBoundingClientRect() || {};
      return { x, y };
    }
    let lastPosition = getPosition();

    const observerCallback = () => {
      const { x, y } = getPosition() || {};
      if (lastPosition.x !== x || lastPosition.y !== y) {
        // coordinates has changed here!
        updatePosition.current?.();
      }
      lastPosition = { x, y };
    };

    const observer = new MutationObserver(observerCallback);
    if (tabElement) {
      observer.observe(tabElement, {
        subtree: true,
        attributes: true,
      });
    }
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <ReactJoyride
      floaterProps={{
        getPopper(popper, origin) {
          if (origin === 'floater') {
            // get coachmark force update function
            updatePosition.current = popper.instance.update;
          }
        },
      }}
      steps={steps}
      spotlightPadding={SPOTLIGHT_PADDING}
      run={run}
      styles={styles}
      scrollOffset={180}
      disableScrollParentFix
    />
  );
}
