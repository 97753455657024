import { useGetCorridorAnnouncement } from '@api-hooks/transaction';
import { QuotationNote } from '@elements';
import { css, cx } from '@emotion/css';
import { useRecipient } from '@hooks';
import { getIsRoutingChannelMaintenance } from '@modules/dashboard/calculator/helper';
import corridorHolidayImage from '@public/images/png/holiday-corridor.png';
import corridorMaintenanceImage from '@public/images/png/maintenance-corridor.png';
import {
  DealRateQuotationModel,
  PersonalQuotationFormValues,
  RemitQuotationModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import { areObjEqual, isEmptyObject } from '@topremit/shared-web/common/helper';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  CorrespondentFeeWrapper,
  Notes,
} from '@topremit/shared-web/components/elements';
import { useMediaQuery } from '@topremit/shared-web/hooks';
import Image from 'next/image';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { ChooseServiceCard, RecipientPayersMaintenanceNote } from '.';
import { ServiceDetailModel } from './CalculatorRemit';
import ChooseRecipientCard from './ChooseRecipientCard';
import RateWrapper from './RateWrapper';

interface IQuotationInfo {
  rate: string;
  quotation?: RemitQuotationModel;
  dealRateQuotation?: DealRateQuotationModel;
  isDisabled?: boolean;
  liveRate?: RemitQuotationModel;
  dealrateQuotation?: DealRateQuotationModel;

  isVerifiedKyc?: boolean;
  hasChooseService: boolean;
  isErrorChooseServiceDelivery: boolean;
  isErrorChooseRecipient: boolean;
  serviceDetail?: ServiceDetailModel;
  selectedRoutingChannel: RoutingChannelType;

  onClickServiceCard: () => void;
  onClickRecipientCard: () => void;
}

const { tabletMd } = screenSize;

function QuotationInfo({
  rate,
  liveRate,
  quotation,
  isDisabled,
  isVerifiedKyc,
  hasChooseService,
  dealRateQuotation,
  isErrorChooseServiceDelivery,
  isErrorChooseRecipient,
  serviceDetail,
  onClickServiceCard,
  onClickRecipientCard,
  selectedRoutingChannel,
}: IQuotationInfo) {
  const { quotationNote } = quotation || {};

  const correspondentFee =
    quotation?.correspondentFee ??
    serviceDetail?.routingChannel.correspondentFee;

  const isAppliedDealCode = !isEmptyObject(dealRateQuotation);

  const formContext = useFormContext<PersonalQuotationFormValues>();

  const { watch } = formContext;

  const {
    amountSend,
    serviceId = -1,
    routingChannel,
    destinationCountry,
    destinationCurrency,
    isSendAmount,
  } = watch();

  const [
    isVibrateErrorServiceDeliverySpeed,
    setIsVibrateErrorServiceDeliverySpeed,
  ] = useState(false);

  const { recipient } = useRecipient();

  const isMaintenanceNoteVisible = getIsRoutingChannelMaintenance(
    recipient?.routingChannels,
    selectedRoutingChannel,
  );

  const { data: corridorNoteData } = useGetCorridorAnnouncement({
    destinationCountry,
    destinationCurrency,
    serviceId,
    routingChannel: routingChannel || '',
  });

  const corridorNote = corridorNoteData?.data;

  const { beneficiary, bankAccountInfo } = recipient || {};

  const chooseServiceDeliveryClassName = cx(
    styled.listMenuWrapper(isErrorChooseServiceDelivery),
    'choose-service-delivery',
  );

  const chooseRecipientClassName = cx(
    styled.listMenuWrapper(Boolean(isErrorChooseRecipient)),
    'choose-recipient-card',
  );

  const { holidayComparisonRateInfo } = quotation || {};

  const hasQuotationNote =
    !isEmptyObject(quotationNote) || dealRateQuotation?.quotationNote;

  const canShowCorrespondentFee = hasChooseService && correspondentFee;

  const isHolidayNote = !!corridorNote?.holiday;
  const isMaintenanceNote = !!corridorNote?.maintenance;
  const hasCorridorNote = isHolidayNote || isMaintenanceNote;

  const corridorStatus = isHolidayNote ? 'info' : 'warning';
  const corridorIcon = isHolidayNote
    ? corridorHolidayImage
    : corridorMaintenanceImage;

  const titleNote = isHolidayNote
    ? corridorNote.holiday.title
    : corridorNote?.maintenance?.title;
  const descNote = isHolidayNote
    ? corridorNote.holiday.description
    : corridorNote?.maintenance?.description;

  const getQuotationNote = isAppliedDealCode
    ? dealRateQuotation?.quotationNote
    : quotationNote;

  const iconSize = useMediaQuery(`(max-width:${tabletMd}px)`) ? 32 : 40;

  function _onClickChooseRecipient() {
    onClickRecipientCard();
    if (isErrorChooseServiceDelivery) {
      setIsVibrateErrorServiceDeliverySpeed(true);
    }
  }

  useEffect(() => {
    if (isVibrateErrorServiceDeliverySpeed) {
      setIsVibrateErrorServiceDeliverySpeed(false);
    }
  }, [isVibrateErrorServiceDeliverySpeed]);

  return (
    <>
      {hasCorridorNote && (
        <Notes
          showIcon
          title={titleNote}
          message={descNote}
          status={corridorStatus}
          className={styled.corridorNotes}
          icon={
            <Image
              src={corridorIcon}
              alt=""
              width={iconSize}
              height={iconSize}
            />
          }
        />
      )}
      {!hasCorridorNote && isMaintenanceNoteVisible && (
        <RecipientPayersMaintenanceNote />
      )}
      <RateWrapper
        rate={rate}
        currency={destinationCurrency}
        quotation={quotation}
        liveRate={liveRate}
        isAppliedDealCode={isAppliedDealCode}
        normalFee={dealRateQuotation?.referenceFxRate}
        holidayComparisonRateInfo={holidayComparisonRateInfo}
        hasChooseService={hasChooseService}
        isSendAmount={isSendAmount}
      />
      {hasQuotationNote && (
        <QuotationNote
          data={getQuotationNote}
          className={cx(styled.quotationNote, 'quotation-note')}
        />
      )}
      <ChooseServiceCard
        amountSend={amountSend}
        isDisabled={isDisabled || isAppliedDealCode}
        isError={isErrorChooseServiceDelivery}
        hasChooseService={hasChooseService}
        selectedService={serviceDetail}
        isVibrateError={isVibrateErrorServiceDeliverySpeed}
        className={chooseServiceDeliveryClassName}
        onClick={onClickServiceCard}
      />
      {canShowCorrespondentFee && (
        <CorrespondentFeeWrapper correspondentFee={correspondentFee} />
      )}
      {isVerifiedKyc && (
        <>
          <ChooseRecipientCard
            isDisabled={isDisabled}
            isError={isErrorChooseRecipient}
            title={beneficiary?.fullName}
            description={bankAccountInfo}
            className={chooseRecipientClassName}
            onClick={_onClickChooseRecipient}
          />
        </>
      )}
    </>
  );
}

const styled = {
  quotationNote: css`
    width: 100%;
    margin-bottom: 1.5rem;
    padding: 0.5rem 1rem;
    overflow: hidden;
    #other-details,
    a {
      cursor: pointer;
      color: var(--blue-500);
      font-weight: var(--bold-font-weight);
      &:not(:disabled) {
        &:active {
          color: var(--blue-500);
          text-decoration: underline;
        }
      }
    }
    .bg-wrapper {
      svg {
        width: 144px;
        height: 7.1875rem;
      }
    }
  `,
  listMenuWrapper: (isError: boolean) => css`
    &:not(:last-child) {
      margin-bottom: ${isError ? '0.25rem' : '1.5rem'} !important;
    }
  `,
  corridorNotes: css`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-alert-content .ant-alert-message {
      margin-bottom: 0;
    }
  `,
};

export default memo(QuotationInfo, areObjEqual);
