import { calculateRoutingChannelsDiscount } from '@elements/choose-service/helper';
import { css, cx } from '@emotion/css';
import { DiscountFeeBanner } from '@modules/dashboard';
import {
  RoutingChannelModel,
  ServiceModel,
} from '@topremit/shared-web/api-hooks/common';
import { Card, ListMenu } from '@topremit/shared-web/components/elements';
import { useGlobalState, useTranslation } from '@topremit/shared-web/hooks';
import {
  ServiceIconName,
  SERVICE_ICONS,
} from 'landing/src/components/shapes/icons.constant';
import Image from 'next/image';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import ChooseServiceRoutingChannel from './ChooseServiceRoutingChannel';
import { getServiceDiscountBadge } from './helper';

type NumberState = [number, Dispatch<SetStateAction<number>>];

interface ServiceStatusModel {
  isDisabled: boolean;
  availableRoutingChannelOrder: number;
}

interface IChooseServiceModalBody {
  serviceOrder: number;
  services: ServiceModel[];
  hasChooseService: boolean;
  routingChannelOrder: number;
  modalServiceOrderState: NumberState;
  modalRoutingChannelOrderState: NumberState;
  shouldCheckVolume?: boolean;
  isFirstTransactionFree?: boolean;
}

/**
 * @deprecated use <ChooseService /> instead
 */
export default function ChooseServiceModalBody({
  services,
  serviceOrder,
  hasChooseService,
  routingChannelOrder,
  modalServiceOrderState,
  shouldCheckVolume = true,
  modalRoutingChannelOrderState,
  isFirstTransactionFree,
}: IChooseServiceModalBody) {
  const { t } = useTranslation('home');

  const [modalServiceOrder, setModalServiceOrder] = modalServiceOrderState;
  const [modalRoutingChannelOrder, setModalRoutingChannelOrder] =
    modalRoutingChannelOrderState;

  const [globalState] = useGlobalState();
  const { amountSend, amountReceive } = globalState.get('quotation-form');

  const [hasLoadedIcon, setHasLoadedIcon] = useState(false);

  useEffect(() => {
    if (hasChooseService) {
      const { availableRoutingChannelOrder } = getServiceStatus(
        services[serviceOrder].routingChannels,
      );

      const _routingChannelOrder = Math.max(
        routingChannelOrder,
        availableRoutingChannelOrder,
      );

      setModalServiceOrder(serviceOrder);
      setModalRoutingChannelOrder(_routingChannelOrder);
    }
  }, []);

  function onClickService({
    isDisabled,
    serviceOrder,
    availableRoutingChannelOrder,
  }: {
    isDisabled: boolean;
    serviceOrder: number;
    availableRoutingChannelOrder: number;
  }) {
    if (!isDisabled) {
      setModalServiceOrder((prev) =>
        prev !== serviceOrder ? serviceOrder : -1,
      );
      if (modalServiceOrder !== serviceOrder) {
        setModalRoutingChannelOrder(availableRoutingChannelOrder);
      }
    }
  }

  function getServiceStatus(
    routingChannels: RoutingChannelModel[],
  ): ServiceStatusModel {
    const { maxVolume, minVolume } = routingChannels[0] || {};

    if (maxVolume !== undefined && minVolume !== undefined) {
      const availableRoutingChannelOrder = routingChannels.findIndex(
        ({ maxVolume, minVolume }) =>
          amountReceive >= minVolume && amountReceive <= maxVolume,
      );
      return {
        availableRoutingChannelOrder: shouldCheckVolume
          ? availableRoutingChannelOrder
          : 0,
        isDisabled: availableRoutingChannelOrder === -1 && shouldCheckVolume,
      };
    }

    return {
      isDisabled: true,
      availableRoutingChannelOrder: -1,
    };
  }

  const renderedServices = useMemo(
    () =>
      services.map((service, idx) => {
        const {
          id,
          name,
          description,
          serviceIcon,
          routingChannels,
          volumeExceededDescription,
        } = service || {};

        const isSelected = modalServiceOrder === idx;
        const iconUrl =
          SERVICE_ICONS.has(serviceIcon as any) &&
          SERVICE_ICONS.get(serviceIcon as ServiceIconName);
        const { isDisabled, availableRoutingChannelOrder } =
          getServiceStatus(routingChannels);

        const { isAllChannelFree, highestDiscount, isDiscountFeeEqual } =
          calculateRoutingChannelsDiscount(routingChannels, amountSend);
        const canShowBadge = highestDiscount > 0;
        const _description = isDisabled
          ? volumeExceededDescription
          : description;

        return (
          <Card key={id} variant="outline" p={0}>
            <ListMenu
              title={name}
              type="accordion"
              hasBadge={canShowBadge && !isFirstTransactionFree}
              isDisabled={isDisabled}
              isSelected={isSelected}
              description={_description || ''}
              titleClassName="bold-text"
              className={cx(styled.serviceListMenu(isDisabled), {
                [styled.disabled]: isDisabled,
              })}
              badgeValue={getServiceDiscountBadge(
                isAllChannelFree,
                isDiscountFeeEqual,
                highestDiscount,
                t,
              )}
              accordionChildren={
                <ChooseServiceRoutingChannel
                  routingChannels={routingChannels}
                  amountReceive={amountReceive}
                  selectedRoutingChannel={modalRoutingChannelOrder}
                  sendAmount={amountSend}
                  shouldCheckVolume={shouldCheckVolume}
                  onSelect={setModalRoutingChannelOrder}
                />
              }
              leftIcon={
                <>
                  <Image
                    src={iconUrl}
                    width={34}
                    height={34}
                    alt={serviceIcon}
                    onLoadingComplete={() => setHasLoadedIcon(true)}
                  />
                  {!hasLoadedIcon && <div className={styled.iconSkeleton} />}
                </>
              }
              onClick={() =>
                onClickService({
                  isDisabled,
                  serviceOrder: idx,
                  availableRoutingChannelOrder,
                })
              }
            />
          </Card>
        );
      }),
    [modalServiceOrder, modalRoutingChannelOrder, hasLoadedIcon],
  );

  return (
    <div className={styled.root}>
      <h4>{t('select_service_and_delivery_speed')}</h4>
      {isFirstTransactionFree && <DiscountFeeBanner />}
      {renderedServices}
    </div>
  );
}

const styled = {
  root: css`
    .list-menu-no-bg {
      > .wrapper {
        > .content {
          > .col {
            > .title {
              align-items: center;
              > .pills {
                .xs-text {
                  font-weight: var(--bold-font-weight);
                  background-color: var(--neutral-0);
                  border: 1px solid var(--neutral-0);
                  box-shadow: 0px 2px 8px 0px rgba(46, 72, 101, 0.1);
                }
              }
            }
          }
        }
      }
    }
    h4 {
      margin-bottom: 1.5rem;
      font-size: clamp(
        var(--h5-font-size),
        1.0178571428571428rem + 0.5357142857142857vw,
        var(--h4-font-size)
      );
    }
    > div:not(:last-child) {
      margin-bottom: 1rem;
    }
  `,
  serviceListMenu: (disabled) => css`
    .left-element {
      align-items: center;
    }
    .badge-wrapper {
      background-color: ${!disabled ? 'var(--red-500)' : '#B5C8DD'} !important;
      .curve-ribbon {
        background-color: ${!disabled
          ? 'var(--red-500)'
          : '#B5C8DD'} !important;
        .back-ribbon {
          background-color: ${!disabled ? '#b62626' : '#B5C8DD'} !important;
        }
      }
    }
  `,
  disabled: css`
    cursor: default;
    pointer-events: none;
    background: var(--neutral-100) !important;
    > * {
      background: var(--neutral-100) !important;
      opacity: 1 !important;
    }
  `,
  iconSkeleton: css`
    width: 2.125rem;
    height: 2.125rem;
    margin-top: -2.54rem;
    border-radius: 100%;
    background: var(--neutral-200);
    animation: blink 1s infinite;
    @keyframes blink {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.6;
      }
    }
  `,
};
