import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { useShallow } from 'zustand/react/shallow';

import useDomesticDeliverySpeed from './use-domestic-delivery-speed';
import {
  IRecipientDocumentStore,
  useDomesticFlowStore,
} from './use-domestic-flow-store';
import useDomesticQuotation from './use-domestic-quotation';
import useDomesticRecipient from './use-domestic-recipient';
import useDomesticServices from './use-domestic-services';
import useDomesticType from './use-domestic-type';
import usePopUpDeliverySpeedChange from './use-pop-up-delivery-speed-change';
import {
  BI_FAST_ID,
  COUNTRY_IDN,
  CURRENCY_IDR,
  MAX_LIMIT_AMOUNT,
  MIN_LIMIT_AMOUNT,
} from '../constants';
import { getMinMaxVolumeDeliverySpeed } from '../helper';

export default function useDomesticAmount() {
  const { popUpDeliverySpeedChanged } = usePopUpDeliverySpeedChange();

  const { setField, amount } = useDomesticFlowStore(useShallow((s) => s));

  const { deliverySpeed, handleDeliverySpeedChange } =
    useDomesticDeliverySpeed();

  const services = useDomesticServices();
  const servicesData = services.data?.data;

  const { quotationMutation } = useDomesticQuotation();

  const { isBulk } = useDomesticType();

  const { hasChooseRecipient } = useDomesticRecipient();

  const totalServices = servicesData?.length ?? 0;

  const BIFastService = servicesData?.find(
    (service) => service.id === BI_FAST_ID,
  );
  const LLGService = servicesData?.find((service) => service.id !== BI_FAST_ID);

  const BIFastMaxVolume = BIFastService?.routingChannels[0]?.maxVolume;

  const { minVolumeDeliverySpeed, maxVolumeDeliverySpeed } =
    getMinMaxVolumeDeliverySpeed(servicesData);

  const minLimitAmount = Number(
    minVolumeDeliverySpeed?.minVolume ?? MIN_LIMIT_AMOUNT,
  );

  const maxLimitAmount = Number(
    maxVolumeDeliverySpeed?.maxVolume ??
      minVolumeDeliverySpeed?.maxVolume ??
      MAX_LIMIT_AMOUNT,
  );

  const isExceededAmount = getIsLimitExceeded({
    isBulk,
    amount: Number(amount),
    max: maxLimitAmount,
    min: minLimitAmount,
  });

  function handleAmountChange(amount: IRecipientDocumentStore['amount']) {
    setField('amount', amount);

    // Bulk limit handled by BE
    const isAmountExceededLimit = getIsLimitExceeded({
      isBulk,
      amount: Number(amount),
      max: maxLimitAmount,
      min: minLimitAmount,
    });

    if (!hasChooseRecipient || !amount || isAmountExceededLimit) {
      return;
    }

    // Automatically set delivery speed to BI-Fast if there is only one service and no selection yet.
    if (totalServices === 1) {
      if (!deliverySpeed.serviceId) {
        handleDeliverySpeedChange(
          {
            serviceId: BI_FAST_ID,
            routingChannel: RoutingChannelType.INSTANT,
          },
          amount,
        );
        return;
      }
      if (!isBulk) {
        quotationMutation.mutate({
          isSendAmount: true,
          serviceId: deliverySpeed.serviceId,
          destinationCountry: COUNTRY_IDN,
          amount: Math.abs(Number(amount)),
          destinationCurrency: CURRENCY_IDR,
          routingChannel: deliverySpeed.routingChannel,
        });
      }
      return;
    }

    if (totalServices > 1) {
      // Handle multiple services when no delivery speed is selected.
      if (!deliverySpeed.serviceId) {
        if (
          amount >= Number(minLimitAmount) &&
          amount <= Number(BIFastMaxVolume)
        ) {
          handleDeliverySpeedChange(
            {
              serviceId: BI_FAST_ID,
              routingChannel: RoutingChannelType.INSTANT,
            },
            amount,
          );

          return;
        }
        if (
          amount >= Number(minLimitAmount) &&
          amount > Number(BIFastMaxVolume) &&
          amount <= Number(maxLimitAmount)
        ) {
          handleDeliverySpeedChange(
            {
              serviceId: LLGService?.id,
              routingChannel: LLGService?.routingChannels[0]?.type,
            },
            amount,
          );
        }
        return;
      }

      // Adjust delivery speed if BI-Fast is selected but the amount exceeds its maximum volume.
      if (
        deliverySpeed.serviceId === BI_FAST_ID &&
        amount > Number(BIFastMaxVolume)
      ) {
        handleDeliverySpeedChange(
          {
            serviceId: LLGService?.id,
            routingChannel: LLGService?.routingChannels[0]?.type,
          },
          amount,
        );

        popUpDeliverySpeedChanged({
          LLGService,
          BIFastService,
        });
        return;
      }

      if (!isBulk) {
        quotationMutation.mutate({
          isSendAmount: true,
          serviceId: deliverySpeed.serviceId,
          destinationCountry: COUNTRY_IDN,
          amount: Math.abs(Number(amount)),
          destinationCurrency: CURRENCY_IDR,
          routingChannel: deliverySpeed.routingChannel,
        });
      }
    }
  }

  return {
    amount,
    maxLimitAmount,
    minLimitAmount,
    isExceededAmount,
    handleAmountChange,
  };
}

function getIsLimitExceeded({
  amount,
  isBulk,
  max,
  min,
}: {
  amount: number;
  isBulk: boolean;
  max: number;
  min: number;
}) {
  // Bulk limit handled by BE
  return isBulk ? false : amount > max || amount < min;
}
