import { useGetRateAlert } from '@api-hooks/rate-alert';
import { MOBILE_VERSION, RATE_ALERT_KEY } from '@common/storage';
import { css, cx } from '@emotion/css';
import {
  useAnalytics,
  useAuth,
  usePermission,
  usePrevQuotationFormValues,
} from '@hooks';
import { IS_CREATE_FROM_RATE } from '@modules/dashboard/rate-alert/constants';
import {
  DealRateQuotationModel,
  HolidayComparisonRateInfo,
  RemitQuotationModel,
} from '@topremit/shared-web/api-hooks/transaction';
import { ObjToCssString } from '@topremit/shared-web/common/helper';
import { isNativeApp } from '@topremit/shared-web/common/native-web-view-bridge';
import {
  AnimatedWrapper,
  Card,
} from '@topremit/shared-web/components/elements';
import { BellFill, StatsOutline } from '@topremit/shared-web/components/shapes';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { AnalyticPagesNames } from '@topremit/shared-web/typings/analytics.model';
import { useAnimationControls } from 'framer-motion';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import RateHistoryModal from './RateHistoryModal';

export enum HolidayType {
  SUPERIOR = 'SUPERIOR',
  INFERIOR = 'INFERIOR',
}

interface IRateWrapper {
  currency: string;
  rate: string;
  holidayComparisonRateInfo?: HolidayComparisonRateInfo;
  quotation?: DealRateQuotationModel | RemitQuotationModel;
  liveRate?: RemitQuotationModel;
  hasChooseService?: boolean;
  isSendAmount: boolean;
  withRateAlert?: boolean;
  disabled?: boolean;
  className?: string;
  isAppliedDealCode?: boolean;
  normalFee?: string;
}

/**
 * @deprecated Use RateInfo component instead.
 */
export default function RateWrapper({
  currency,
  quotation,
  liveRate,
  rate,
  holidayComparisonRateInfo,
  normalFee,
  isAppliedDealCode,
  hasChooseService,
  isSendAmount,
  disabled,
  className,
  withRateAlert = true,
}: IRateWrapper) {
  const controlsAnimate = useAnimationControls();
  const { t } = useTranslation();
  const { type } = holidayComparisonRateInfo || {};
  const { push, query } = useRouter();
  const { addNotification } = useNotification();
  const {
    analyticsPersonal: { tracksPersonal },
  } = useAnalytics();
  const { storePrevQuotationFormValues } = usePrevQuotationFormValues();

  const { isAuthenticated } = useAuth();
  const { isBusinessAccount } = usePermission();
  const isShowRateAlert =
    withRateAlert && isAuthenticated && !isBusinessAccount;

  const { data: rateAlert } = useGetRateAlert({
    enabled: isShowRateAlert,
  });
  const showDialog = useDialogStore((store) => store.show);

  const rootClassName = cx(
    'coachmark',
    styled.rateWrapper(type === HolidayType.INFERIOR, disabled),
    className,
  );

  function renderHolidayComparisonRateInfo() {
    switch (type) {
      case HolidayType.INFERIOR:
        return (
          <div className="rate-badge">
            {t('best_rate')}
            <div className="curve-ribbon">
              <div className="back-ribbon" />
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function renderRateAlertIcon() {
    const isHideRateAlertIcon =
      (isNativeApp() &&
        (!query.buildNumber ||
          (query.buildNumber &&
            Number(query.buildNumber) <= MOBILE_VERSION))) ||
      isAppliedDealCode;

    if (isHideRateAlertIcon) {
      return null;
    } else {
      return (
        <div className={styled.rateAlert}>
          <div className="circle circle-info" onClick={handleClickAlert}>
            <BellFill fill="var(--blue-500)" />
          </div>
        </div>
      );
    }
  }

  function handleClickRate() {
    if (disabled) {
      return;
    }
    showDialog({
      body: (
        <RateHistoryModal
          currency={currency}
          holidayComparisonRateInfo={holidayComparisonRateInfo}
        />
      ),
    });
  }

  function handleClickAlert() {
    if (disabled) {
      return;
    }
    tracksPersonal.rateAlert({ source: AnalyticPagesNames.CALCULATOR });
    if (quotation || liveRate) {
      const {
        serviceId,
        sourceAmount,
        destinationAmount,
        routingChannel,
        destinationCountry,
        destinationCurrency,
        fxRate,
      } =
        (quotation as RemitQuotationModel) || (liveRate as RemitQuotationModel);
      storePrevQuotationFormValues({
        serviceId,
        amountSend: sourceAmount,
        amountReceive: destinationAmount,
        isSendAmount,
        countryAndCurrency: {
          country: destinationCountry,
          currency: destinationCurrency,
        },
        destinationCountry,
        destinationCurrency,
        routingChannel,
      });

      if (rateAlert?.data.length === 5) {
        push({ pathname: '/rate-alert' });
        addNotification({
          message: t('rate-alert:create_limit_notification'),
          type: 'danger',
          closeable: false,
        });
        return;
      }

      const payload = hasChooseService
        ? {
            serviceId,
            routingChannel,
            destinationCountry,
            destinationCurrency,
            rate: fxRate.replace(',', ''),
          }
        : {
            destinationCountry,
            destinationCurrency,
          };
      localStorage.setItem(
        RATE_ALERT_KEY,
        JSON.stringify({
          ...payload,
          country: payload.destinationCountry,
          currency: payload.destinationCurrency,
        }),
      );
      window.sessionStorage.removeItem(IS_CREATE_FROM_RATE);
    }
    push({ pathname: '/rate-alert/form' });
  }

  useEffect(() => {
    controlsAnimate.start({
      y: [0, 15, -15, 0],
      opacity: [1, 0.5, 0, 0.5, 1],
    });
  }, [rate]);

  return (
    <div className={styled.root}>
      <Card className={rootClassName} onClick={handleClickRate}>
        <div className="title sm-text">
          {t('rate')}
          <StatsOutline width={20} height={20} className="icon" />
        </div>
        <div className="sm-text rate">
          {currency} 1 =
          {normalFee && <span className="strikethrough">IDR {normalFee}</span>}
          <AnimatedWrapper
            htmlTag="div"
            animate={controlsAnimate}
            className="rate-wrapper"
          >
            IDR {rate}
          </AnimatedWrapper>
        </div>
        {renderHolidayComparisonRateInfo()}
      </Card>
      {isShowRateAlert && renderRateAlertIcon()}
    </div>
  );
}

const styled = {
  root: css`
    display: flex;
    align-items: center;
  `,
  rateWrapper: (bestRate: boolean, disabled?: boolean) => css`
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    margin-top: ${bestRate ? '1.75rem' : '1rem'};
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    ${disabled &&
    ObjToCssString({
      pointerEvents: 'none',
      backgroundColor: 'var(--neutral-100)!important',
      boxShadow: 'none',
    })}
    > .title {
      margin-right: auto;
      display: flex;

      > .icon {
        margin-left: 0.5rem;
      }
    }
    .circle {
      width: 1.25rem;
      height: 1.25rem;
      background-color: var(--blue-100);
      margin-left: 0.5rem;
    }
    .rate-badge {
      position: absolute;
      top: -0.75rem;
      right: -0.3125rem;
      padding: 0.125rem 0.5rem;
      background-color: var(--red-500);
      border-radius: 0.25rem 0.25rem 0;
      color: var(--neutral-0);
      font-size: 0.75rem;
      line-height: 0.975rem;
      .curve-ribbon {
        position: absolute;
        background-color: var(--red-500);
        bottom: -0.125rem;
        right: 0;
        width: 0.3125rem;
        height: 0.125rem;
        .back-ribbon {
          background-color: #b62626;
          width: 0.3125rem;
          height: 0.25rem;
          border-radius: 0 0.25rem 0.25rem 0;
        }
      }
    }
    .rate {
      display: flex;
      flex-direction: row;
      > .strikethrough {
        margin-right: 0.25rem;
        margin-left: 0.2rem;
      }

      > .rate-wrapper {
        margin-left: 0.2rem;
      }
    }
    .holiday-rate-tooltip {
      .ant-popover {
        animation-duration: 0s !important;
      }
      .ant-popover-title {
        height: 0.875rem;
        margin-bottom: 0;
      }
    }
    .select-service-speed {
      background: var(--red-500);
    }
  `,
  rateAlert: css`
    display: flex;
    margin: 1rem 0 1.5rem 1rem;
    > .circle {
      cursor: pointer;
      width: 2.875rem;
      height: 2.875rem;
    }
  `,
};
