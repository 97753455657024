import { useShallow } from 'zustand/react/shallow';

import { useDomesticFlowStore } from './use-domestic-flow-store';
import { SetErrorParams } from '../types';

export default function useDomesticError() {
  const {
    setField,
    hardLimitMessage,
    errorServicesMessage,
    errorRecipientMessage,
    errorRecipientBatchesIds,
  } = useDomesticFlowStore(useShallow((s) => s));

  function setError({ type, error }: SetErrorParams) {
    if (type === 'recipient') {
      setField('errorRecipientMessage', error);
      return;
    }
    if (type === 'services') {
      setField('errorServicesMessage', error);
      return;
    }
    if (type === 'hardLimit') {
      setField('hardLimitMessage', error);
      return;
    }
    if (type === 'recipientBatches') {
      setField('errorRecipientBatchesIds', error);
    }
  }

  return {
    errorRecipientBatchesIds,
    errorRecipientMessage,
    errorServicesMessage,
    hardLimitMessage,
    setError,
  };
}
