import { useShallow } from 'zustand/react/shallow';

import {
  IRecipientDocumentStore,
  useDomesticFlowStore,
} from './use-domestic-flow-store';

export default function useDomesticNote() {
  const { setField, note } = useDomesticFlowStore(useShallow((s) => s));

  function handleNoteChange(note: IRecipientDocumentStore['note']) {
    setField('note', note);
  }

  return {
    note,
    handleNoteChange,
  };
}
