import { UNCOMPLETED_QUOTATION_KEY } from '@common/storage';
import { PersonalQuotationFormValues } from '@topremit/shared-web/api-hooks/transaction';
import { useState } from 'react';

interface UsePrevQuotationResult {
  prevQuotationFormValues: PersonalQuotationFormValues | null;
  emptyPrevQuotationFormValues(): void;
  storePrevQuotationFormValues(values: PersonalQuotationFormValues): void;
}

export function usePrevQuotationFormValues(): UsePrevQuotationResult {
  const [prevQuotationFormValues, setPrevQuotationFormValues] = useState(() => {
    if (typeof window !== 'undefined') {
      const savedQuotation = window.localStorage.getItem(
        UNCOMPLETED_QUOTATION_KEY,
      );

      return savedQuotation ? JSON.parse(savedQuotation) : null;
    }
    return null;
  });

  function emptyPrevQuotationFormValues() {
    window.localStorage.removeItem(UNCOMPLETED_QUOTATION_KEY);
    setPrevQuotationFormValues(null);
  }

  function storePrevQuotationFormValues(values) {
    const stringifiedValues = JSON.stringify(values);

    window.localStorage.setItem(UNCOMPLETED_QUOTATION_KEY, stringifiedValues);
    setPrevQuotationFormValues(values);
  }

  return {
    prevQuotationFormValues,
    emptyPrevQuotationFormValues,
    storePrevQuotationFormValues,
  };
}
