import { getDiscountedFee } from '@elements/choose-service/helper';
import { css } from '@emotion/css';
import { color } from '@global-styles/color';
import { RoutingChannelDiscount } from '@topremit/shared-web/api-hooks/common';
import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import { Button } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { format } from 'date-fns';

interface IChooseServiceModalFooter {
  fee: number;
  validUntil: string | null;
  amountSend: number;
  discountValue: number;
  correspondentFee?: number;
  discountType?: RoutingChannelDiscount['discountType'];
  discountMinimumAmount?: number;
  onClickBtnApplyService: () => void;
}

interface FeeInfoInlineProps {
  label: string;
  value: string;
  normalFee?: number;
  validUntil?: string;
  discountValue?: number;
  isAmountExceed?: boolean;
}

/**
 * @deprecated use <FeeInfo /> instead
 */
function FeeInfoInline({
  label,
  value,
  normalFee,
  validUntil,
  discountValue,
  isAmountExceed,
}: FeeInfoInlineProps) {
  const _normalFee = formatToIDRCurrency(Number(normalFee));
  const hasDiscountFee =
    normalFee && Number(discountValue) > 0 && isAmountExceed;

  return (
    <div className="fee">
      <span className="left bold-text">{label}</span>
      <div className="right">
        <div className="fee-detail">
          {hasDiscountFee && (
            <span className="strikethrough xs-text">{_normalFee}</span>
          )}
          <span style={{ color: color.blue500 }} className="bold-text">
            {value}
          </span>
        </div>
        {validUntil && <span className="valid-until">{validUntil}</span>}
      </div>
    </div>
  );
}

/**
 * @deprecated use <ChooseService /> instead
 */
export default function ChooseServiceModalFooter({
  fee,
  discountType,
  amountSend,
  validUntil,
  discountValue,
  correspondentFee,
  discountMinimumAmount,
  onClickBtnApplyService,
}: IChooseServiceModalFooter) {
  const { t } = useTranslation();

  const isAmountExceed = (amountSend || 0) >= Number(discountMinimumAmount);

  const canShowFeeInfo = fee > 0;
  const isDisabledBtnApply = fee === 0;
  const discountedFee =
    isAmountExceed && discountType
      ? getDiscountedFee(discountValue, discountType, fee)
      : fee;

  const _discountedFee = formatToIDRCurrency(discountedFee);
  const _correspondentFee =
    correspondentFee && formatToIDRCurrency(correspondentFee);

  const formattedValidUntil =
    isAmountExceed && validUntil
      ? ` ${t('home:valid_until')}  ${format(
          new Date(validUntil),
          'dd MMM yyyy',
        )}`
      : '';

  return (
    <div className={styled.root}>
      {_correspondentFee ? (
        <FeeInfoInline
          value={_correspondentFee}
          label={t('transaction:correspondent_fee')}
        />
      ) : null}
      {canShowFeeInfo && (
        <FeeInfoInline
          normalFee={fee}
          label={t('fee')}
          value={_discountedFee}
          discountValue={discountValue}
          validUntil={formattedValidUntil}
          isAmountExceed={isAmountExceed}
        />
      )}

      <Button
        fullWidth
        disabled={isDisabledBtnApply}
        onClick={onClickBtnApplyService}
      >
        {t('apply')}
      </Button>
    </div>
  );
}

const styled = {
  root: css`
    .fee {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      > .left {
        font-size: var(--sm-font-size);
        font-size: clamp(
          var(--sm-font-size),
          0.8309859154929577rem + 0.18779342723004694vw,
          1rem
        );
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .fee-detail {
          gap: 0.25rem;
          display: flex;
          align-items: center;
          .link {
            font-size: 1rem;
            font-size: clamp(
              1rem,
              0.8309859154929577rem + 0.18779342723004694vw,
              var(--h5-font-size)
            );
          }
        }
        .valid-until {
          color: var(--text-secondary);
          font-size: var(--sm-font-size);
        }
      }
    }
  `,
};
