import MultiRecipientSummaryCard from '@modules/recipient/MultiRecipientSummaryCard';
import { useRouter } from 'next/router';

import useDomesticFlow from './hooks/use-domestic-flow';
import { DomesticRecipientStep } from './types';

function BulkAmountSummaryCard() {
  const router = useRouter();
  const {
    errors: { errorRecipientMessage },
    values: { bulkRecipients, note, amount },
    handlers: { handleNoteChange },
    stepper: { handleStepChange },
  } = useDomesticFlow();

  function handleClickSummary() {
    handleStepChange(router, DomesticRecipientStep.AMOUNT);
  }

  return (
    <MultiRecipientSummaryCard
      notesValue={note}
      error={errorRecipientMessage}
      totalAmount={Number(amount ?? 0)}
      totalRecipients={bulkRecipients.length}
      onClick={handleClickSummary}
      onNotesValueChange={handleNoteChange}
    />
  );
}

export default BulkAmountSummaryCard;
