import { useGetMe, usePostHasSeenOnboardings } from '@api-hooks/common';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, Flex, Text } from '@topremit/shared-web/components/elements';
import { useMediaQuery, useTranslation } from '@topremit/shared-web/hooks';
import { useEffect } from 'react';
import ReactJoyride, { Step, Styles } from 'react-joyride';

const SPOTLIGHT_PADDING = 20;
const MINIMUM_SCROLL_OFFSET = 1000;

interface DomesticDeliverySpeedCoachmarkProps {
  run: boolean;
  onRunChange: (run: boolean) => void;
  onOpenDeliverySpeedChange: (open: boolean) => void;
}

function DomesticDeliverySpeedCoachmark({
  run,
  onRunChange,
  onOpenDeliverySpeedChange,
}: DomesticDeliverySpeedCoachmarkProps) {
  const { t } = useTranslation('transaction');
  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);

  const { refetch: refetchMe } = useGetMe();

  const {
    mutate: mutateUpdateShowCoachmark,
    isLoading: isLoadingUpdateShowCoachmark,
  } = usePostHasSeenOnboardings({
    onSuccess: () => {
      onRunChange(false);
      onOpenDeliverySpeedChange(false);
      refetchMe();
    },
  });

  function handleCloseJoyRide() {
    mutateUpdateShowCoachmark({
      type: 'domestic_llg',
    });
  }

  const step: Step[] = [
    {
      content: (
        <Flex className={styled.contentJoyride} column>
          <Text className="bold-text">
            {t('domestic.coach_mark.speed.title')}
          </Text>
          <Text className="xs-text">{t('domestic.coach_mark.speed.desc')}</Text>
          <Flex className="button-wrapper" justify="flex-end">
            <Button
              size="small"
              className="okay-button"
              onClick={handleCloseJoyRide}
              isLoading={isLoadingUpdateShowCoachmark}
            >
              {t('common:okay_got_it')}
            </Button>
          </Flex>
        </Flex>
      ),
      floaterProps: {
        disableAnimation: true,
      },
      hideCloseButton: true,
      hideFooter: true,
      disableBeacon: true,
      disableOverlayClose: true,
      placement: 'top',
      spotlightPadding: 10,
      target: '.delivery-speed',
    },
  ];

  const styles: Partial<Styles> = {
    tooltip: {
      borderRadius: '0.875rem',
      padding: '0.875rem 1.25rem',
      width: isMobileSize ? '19.5rem' : '21.875rem',
    },
    tooltipContent: { padding: 0 },
    spotlight: {
      borderRadius: '0.5rem',
    },
    options: {
      zIndex: 1000,
    },
  };

  useEffect(() => {
    return () => {
      onRunChange(false);
      onOpenDeliverySpeedChange(false);
    };
  }, []);

  return (
    <ReactJoyride
      run={run}
      continuous
      steps={step}
      hideCloseButton
      styles={styles}
      scrollOffset={MINIMUM_SCROLL_OFFSET}
      spotlightPadding={SPOTLIGHT_PADDING}
    />
  );
}

const styled = {
  contentJoyride: css`
    text-align: left;
    .button-wrapper {
      .okay-button {
        margin-top: 0.5rem;
      }
    }
  `,
};

export default DomesticDeliverySpeedCoachmark;
