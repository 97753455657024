import { MaintenancePayerDetail } from '@api-hooks/domestic';
import { css } from '@emotion/css';
import { BankOutline, Flex, ListMenu, Text } from '@topremit/shared-web';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import { useTranslation } from '@topremit/shared-web/hooks';
import Image from 'next/image';

const getHasDivider = (index, arrayLength) => index !== arrayLength - 1;

export default function BankUnderMaintenanceList({
  maintenanceBankPayers,
}: {
  maintenanceBankPayers: MaintenancePayerDetail[];
}) {
  const { t } = useTranslation('transaction');

  return (
    <Flex>
      <Text className={styled.title}>
        {t('maintenance_info.under_maintenance')}
      </Text>
      {maintenanceBankPayers.map((payer, i) => (
        <ListMenu
          className={styled.list}
          leftIcon={
            payer.logo ? (
              <Image alt={payer.name} src={payer.logo} width={32} height={32} />
            ) : (
              <BankOutline width={26} height={26} />
            )
          }
          key={payer.id}
          type="empty"
          title={payer.name}
          description={payer.message}
          hasDivider={getHasDivider(i, maintenanceBankPayers.length)}
        />
      ))}
    </Flex>
  );
}

const styled = {
  list: css`
    padding-left: 0;
  `,
  title: css`
    margin-bottom: 16px;
    font-size: ${typography.body1Bold.fontSize}rem;
    font-weight: ${typography.body1Bold.fontWeight};

    @media (max-width: ${screenSize.tabletSm}px) {
      font-size: ${typography.body2Bold.fontSize}rem;
      font-weight: ${typography.body2Bold.fontWeight};
    }
  `,
};
