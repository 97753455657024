import { Voucher, VoucherState } from '@api-hooks/transaction';
import { create } from 'zustand';

import { SetStateStore, setStore } from './helper';

interface IVoucherStore {
  voucher: Voucher | null;
  voucherState: VoucherState;
  setVoucher: (value: SetStateStore<Voucher | null>) => void;
  setVoucherState: (value: SetStateStore<VoucherState>) => void;
}

const initialState = { voucher: null, voucherState: VoucherState.CHOOSE };

export const useVoucherStore = create<IVoucherStore>((set) => ({
  ...initialState,
  setVoucher: (value) => set((state) => setStore(state, { voucher: value })),
  setVoucherState: (value) =>
    set((state) => setStore(state, { voucherState: value })),
}));
