import { useShallow } from 'zustand/react/shallow';

import useDomesticError from './use-domestic-error';
import {
  IRecipientDocumentStore,
  useDomesticFlowStore,
} from './use-domestic-flow-store';
import useDomesticQuotation from './use-domestic-quotation';
import useDomesticType from './use-domestic-type';
import { COUNTRY_IDN, CURRENCY_IDR } from '../constants';

export default function useDomesticDeliverySpeed() {
  const {
    setField,
    deliverySpeed,
    amount: amountStore,
  } = useDomesticFlowStore(useShallow((s) => s));

  const { setError } = useDomesticError();
  const { quotationMutation } = useDomesticQuotation();
  const { isBulk } = useDomesticType();

  function handleDeliverySpeedChange(
    deliverySpeed: IRecipientDocumentStore['deliverySpeed'],
    amount?: IRecipientDocumentStore['amount'],
  ) {
    const _amount = amount ?? amountStore;
    setField('deliverySpeed', deliverySpeed);
    setField('amount', _amount);
    setError({ type: 'services', error: '' });

    if (!isBulk && deliverySpeed.serviceId && deliverySpeed.routingChannel) {
      quotationMutation.mutate({
        isSendAmount: true,
        destinationCountry: COUNTRY_IDN,
        amount: Math.abs(Number(_amount)),
        destinationCurrency: CURRENCY_IDR,
        serviceId: deliverySpeed.serviceId,
        routingChannel: deliverySpeed.routingChannel,
      });
    }
  }

  return {
    deliverySpeed,
    handleDeliverySpeedChange,
  };
}
