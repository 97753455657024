export const BI_FAST_ID = 12;

export const COUNTRY_IDN = 'IDN';
export const CURRENCY_IDR = 'IDR';
export const PURPOSE_ID_KEY = 'PURPOSE-ID';
export const RECIPIENT_ID_KEY = 'RECIPIENT-ID';
export const ADDITIONAL_NOTES_KEY = 'ADDITIONAL-NOTES';
export const DOMESTIC_PREV_STEP = 'DOMESTIC_PREV_STEP';
export const MULTI_ENTER_AMOUNT = 'MULTI_ENTER_AMOUNT';

export const MIN_LIMIT_AMOUNT = 10_000;
export const MAX_LIMIT_AMOUNT = 250_000_000;
export const MAX_MULTI_LIMIT_AMOUNT = 2_500_000_000;

export const MAX_SELECTED_MULTIPLE_RECIPIENT = 25;

export enum Segment {
  PERSONAL = 'PERSONAL',
  BUSINESS = 'BUSINESS',
}

export enum DomesticRecipient {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}
