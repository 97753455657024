import { ContainerSpinner } from '@topremit/shared-web/components/elements';
import dynamic from 'next/dynamic';

export const LimitExceed = dynamic(() => import('./LimitExceed'), {
  loading: () => <ContainerSpinner />,
});
export const SpeedChangedModalBody = dynamic(
  () => import('./SpeedChangedModalBody'),
  {
    loading: () => <ContainerSpinner />,
  },
);
export const SpeedNotSupported = dynamic(() => import('./SpeedNotSupported'), {
  loading: () => <ContainerSpinner />,
});

export * from './delivery-speed-change';
