import { css } from '@emotion/css';
import {
  Button,
  Flex,
  NumberInput,
} from '@topremit/shared-web/components/elements';
import { Check } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';

interface AccountNumberCheckerProps {
  name: string;
  loading?: boolean;
  className?: string;
  disabled?: boolean;
  hasCheckBankAccount: boolean;
  onCheck: () => void;
}

export default function AccountNumberChecker({
  name,
  loading,
  disabled,
  className,
  hasCheckBankAccount,
  onCheck,
}: AccountNumberCheckerProps) {
  const { t } = useTranslation();
  return (
    <Flex column className={className}>
      <NumberInput
        name={name}
        inputMode="tel"
        checkMax={false}
        allowLeadingZeros
        allowDecimal={false}
        className={styled.accountNumber}
        label={t('abad.form.label.account_number')}
        rightSection={
          <Button
            size="small"
            type="tertiary"
            onClick={onCheck}
            htmlType="button"
            isLoading={loading}
            disabled={disabled}
            className={styled.check(hasCheckBankAccount)}
            {...(hasCheckBankAccount && {
              leftIcon: (
                <Check
                  fill={hasCheckBankAccount ? color.green500 : color.neutral300}
                  size="small"
                />
              ),
            })}
          >
            {hasCheckBankAccount ? t('common:checked') : t('common:check')}
          </Button>
        }
      />
    </Flex>
  );
}

const styled = {
  check: (hasCheckBankAccount: boolean) => css`
    margin-right: 0.5rem;
    span {
      color: ${hasCheckBankAccount ? color.green500 : ''};
    }
  `,
  accountNumber: css`
    margin: 0 !important;
  `,
};
