import { MaintenancePayerDetail } from '@api-hooks/domestic';
import BankUnderMaintenanceList from '@modules/landing/jumbotron/BankUnderMaintenanceList';
import { DelayFill, Notes, Text } from '@topremit/shared-web';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

interface RecipientPayersMaintenanceNoteProps {
  className?: string;
  isMultiple?: boolean;
  maintenanceBankpayers?: MaintenancePayerDetail[];
}

export default function RecipientPayersMaintenanceNote({
  className,
  isMultiple,
  maintenanceBankpayers,
}: RecipientPayersMaintenanceNoteProps) {
  const { t } = useTranslation('transaction');
  const showDialog = useDialogStore((state) => state.show);
  const isMaintenanceBankShowable = !!(maintenanceBankpayers && isMultiple);

  function showMaintenanceBanks() {
    showDialog({
      body: (
        <BankUnderMaintenanceList
          // was checked in isMaintenanceBankShowable variable
          maintenanceBankPayers={maintenanceBankpayers!}
        />
      ),
    });
  }

  function renderMultipleNote() {
    return (
      <Text>
        {t('maintenance_info.some_bank')}{' '}
        <Text as="a" onClick={showMaintenanceBanks}>
          {t('maintenance_info.see_list_bank')}
        </Text>
      </Text>
    );
  }
  return (
    <Notes
      showIcon
      className={className}
      title={
        isMaintenanceBankShowable
          ? renderMultipleNote()
          : t('maintenance_info.recipient_bank')
      }
      status="warning"
      icon={<DelayFill />}
      style={{ marginTop: '16px' }}
    />
  );
}
