import { dynamicImportWithSpinner } from '@topremit/shared-web/common/import';

import RecipientType from './RecipientType';
import useDomesticStepper from './hooks/use-domestic-stepper';
import { DomesticRecipientStep } from './types';

const ChooseRecipient = dynamicImportWithSpinner(
  () => import('./ChooseRecipient'),
);
const AddDestinationBankAccount = dynamicImportWithSpinner(
  () => import('./AddDestinationBankAccount'),
);
const RecipientAmount = dynamicImportWithSpinner(
  () => import('./RecipientAmount'),
);

const recipient = {
  [DomesticRecipientStep.TYPE]: <RecipientType />,
  [DomesticRecipientStep.CHOOSE]: <ChooseRecipient />,
  [DomesticRecipientStep.AMOUNT]: <RecipientAmount />,
  [DomesticRecipientStep.ADD]: <AddDestinationBankAccount />,
};

function RecipientStepDomestic() {
  const { step } = useDomesticStepper();
  return recipient[step];
}

export default RecipientStepDomestic;
