import { useShallow } from 'zustand/react/shallow';

import {
  IRecipientDocumentStore,
  useDomesticFlowStore,
} from './use-domestic-flow-store';

export default function useDomesticPurpose() {
  const { setField, purpose } = useDomesticFlowStore(useShallow((s) => s));

  function handlePurposeChange(purpose: IRecipientDocumentStore['purpose']) {
    setField('purpose', purpose);
  }
  return {
    purpose,
    handlePurposeChange,
  };
}
