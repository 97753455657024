import { css } from '@emotion/css';
import { useBanner } from '@hooks';
import { BolyFailed } from '@shapes';
import { Button, Flex, Text } from '@topremit/shared-web/components/elements';
import { getUrlValue, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useRouter } from 'next/router';

export default function ExpireDealRateModal() {
  const { t } = useTranslation('transaction');
  const router = useRouter();
  const { replace, query, pathname } = router;
  const { hideBanner } = useBanner();
  const closeDialog = useDialogStore((store) => store.close);

  function onClickButton() {
    closeDialog();
    hideBanner();
    /**
     * Redirects to the quote page if the deal code expires at quote or confirmation trx page.
     * Checking by path because confirmation page already has a temp ref ID.
     */
    if (!query.refId || pathname.includes('confirm-transaction-details')) {
      return replace({ pathname: '/quote', query: { refresh: true } });
    }
    replace({
      pathname: `/activity/transaction/detail/${query.refId}`,
      query: getUrlValue({ ...router.query, source: router.asPath }),
    });
  }

  return (
    <Flex column className={styled.root}>
      <BolyFailed />
      <Text as="h5">{t('deal_rate.modal_expired.title')}</Text>
      <Text className="description">{t('deal_rate.modal_expired.desc')}</Text>
      <Button onClick={onClickButton} fullWidth>
        {t('deal_rate.modal_expired.button')}
      </Button>
    </Flex>
  );
}

const styled = {
  root: css`
    align-items: center;
    text-align: center;

    > button {
      margin-top: 1.5rem;
    }
  `,
};
