import { acceptfile } from './DragDropInput';

export function acceptTypeConvert(data: string[]) {
  const result: { [key: string]: string[] } = {};

  data?.forEach((type) => {
    for (const [key, value] of Object.entries(acceptfile)) {
      const ext = '.' + (type?.split('/')?.[1] || '');
      if (value.includes(ext)) {
        if (!result[key]) {
          result[key] = [];
        }
        result[key].push(ext);
      }
    }
  });

  return result;
}
