import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, Flex, Text } from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { Searchbar } from '@topremit-ui/searchbar';
import { useState } from 'react';

interface ChooseRecipientHeaderProps {
  className?: string;
  buttonLabel: string | undefined;
  onClickButton: () => void;
  onSearchChange?: (value: string) => void;
}

function ChooseRecipientHeader({
  className,
  buttonLabel,
  onClickButton,
  onSearchChange,
}: ChooseRecipientHeaderProps) {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');

  function handleSearchChange(value: string) {
    setSearch(value);
    onSearchChange?.(value);
  }
  return (
    <Flex column className={cx(styled.header, className)}>
      <Flex align="center" justify="space-between" style={{ marginBottom: 16 }}>
        <Text as="h4" className={styled.title}>
          {t('calculator:choose_recipient')}
        </Text>
        {buttonLabel && (
          <Button type="invert" size="small" onClick={onClickButton}>
            {buttonLabel}
          </Button>
        )}
      </Flex>
      <Searchbar
        value={search}
        placeholder={t('calculator:search_recipient')}
        onChange={handleSearchChange}
      />
    </Flex>
  );
}

const styled = {
  header: css`
    margin-top: 1rem;
  `,
  title: css`
    margin: 0;
    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: 1.125rem;
    }
  `,
};

export default ChooseRecipientHeader;
