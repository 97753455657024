import {
  RoutingChannelDiscount,
  RoutingChannelModel,
} from '@topremit/shared-web/api-hooks/common';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';

import { IChooseServiceProps, IChooseServiceValue } from './types';

export function getSelectedValue(
  options: IChooseServiceProps['options'],
  value: IChooseServiceValue,
) {
  const selectedService = options.find(
    (option) =>
      option.id === value.serviceId &&
      option.routingChannels.some(
        (routingChannel) => routingChannel.type === value.routingChannel,
      ),
  );

  const selectedRoutingChannel = selectedService?.routingChannels.find(
    (routingChannel) => routingChannel.type === value.routingChannel,
  );

  return {
    selectedService,
    selectedRoutingChannel,
  };
}

/**
 * Transform routing channel discount from array to object,
 * while tranforming do some calculation.
 * @param routingChannels
 * @param sendAmount
 * @returns An object containing:
 *  - isAllChannelFree: true if all channel is free
 *  - isDiscountFeeEqual: true if all applied discounts have the same value
 *  - highestDiscount: the largest discount amount across all channels
 *  - routingChannelDiscounts: detailed discount information for each channel type
 */
export function calculateRoutingChannelsDiscount(
  routingChannels: RoutingChannelModel[],
  sendAmount: number,
) {
  const routingChannelDiscounts: {
    [key in RoutingChannelType]?: { discount: number; isFree: boolean };
  } = {};
  let isAllChannelFree = true; // true if all speed fee is free
  let highestDiscount = 0;
  let isAllChannelsHasDiscount = true;
  const discountAmounts = new Set<number>();

  for (const routingChannel of routingChannels) {
    const { fee, discount, type } = routingChannel;
    if (!discount) {
      isAllChannelFree = false;
      isAllChannelsHasDiscount = false;
      continue;
    }
    const { value, discountType, minimumAmount } = discount;
    if (!isDiscountValid(sendAmount, minimumAmount)) {
      isAllChannelFree = false;
      isAllChannelsHasDiscount = false;
      continue;
    }

    const discountAmount = getDiscountAmount(value, discountType, fee);
    highestDiscount = Math.max(highestDiscount, discountAmount);
    const isFeeFree = fee === discountAmount;

    isAllChannelFree = isAllChannelFree && isFeeFree;
    discountAmounts.add(discountAmount);

    routingChannelDiscounts[type] = {
      discount: discountAmount,
      isFree: isFeeFree,
    };
  }

  return {
    isAllChannelFree,
    isDiscountFeeEqual: isAllChannelsHasDiscount && discountAmounts.size === 1,
    highestDiscount,
    routingChannelDiscounts,
  };
}

/**
 * There are two ways to calculate a discount,
 * percentage or direct value deduction.
 * This function generalizes the calculation of the discount amount.
 */
export function getDiscountAmount(
  discountValue: number,
  discountType: RoutingChannelDiscount['discountType'],
  fee: number,
) {
  if (!discountValue) return 0;
  if (discountType === 'DISCOUNT_PERCENTAGE_FEE') {
    return (fee * discountValue) / 100;
  }
  return discountValue;
}

export function getDiscountedFee(
  discountValue: number,
  discountType: RoutingChannelDiscount['discountType'],
  fee: number,
) {
  return fee - getDiscountAmount(discountValue, discountType, fee);
}

/**
 * Checks if the discount meets the minimum amount required to apply it.
 *
 * @param {number} amonut - The amount user send.
 * @param {number} minimumAmount - The minimum amount required for discount.
 * @returns {boolean} - Returns true if the discount meets the minimum amount, otherwise false.
 */
export function isDiscountValid(sendAmount: number, minimumAmount?: number) {
  return typeof minimumAmount === 'number' ? sendAmount >= minimumAmount : true;
}
