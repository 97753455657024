import { ChooseDeliverySpeed } from '@elements';
import { css, cx } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { useState } from 'react';

import DomesticDeliverySpeedCoachmark from './DomesticDeliverySpeedCoachmark';
import useDomesticCoachmark from './hooks/use-domestic-coachmark';
import useDomesticFlow from './hooks/use-domestic-flow';

interface DeliverySpeedSelectorProps {
  onClick?: () => void;
}

function DeliverySpeedSelector({ onClick }: DeliverySpeedSelectorProps) {
  const [open, setOpen] = useState(false);

  const { runCoachmark, setRunCoachmark } = useDomesticCoachmark({
    open,
    setOpen,
  });

  const {
    data: { services },
    errors: { errorServicesMessage },
    values: { amount, deliverySpeed, hasChooseRecipient, isLoadingQuotation },
    handlers: { handleDeliverySpeedChange },
  } = useDomesticFlow();

  const servicesDataWithDisabledState =
    services.data?.data.map((option) => {
      return {
        ...option,
        routingChannels: option.routingChannels.map((routingChannel) => {
          return {
            ...routingChannel,
            disabled: Number(amount) > routingChannel.maxVolume,
          };
        }),
      };
    }) ?? [];

  const selectedRoutingChannel = servicesDataWithDisabledState
    ?.find((option) => option.id === deliverySpeed.serviceId)
    ?.routingChannels.find(
      (routingChannel) => routingChannel.type === deliverySpeed.routingChannel,
    );

  const isDisabled = services.isLoading || isLoadingQuotation;

  /**
   * Determines whether the "ChooseDeliverySpeed" component should be rendered:
   * - If there is only one service available (`servicesData.length === 1`),
   *   the component is rendered only when `recipient`, `amount`, and `deliverySpeed.serviceId` are defined.
   * - If there are multiple services (`servicesData.length > 1`),
   *   the component is always rendered regardless of other conditions.
   */
  const canShowSelector =
    (servicesDataWithDisabledState?.length === 1 &&
      hasChooseRecipient &&
      amount &&
      deliverySpeed.serviceId) ||
    Number(servicesDataWithDisabledState?.length ?? 0) > 1;

  const canShowDiscountFee =
    Number(amount ?? 0) >
    Number(selectedRoutingChannel?.discount?.minimumAmount ?? 0);

  function handleOpenChange(open: boolean) {
    if (!hasChooseRecipient || !amount) {
      setOpen(false);
      return;
    }
    setOpen(open);
  }

  if (!canShowSelector) {
    return null;
  }

  return (
    <>
      <ChooseDeliverySpeed
        open={open}
        value={deliverySpeed}
        disabled={isDisabled}
        error={errorServicesMessage}
        showDiscountFee={canShowDiscountFee}
        options={servicesDataWithDisabledState}
        onClick={onClick}
        onOpenChange={handleOpenChange}
        onValueChange={handleDeliverySpeedChange}
        className={cx('delivery-speed', styled.chooseDeliverySpeed(isDisabled))}
      />

      <DomesticDeliverySpeedCoachmark
        run={runCoachmark}
        onRunChange={setRunCoachmark}
        onOpenDeliverySpeedChange={setOpen}
      />
    </>
  );
}

const styled = {
  chooseDeliverySpeed: (isDisabled: boolean) => css`
    opacity: ${isDisabled ? 0.6 : 1};
    margin-top: 24px;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin-top: 16px;
    }
  `,
};

export default DeliverySpeedSelector;
