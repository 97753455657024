import { useGetMe } from '@api-hooks/common';
import { scrollToElement } from '@topremit/shared-web/common/helper';
import { useEffect, useState } from 'react';

import useDomesticFlow from './use-domestic-flow';

interface UseDomesticCoachmarkProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export default function useDomesticCoachmark({
  open,
  setOpen,
}: UseDomesticCoachmarkProps) {
  const { data: me, isFetching: isFetchingMe } = useGetMe();

  const {
    data: { services },
    values: { deliverySpeed },
  } = useDomesticFlow();

  const [runCoachmark, setRunCoachmark] = useState(false);

  const servicesData = services.data?.data;

  useEffect(() => {
    const canShowCoachmark =
      me?.data.onboarding.domesticLlg.showable &&
      deliverySpeed.serviceId &&
      servicesData?.length! > 1 &&
      !isFetchingMe;

    if (canShowCoachmark) {
      if (!open) {
        setOpen(true);
        /**
         * @note scroll to bottom
         * for better coachmark readability of the user
         */
        setTimeout(() => {
          const deliverySpeedElement =
            document.querySelector('.delivery-speed');
          const contentCardContainer = document.querySelector(
            '.content-card-container',
          );
          if (deliverySpeedElement && contentCardContainer) {
            scrollToElement(contentCardContainer, deliverySpeedElement);
          }
        }, 400);
      } else {
        setTimeout(() => {
          setRunCoachmark(true);
        }, 300);
      }
    }
  }, [
    open,
    setOpen,
    isFetchingMe,
    servicesData?.length,
    deliverySpeed.serviceId,
    me?.data.onboarding.domesticLlg.showable,
  ]);

  return {
    runCoachmark,
    setRunCoachmark,
  };
}
