import { calculateRoutingChannelsDiscount } from '@elements/choose-service/helper';
import { RoutingChannelModel } from '@topremit/shared-web/api-hooks/common';
import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import {
  Divider,
  ListMenu,
  Status,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { Fragment } from 'react';

interface IChooseServiceRoutingChannel {
  sendAmount: number;
  amountReceive: number;
  selectedRoutingChannel: number;
  shouldCheckVolume: boolean;
  routingChannels: RoutingChannelModel[];
  onSelect(value: number): void;
}

/**
 * @deprecated use <ChooseService /> instead
 */
export default function ChooseServiceRoutingChannel({
  routingChannels,
  amountReceive,
  selectedRoutingChannel,
  shouldCheckVolume,
  sendAmount,
  onSelect,
}: IChooseServiceRoutingChannel) {
  const { t } = useTranslation('home');
  const { routingChannelDiscounts, isAllChannelFree, isDiscountFeeEqual } =
    calculateRoutingChannelsDiscount(routingChannels, sendAmount);

  return (
    <>
      {routingChannels.map(
        (
          { label, type, maxVolume, description, volumeExceededDescription },
          idx,
        ) => {
          const isLast = idx === routingChannels.length - 1;
          const isDisabled = maxVolume < amountReceive && shouldCheckVolume;
          const isSelected = selectedRoutingChannel === idx;
          const discount = routingChannelDiscounts[type]?.discount || 0;
          const canShowPill =
            Boolean(discount) && !isAllChannelFree && !isDiscountFeeEqual;

          return (
            <Fragment key={type}>
              <ListMenu
                titleClassName="bold-text"
                className="list-menu-no-bg"
                descriptionClassName="sm-text"
                type="radio"
                title={label}
                isDisabled={isDisabled}
                isSelected={isSelected}
                description={
                  isDisabled ? volumeExceededDescription : description
                }
                pill={
                  canShowPill && (
                    <Status
                      color="red"
                      size="small"
                      message={t('discount_off', {
                        discount: formatToIDRCurrency(discount),
                      })}
                    />
                  )
                }
                onClick={() => onSelect(idx)}
              />
              {!isLast && <Divider variant="dashed" my={0} />}
            </Fragment>
          );
        },
      )}
    </>
  );
}
