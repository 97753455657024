import { useGetCorridorAnnouncement } from '@api-hooks/transaction';
import corridorHolidayImage from '@public/images/png/holiday-corridor.png';
import corridorMaintenanceImage from '@public/images/png/maintenance-corridor.png';
import { NotesStatus } from '@topremit/shared-web';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';

interface UseCorridorNoteProps {
  destinationCountry: string;
  destinationCurrency: string;
  routingChannel: RoutingChannelType | undefined;
  serviceId: number | undefined;
}

export default function useCorridorNote({
  destinationCountry,
  destinationCurrency,
  routingChannel,
  serviceId,
}: UseCorridorNoteProps) {
  const corridorNote = useGetCorridorAnnouncement(
    {
      destinationCountry,
      destinationCurrency,
      routingChannel: routingChannel as RoutingChannelType,
      serviceId: serviceId as number,
    },
    {
      enabled:
        !!destinationCountry &&
        !!destinationCurrency &&
        !!routingChannel &&
        !!serviceId,
    },
  );

  const corridorNoteData = corridorNote?.data?.data;
  const isHolidayNote = !!corridorNoteData?.holiday;
  const isMaintenanceNote = !!corridorNoteData?.maintenance;

  const hasCorridorNote = isHolidayNote || isMaintenanceNote;

  const corridorStatus = (isHolidayNote ? 'info' : 'warning') as NotesStatus;
  const corridorIcon = isHolidayNote
    ? corridorHolidayImage
    : corridorMaintenanceImage;

  const titleNote = isHolidayNote
    ? corridorNoteData.holiday.title
    : corridorNoteData?.maintenance?.title;
  const descNote = isHolidayNote
    ? corridorNoteData.holiday.description
    : corridorNoteData?.maintenance?.description;

  return {
    descNote,
    titleNote,
    corridorIcon,
    corridorStatus,
    hasCorridorNote,
    corridorNoteQuery: corridorNote,
  };
}
