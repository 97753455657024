import { RoutingChannel } from '@api-hooks/transaction';

import BankSpeedPills from './BankSpeedPills';

export default function BankSpeedDescription({
  routingChannels,
}: {
  routingChannels: RoutingChannel[];
}) {
  return (
    <div className="pills-wrapper">
      {routingChannels.map((routingChannel) => (
        <BankSpeedPills routingChannel={routingChannel} />
      ))}
    </div>
  );
}
