import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';

export const calculatorRemit = {
  root: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 21rem !important;
  `,
  modalDetailList: css`
    ul {
      padding-left: 1.5625rem;
      margin-top: 0.5rem;
      li {
        color: var(--text-secondary);
        > b {
          color: var(--text-primary);
        }
      }
      & :last-child {
        margin-top: 1.25rem;
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-top: 1.5rem;
      .img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 2.5rem;
      }
      > span {
        font-size: 0.875rem;
        line-height: 19.6px;
        width: 21rem;
        padding-left: 0.5rem;
      }
    }
  `,
  confirmationDialogAdditionalInfo: css`
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
  `,
  confirmationDialogAdditionalInfoIcon: css`
    display: flex;
    margin-right: 0.5rem;
  `,
  confirmationDialogActions: css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    button:nth-child(2) {
      margin-top: 0.5rem;
    }
  `,
  confirmationDialogActionButton: css`
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  `,
  customerSupportButton: css`
    cursor: pointer;
    font-weight: var(--bold-font-weight);
  `,
  modalListTitle: css`
    > b {
      color: var(--text-primary) !important;
    }
  `,
  modal: css`
    .modal-header {
      h1 {
        line-height: var(--regular-line-height);
        margin-bottom: 0;
      }
    }
    .modal-body {
      flex-direction: column;
    }
  `,
  quotationInfoSkeleton: css`
    gap: unset;
    > div:nth-child(1) {
      .ant-skeleton-input {
        margin-top: 1rem;
        min-height: 2rem;
      }
    }
    > div:nth-child(2) {
      .ant-skeleton-input {
        margin-top: 1.5rem;
        min-height: 3rem;
      }
    }
    > div:nth-child(3) {
      .ant-skeleton-input {
        margin-top: 1.5rem;
        min-height: 4.5rem;
      }
    }
    > div:last-child {
      margin-bottom: 2rem;
    }
  `,
};

export const calculatorAmountField = {
  currencyWrapper: (isError: boolean) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border: ${isError
      ? '0.125rem solid var(--red-500)'
      : '0.0625rem solid var(--neutral-200)'} !important;
    border-radius: 0.75rem;
    padding: 0.125rem;
    overflow: hidden;
    .flag-field-group {
      margin: unset;
    }
    .flag-field-group-wrapper {
      > div {
        border: unset;
      }
    }
    .dashed-divider {
      display: flex;
      width: 95%;
      overflow: hidden;
    }
  `,
  rightAlign: css`
    text-align: right !important;
    margin: 0 0 auto !important;
    .numeric-format-base {
      text-align: right;
      font-size: 1rem;
    }
    .input-wrapper {
      .form-label {
        left: unset;
        right: 20px;
        width: 100% !important;
      }
    }
  `,
  currencyFieldFlag: css`
    padding-left: 20px;
  `,
  currencyFieldLanding: css`
    z-index: 99;
    .ant-select-item-group {
      font-size: 0.75rem !important;
    }
  `,
  countryAndCurrency: (width: number) => css`
    width: ${width / 16}rem!important;
  `,
  selectCountry: (hasValue: boolean, isDashboard: boolean) => css`
    position: absolute;
    z-index: 100;
    width: 45%;
    > div {
      overflow: visible;
    }

    button {
      padding-right: 0px;
      span {
        svg {
          z-index: 999;
          left: ${hasValue ? '80px' : ''};
          right: ${hasValue ? '' : isDashboard ? '10%' : '-20px'};
          @media (max-width: ${screenSize.mobileLg}px) {
            right: ${hasValue ? '' : '-20px'};
          }
        }
      }
    }
  `,
  sourceAmountWrapper: (isDisabled?: boolean) => css`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    background-color: ${isDisabled ? 'var(--neutral-100)' : 'var(--neutral-0)'};
  `,
  receiveAmountWrapper: css`
    width: 100%;
    position: relative;
    display: flex;
  `,
  receiveAmountInputWrapper: css`
    display: flex;
    justify-content: end;
    margin-left: auto;
    width: 100%;
  `,
  receiveAmount: css`
    width: 55%;
  `,
  selectWrapper: css`
    position: absolute;
    top: 0;
    background: none;
    border: none;
    min-height: 3rem !important;
    max-height: 3rem !important;

    .form-control {
      min-width: 8rem;
      max-width: unset;
      padding-left: 0rem !important;
      padding-right: 0.5rem !important;
      label {
        width: unset !important;
      }
    }

    .form-label {
      left: 1.25rem;
      right: unset;
    }
  `,
  hideSelectWrapperLabel: css`
    .form-control {
      padding-top: 0.3125rem !important;
    }
    .form-label {
      display: none;
    }
  `,
  customizeEmpty: css`
    padding: 1.5rem 1.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: default;
    > .bold-text {
      color: var(--text-primary);
    }
  `,
  nonClickableOption: css`
    cursor: default;
    > .notes {
      > .ant-alert-content {
        display: flex;
        text-align: left;
        white-space: normal;
      }
    }
    @media (max-width: ${screenSize.tabletMd}px) {
      padding: 0;
      > .notes {
        margin-top: 1rem;
      }
    }
  `,
};
