import { HardLimitType, SoftLimitType } from '@global-api-hooks/transaction';
import { useDialogStore } from '@global-stores';
import {
  HardLimitFrequencyModalBody,
  HardLimitVolumeModalBody,
  SoftLimitFrequencyModalBody,
  SoftLimitVolumeModalBody,
} from '@modules/landing/jumbotron';

export default function useSoftHardLimit() {
  const showDialog = useDialogStore((s) => s.show);
  function handleSoftLimit(limitType: SoftLimitType, onOk?: () => void) {
    switch (limitType) {
      case SoftLimitType.STATUS_EXCEED_PERIODIC_COUNT_LIMIT:
        showDialog({
          body: <SoftLimitFrequencyModalBody onOk={onOk} />,
        });
        return;
      case SoftLimitType.STATUS_EXCEED_ACCU_LIMIT:
        showDialog({
          body: <SoftLimitVolumeModalBody onOk={onOk} />,
        });
    }
  }

  function handleHardLimit(type: HardLimitType) {
    switch (type) {
      case HardLimitType.FREQUENCY:
        showDialog({
          body: <HardLimitFrequencyModalBody />,
        });
        return;
      case HardLimitType.VOLUME:
        showDialog({
          body: <HardLimitVolumeModalBody />,
        });
    }
  }

  return {
    handleSoftLimit,
    handleHardLimit,
  };
}
