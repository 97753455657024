import { IQuotationRecipientBatches } from '@api-hooks/domestic';
import { EditRecipientDetailsParams } from '@api-hooks/recipient';
import { CreateBulkQuotationParams } from '@api-hooks/transaction';
import { useAnalytics } from '@hooks';
import { COUNTRY_IDN, CURRENCY_IDR, Segment } from '@modules';
import {
  TransactionRecipientType,
  TransactionType,
} from '@topremit/shared-web/api-hooks/transaction';
import { useTranslation } from '@topremit/shared-web/hooks';
import { FailedAddToCartError } from '@topremit/shared-web/typings/transaction.model';

import useDomesticAmount from './use-domestic-amount';
import useDomesticDeliverySpeed from './use-domestic-delivery-speed';
import useDomesticError from './use-domestic-error';
import { useDomesticFlowStore } from './use-domestic-flow-store';
import useDomesticNote from './use-domestic-note';
import useDomesticPurpose from './use-domestic-purpose';
import useDomesticQuotation from './use-domestic-quotation';
import useDomesticRecipient from './use-domestic-recipient';
import useDomesticRecipientIdAndPayerId from './use-domestic-recipient-id-and-payer-id';
import useDomesticServices from './use-domestic-services';
import useDomesticStepper from './use-domestic-stepper';
import useDomesticType from './use-domestic-type';

export default function useDomesticFlow() {
  const { t } = useTranslation();
  const { trackerPersonalBusiness } = useAnalytics();

  const resetStore = useDomesticFlowStore((s) => s.resetStore);

  const stepper = useDomesticStepper();

  const {
    errorRecipientBatchesIds,
    errorRecipientMessage,
    errorServicesMessage,
    hardLimitMessage,
    setError,
  } = useDomesticError();

  const { type, isBulk, handleTypeChange } = useDomesticType();

  const services = useDomesticServices();

  const { payerIdByDeliverySpeed, recipientIdByDeliverySpeed } =
    useDomesticRecipientIdAndPayerId();

  const {
    quotation,
    isLoadingQuotation,
    bulkQuotationMutation,
    quotationRecipientMutation,
  } = useDomesticQuotation();

  const { note, handleNoteChange } = useDomesticNote();

  const {
    amount,
    maxLimitAmount,
    minLimitAmount,
    isExceededAmount,
    handleAmountChange,
  } = useDomesticAmount();

  const { deliverySpeed, handleDeliverySpeedChange } =
    useDomesticDeliverySpeed();

  const {
    recipient,
    bulkRecipients,
    hasChooseRecipient,
    validatedRecipientBatches,
    handleRecipientChange,
    handleBulkRecipientsChange,
    handleRecipientAmountChange,
    handleValidatedRecipientBatchesChange,
  } = useDomesticRecipient();

  const { purpose, handlePurposeChange } = useDomesticPurpose();

  function handleClickContinueCalculator() {
    const errors: string[] = [];
    if (!hasChooseRecipient) {
      setError({
        type: 'recipient',
        error: t('transaction:domestic.error.choose_recipient_first'),
      });
      errors.push('recipient');
    }

    if (!deliverySpeed.serviceId) {
      setError({
        type: 'services',
        error: t('transaction:domestic.error.select_delivery_speed'),
      });
      errors.push('service');
    }

    if (errors.length > 0) {
      trackerPersonalBusiness?.failedAddToCart({
        ...(!isBulk &&
          quotation?.referenceId && {
            ref_id: quotation.referenceId,
          }),
        error: errors.join(' ') as FailedAddToCartError,
        item_type: TransactionType.DOMESTIC,
        type: isBulk
          ? TransactionRecipientType.MULTIPLE
          : TransactionRecipientType.SINGLE,
      });
    }

    if (hasChooseRecipient && amount && deliverySpeed.serviceId) {
      if (isBulk) {
        const recipientBatches = bulkRecipients.map((recipient, idx) => {
          // get the amount from validated data
          // cause from amount input can be not valid if user just type the amount without validate it first
          const amount =
            validatedRecipientBatches?.recipientBatches[idx]?.amount;
          const fullName = recipient?.beneficiary?.fullName || recipient?.name;

          return {
            id: recipient?.id,
            amount,
            payerId: recipient?.payerId,
            creditParty: {
              accountNumber: recipient?.accountNumber,
            },
            beneficiary: {
              purposeCode: purpose.id,
              segment: Segment.PERSONAL,
              fullName,
            },
            fundSource: validatedRecipientBatches?.fundSource,
            sender: {
              fundSource: validatedRecipientBatches?.fundSource,
            },
          };
        });
        const bulkPayload = {
          direction: 'DOMESTIC',
          destinationCountry: COUNTRY_IDN,
          destinationCurrency: CURRENCY_IDR,
          isSendAmount: true,
          purposeCode: purpose.id,
          routingChannel: deliverySpeed.routingChannel,
          serviceId: deliverySpeed.serviceId,
          totalAmount: amount,
          recipientBatches:
            recipientBatches as unknown as IQuotationRecipientBatches[],
        } as CreateBulkQuotationParams;

        bulkQuotationMutation.mutate(bulkPayload);
      } else {
        if (!quotation?.referenceId) {
          return;
        }
        const singlePayload = {
          description: note,
          beneficiary: {
            ...recipient?.beneficiary,
            purposeCode: purpose?.id,
          },
          id: recipientIdByDeliverySpeed,
          payerId: payerIdByDeliverySpeed,
          creditParty: recipient?.creditParty,
          serviceId: deliverySpeed.serviceId,
          accountNumber: recipient?.accountNumber,
          direction: TransactionType.DOMESTIC,
        } as EditRecipientDetailsParams;

        quotationRecipientMutation.mutate(singlePayload);
      }
    }
  }

  return {
    stepper,
    errors: {
      hardLimitMessage,
      errorServicesMessage,
      errorRecipientMessage,
      errorRecipientBatchesIds,
      setError,
    },
    data: {
      services,
    },
    values: {
      type,
      note,
      amount,
      isBulk,
      purpose,
      recipient,
      quotation,
      deliverySpeed,
      bulkRecipients,
      minLimitAmount,
      maxLimitAmount,
      /**
       * Bulk will always return false
       * Single will return true if amount exceedes the limit
       */
      isExceededAmount,
      isLoadingQuotation,
      validatedRecipientBatches,
      /**
       *
       * - For `SINGLE`:
       *   The hasChooseRecipient is directly derived from recipient.
       * - For `BULK`:
       *   The hasChooseRecipient is from checking the bulkRecipients length.
       */
      hasChooseRecipient,
      /**
       *  payerId based on selected delivery speed and services
       *
       * - If there is only one service available (`servicesData.length === 1`),
       *   payerId will get the id directly from recipient
       * - If there are multiple services (`servicesData.length > 1`),
       *   payerId will get the id from the routingChannel's payerId based on selected speed `INSTANT` | `REGULAR`
       *
       * this scenario happen because the payerId will be different based on the routingChannels
       */
      payerIdByDeliverySpeed,
      /**
       *  recipientId based on selected delivery speed and services
       *
       * - If there is only one service available (`servicesData.length === 1`),
       *   recipientId will get the id directly from recipient
       * - If there are multiple services (`servicesData.length > 1`),
       *   recipientId will get the id from the routingChannel's recipientId based on selected speed `INSTANT` | `REGULAR`
       *
       * this scenario happen because the recipientId will be different based on the routingChannels
       */
      recipientIdByDeliverySpeed,
    },
    handlers: {
      handleNoteChange,
      handleTypeChange,
      handleAmountChange,
      handlePurposeChange,
      handleRecipientChange,
      handleDeliverySpeedChange,
      handleBulkRecipientsChange,
      handleRecipientAmountChange,
      handleClickContinueCalculator,
      handleValidatedRecipientBatchesChange,
      resetDomesticValue: resetStore,
    },
  };
}
