import { css, cx } from '@emotion/css';
import { color } from '@global-styles/color';
import { RoutingChannelDiscount } from '@topremit/shared-web/api-hooks/common';
import { isEmptyObject } from '@topremit/shared-web/common/helper';
import {
  Card,
  ErrorMessage,
  ListMenu,
  Divider,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { LazyMotion, domAnimation, m, motion } from 'framer-motion';
import dynamic from 'next/dynamic';

import { ServiceDetailModel } from './CalculatorRemit';

const ServiceCard = dynamic(() =>
  import('@topremit/shared-web/components/elements').then((m) => m.ServiceCard),
);

const MotionCard = motion(Card);

interface IChooseServiceCard {
  isError?: boolean;
  className?: string;
  amountSend: number;
  isDisabled?: boolean;
  isVibrateError?: boolean;
  hasChooseService: boolean;
  selectedService?: ServiceDetailModel;
  onClick: () => void;
}

const variants = {
  initial: {
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  vibrate: {
    x: [-10, 10, -10, 10, -8, 8, 0],
    transition: {
      duration: 0.5,
    },
  },
};

function getDiscountValue(
  value: RoutingChannelDiscount['value'],
  type: RoutingChannelDiscount['discountType'],
  fee?: number,
) {
  if (type === 'DISCOUNT_PERCENTAGE_FEE') {
    return ((fee || 0) * Number(value)) / 100;
  }
  return value;
}

function isServiceFeeFree(fee?: number, discount?: number) {
  return Number(fee) - Number(discount) === 0;
}

/**
 * @deprecated use <ChooseService /> instead
 */
export default function ChooseServiceCard({
  isError,
  className,
  isDisabled,
  amountSend,
  selectedService,
  isVibrateError,
  hasChooseService,
  onClick,
}: IChooseServiceCard) {
  const { t } = useTranslation('home');
  const { description, fee, note, discount } =
    selectedService?.routingChannel || {};

  const isMinimumAmountExceeded = amountSend >= Number(discount?.minimumAmount);
  const discountedValue =
    isMinimumAmountExceeded && discount
      ? getDiscountValue(discount.value, discount.discountType, fee)
      : undefined;

  const withHighlight = selectedService?.routingChannel.type === 'INSTANT';
  const canShowServiceCard =
    !isEmptyObject(selectedService) && hasChooseService;

  const _className = cx(styled.root, className, 'card card-secondary', {
    [styled.disabled]: isDisabled,
  });
  const isNewUser =
    selectedService?.routingChannel.discount?.userType === 'NEW';

  const isFirstTransactionFree =
    isNewUser && isServiceFeeFree(fee, discountedValue);

  return (
    <>
      <LazyMotion features={domAnimation}>
        <MotionCard
          initial="initial"
          animate={isVibrateError ? 'vibrate' : 'initial'}
          variants={variants}
          className={_className}
          column
          p={0}
          variant="outline"
          borderColor={isError ? color.red500 : color.neutral200}
        >
          <ListMenu
            type="arrow"
            title={t('select_service_and_delivery_speed')}
            titleClassName="bold-text"
            onClick={onClick}
          />

          {canShowServiceCard && (
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.5 }}
              className="content"
            >
              <Divider variant="dashed" my={0} />
              <ServiceCard
                note={note}
                fee={fee || 0}
                className="service-card"
                description={description}
                title={selectedService?.name}
                withHighlight={withHighlight}
                discountValue={discountedValue}
                onClick={onClick}
                isFirstTransactionFree={isFirstTransactionFree}
              />
            </m.div>
          )}
        </MotionCard>
      </LazyMotion>
      {isError && (
        <ErrorMessage className={styled.errorMessage}>
          {t('select_service_speed_first')}
        </ErrorMessage>
      )}
    </>
  );
}

const styled = {
  root: css`
    > .content {
      margin: 0 1rem 0.75rem;
    }
    .service-card {
      margin-top: 0.75rem;
    }
  `,
  disabled: css`
    cursor: default;
    pointer-events: none;
    overflow: hidden;
    background: var(--neutral-100) !important;
    > * {
      background: var(--neutral-100) !important;
      opacity: 0.6 !important;
    }
  `,
  errorMessage: css`
    margin-top: 0.25rem;
    margin-bottom: 1rem;
  `,
};
