import { Button } from '@global-elements';
import { IButtonProps } from '@topremit-ui/button';
import { MutableRefObject } from 'react';
import { createPortal } from 'react-dom';

interface ContinueButtonProps extends IButtonProps {
  /**
   * A mutable ref object pointing to the target DOM node where the button should be rendered.
   */
  targetRef: MutableRefObject<null>;
}

/**
 * A button component rendered into a specified DOM node using React Portals.
 *
 * This component extends the properties of the `Button` component
 * and renders it into the DOM node referenced by `targetRef`.
 *
 */
export default function ButtonWithPortal({
  targetRef,
  ...resProps
}: ContinueButtonProps) {
  return (
    targetRef?.current &&
    createPortal(<Button {...resProps} />, targetRef.current)
  );
}
