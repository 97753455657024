import { ISelectedRecipient } from '@api-hooks/domestic';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useShallow } from 'zustand/react/shallow';

import { MAX_SELECTED_MULTIPLE_RECIPIENT } from '../constants';
import useDomesticError from './use-domestic-error';
import {
  IRecipientDocumentStore,
  useDomesticFlowStore,
} from './use-domestic-flow-store';
import useDomesticType from './use-domestic-type';

export default function useDomesticRecipient() {
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { setError } = useDomesticError();

  const { setField, recipient, bulkRecipients, validatedRecipientBatches } =
    useDomesticFlowStore(useShallow((s) => s));

  const { isBulk } = useDomesticType();

  const hasChooseRecipient = isBulk ? bulkRecipients.length > 0 : !!recipient;

  function handleRecipientChange(
    recipient: IRecipientDocumentStore['recipient'],
  ) {
    setField('recipient', recipient);
    setError({ type: 'recipient', error: '' });
  }

  function handleBulkRecipientsChange(
    recipient: IRecipientDocumentStore['recipient'] | undefined,
    type: 'increment' | 'decrement',
  ) {
    // If the recipient is `undefined`, it clears the `bulkRecipients` list.
    if (typeof recipient === 'undefined') {
      setField('bulkRecipients', []);
      return;
    }

    // If type is 'decrement', it removes the recipient from the list one by one.
    if (type === 'decrement') {
      const id = recipient?.id;
      const index = bulkRecipients.map((r) => r?.id).lastIndexOf(id);
      if (index !== -1) {
        const newRecipients = [...bulkRecipients];
        newRecipients.splice(index, 1);
        setField('bulkRecipients', newRecipients);
        return;
      }
    }

    // If the new list exceeds the maximum allowed recipients, it shows a notification.
    if (bulkRecipients.length === MAX_SELECTED_MULTIPLE_RECIPIENT) {
      addNotification({
        type: 'danger',
        message: t('choose_max', {
          max: MAX_SELECTED_MULTIPLE_RECIPIENT,
        }),
      });
      return;
    }

    // If type is 'increment', it adds the recipient to the list.
    setField('bulkRecipients', [...bulkRecipients, recipient]);
  }

  function handleRecipientAmountChange(amount: number, index: number) {
    setField('bulkRecipients', (prev: ISelectedRecipient[]) =>
      prev.map((recipient, idx) =>
        idx === index ? { ...recipient, amount } : recipient,
      ),
    );
  }

  function handleValidatedRecipientBatchesChange(
    validatedData: IRecipientDocumentStore['validatedRecipientBatches'],
  ) {
    setField('validatedRecipientBatches', validatedData);
  }

  return {
    recipient,
    bulkRecipients,
    hasChooseRecipient,
    validatedRecipientBatches,
    handleRecipientChange,
    handleBulkRecipientsChange,
    handleRecipientAmountChange,
    handleValidatedRecipientBatchesChange,
  };
}
