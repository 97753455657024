import { useGetLookupField, useGetSwiftPayer } from '@api-hooks/recipient';
import { useGetQuotationForm } from '@api-hooks/transaction';
import { SYNC_FORM_KEY } from '@common/storage';
import { css } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import SelectDestinationBankModalBody from '@modules/recipient/SelectDestinationBankModalBody';
import bolyNotFoundImage from '@public/images/svg/boly-not-found.svg';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  PayersModel,
  RoutingChannelType,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  isEmptyObject,
  isStringEmpty,
} from '@topremit/shared-web/common/helper';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Button,
  ContainerSpinner,
  Flex,
} from '@topremit/shared-web/components/elements';
import {
  useDebounce,
  useGlobalState,
  useNotification,
  useShallowEffect,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import Image from 'next/image';
import { memo, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';

import ChooseRecipient from './ChooseRecipient';
import {
  AdditionalDetails,
  QuitConfirmationDialog,
  RecipientDetails,
  RecipientType,
} from './add-recipient';
import { SwitchSpeedModal } from './conflict-modal';
import { EditRecipientDetails } from './edit-recipient';
import { RecipientFormDetails } from './types';

interface IRecipientModalProps {
  countryAndCurrency?: string;
  selectedRoutingChannel: RoutingChannelType;
}

const { tabletMd } = screenSize;

const PREV_STEP = 'PREV_STEP';
const PAYER_ROUTING_CHANNEL = 'PAYER_ROUTING_CHANNEL';
const RECIPIENT_FORM_VALUES = 'RECIPIENT_FORM_VALUES';
const PAYER_ROUTING_CHANNEL_TYPE = 'PAYER_ROUTING_CHANNEL_TYPE';

function RecipientModal({
  countryAndCurrency,
  selectedRoutingChannel,
}: IRecipientModalProps) {
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  const showDialog = useDialogStore((store) => store.show);
  const pushDialog = useDialogStore((store) => store.push);
  const popDialog = useDialogStore((store) => store.pop);
  const closeDialog = useDialogStore((store) => store.close);

  const [globalState, dispatchGlobalState] = useGlobalState();
  const {
    step,
    payerId,
    quotation,
    setStep,
    setPayerId,
    setRecipient,
    setHasPayerSpeed,
    setIsDeleteRecipient,
    selectedRecipientId,
    showMaintenancesNotes,
    setSelectedRecipientId,
    setIsValidatingBankCode,
    setShowMaintenancesNotes,
    setDeleteSelectedRecipientIdxs,
  } = useRecipient();

  const quotationFormState = globalState.get('quotation-form');
  const routingChannel = localStorage.getItem(
    PAYER_ROUTING_CHANNEL_TYPE,
  ) as RoutingChannelType;
  const recipientFormValues: RecipientFormDetails = globalState.get(
    RECIPIENT_FORM_VALUES,
  );
  const syncFormValues = globalState.get(SYNC_FORM_KEY);

  const {
    data: quotationForm,
    refetch: refetchQuotationForm,
    isFetching: isFetchingQuotationForm,
  } = useGetQuotationForm(quotation?.referenceId);

  const { segment, recipientForm, additionalForm } =
    quotationForm?.data.form || {};

  const strSegment = JSON.stringify(segment);

  const disabledSegments = useMemo(() => {
    if (isEmptyObject(segment)) {
      return ['0', '1'];
    }
    return segment?.sections?.[0].fields?.[0].options
      ?.map((option, idx) => (option.disabled ? String(idx) : null))
      .filter((el) => el) as string[];
  }, [strSegment]);

  const methods = useForm<RecipientFormDetails>({
    defaultValues: {
      ...recipientFormValues,
      ...syncFormValues,
      segment: recipientFormValues?.segment || 'PERSONAL',
      sync: '',
    },
    mode: 'onChange',
  });

  const { watch, setValue, setError, reset, clearErrors } = methods;

  const {
    serviceId,
    beneficiary,
    creditParty,
    destinationCountry,
    destinationCurrency,
  } = watch();

  const strQuotationForm = JSON.stringify(quotationForm);
  const strFormRecipientValues = JSON.stringify(watch());

  const { data: payers, refetch: refetchPayers } = useQuery<
    ApiResult<PayersModel[]>,
    ApiError
  >({
    queryKey: [
      'payers',
      'payers',
      {
        url: 'payers',
        service_id: serviceId,
        destination_country: destinationCountry,
        destination_currency: destinationCurrency,
      },
    ],
    retry: false,
    enabled: false,
    onSuccess: ({ data }) => {
      if (data.length === 1 && step === ChooseRecipientStep.ADD) {
        localStorage.setItem(
          PAYER_ROUTING_CHANNEL,
          JSON.stringify(data[0].routingChannels),
        );
      }
    },
  });

  const { refetch: refetchSwiftPayer } = useGetSwiftPayer(
    {
      serviceId,
      destinationCountry,
      destinationCurrency,
      queryKey: [
        'swift-payer',
        'swift-payer',
        {
          service_id: serviceId,
          destination_country: destinationCountry,
          destination_currency: destinationCurrency,
        },
      ],
    },
    {
      onSuccess: ({ data: swiftPayer }) => {
        setValue('payerId', swiftPayer.id);
        if (step === ChooseRecipientStep.ADD) {
          localStorage.setItem(
            PAYER_ROUTING_CHANNEL,
            JSON.stringify(swiftPayer.routingChannels),
          );
        }
      },
    },
  );

  const {
    refetch: refetchBankBranchCodeValidation,
    isLoading: isLoadingBankBranchCodeValidation,
  } = useGetLookupField(
    {
      serviceId,
      destinationCountry,
      destinationCurrency,
      refId: quotation?.referenceId,
      bankBranchCode: creditParty?.bankBranchCode,
      routingChannel: routingChannel || quotation?.routingChannel,
    },
    {
      onSuccess: ({ data: payer }) => {
        setValue('payerId', payer.id);
        localStorage.setItem(
          PAYER_ROUTING_CHANNEL,
          JSON.stringify(payer.routingChannels),
        );
        localStorage.removeItem(PAYER_ROUTING_CHANNEL_TYPE);
        setIsValidatingBankCode(false);
        clearErrors('creditParty.bankBranchCode');
      },
      onError(err) {
        setError('creditParty.bankBranchCode', {
          message: err.errors?.bankBranchCode,
        });
        addNotification({
          type: 'danger',
          message: err.message,
        });
        if (err?.openSwitchPayer) {
          const suggestedRoutingChannel =
            quotation.routingChannel === RoutingChannelType.INSTANT
              ? RoutingChannelType.REGULAR
              : RoutingChannelType.INSTANT;
          setTimeout(() => {
            handleChangeDeliverySpeedModal(suggestedRoutingChannel);
          }, 1000);
        }
      },
    },
  );

  const bankBranchCodeField = getDynamicField(
    'BANK_DETAILS',
    'credit_party.bank_branch_code',
  );

  const payerField = getDynamicField('BANK_DETAILS', 'payer_id');

  const isBankBranchCodeLengthValid =
    bankBranchCodeField &&
    creditParty?.bankBranchCode?.length! >= bankBranchCodeField.hitLength!;

  const canValidateBankBranchCode =
    isBankBranchCodeLengthValid &&
    !isLoadingBankBranchCodeValidation &&
    bankBranchCodeField.type === 'lookup';

  const isPayerRequestable = useMemo(() => {
    if (isEmptyObject(quotationForm?.data)) {
      return false;
    }

    const bankDetailsSection = recipientForm?.sections.find(
      ({ section }) => section === 'BANK_DETAILS',
    );

    if (bankDetailsSection) {
      const payerId = bankDetailsSection.fields.find(
        ({ name }) => name === 'payer_id',
      );
      return payerId?.isRequestable;
    }
  }, [strQuotationForm]);

  const hasNotFilledForm =
    isStringEmpty(payerId) &&
    isEmptyObject(creditParty) &&
    isStringEmpty(beneficiary?.email) &&
    isStringEmpty(beneficiary?.fullname) &&
    isStringEmpty(beneficiary?.relationship);

  function renderContent() {
    switch (step) {
      case ChooseRecipientStep.CHOOSE:
        return (
          <ChooseRecipient
            disabledSegments={disabledSegments}
            countryAndCurrency={countryAndCurrency}
            selectedRoutingChannel={selectedRoutingChannel}
          />
        );
      case ChooseRecipientStep.TYPE:
        return (
          <RecipientType
            section={segment?.sections[0]}
            isFetching={isFetchingQuotationForm}
          />
        );
      case ChooseRecipientStep.ADD:
        return (
          <RecipientDetails
            form={recipientForm}
            isFetching={isFetchingQuotationForm}
          />
        );
      case ChooseRecipientStep.EDIT:
        return (
          <EditRecipientDetails
            key={payerId}
            payers={payers?.data}
            form={recipientForm}
            isFetching={isFetchingQuotationForm}
          />
        );
      case ChooseRecipientStep.PAYERS:
        return (
          <SelectDestinationBankModalBody
            payers={payers?.data}
            isRequestable={isPayerRequestable}
            routingChannel={quotation.routingChannel}
            selectedPayerIdState={[payerId, setPayerId]}
            showMaintenancesNotesState={[
              showMaintenancesNotes,
              setShowMaintenancesNotes,
            ]}
            searchPlaceholder={payerField?.searchPlaceholder}
            emptyStateImage={
              <Image
                src={bolyNotFoundImage}
                width={136}
                height={136}
                alt="boly not found"
              />
            }
          />
        );
      case ChooseRecipientStep.ADDITIONAL:
        return (
          <AdditionalDetails
            sections={additionalForm?.sections}
            isFetching={isFetchingQuotationForm}
          />
        );
      default:
        <ContainerSpinner />;
    }
  }

  function onClickBtnBack() {
    switch (step) {
      case ChooseRecipientStep.CHOOSE:
        closeDialog();
        return;
      case ChooseRecipientStep.TYPE:
        setStep(ChooseRecipientStep.CHOOSE);
        reset();
        return;
      case ChooseRecipientStep.ADD:
        setStep(ChooseRecipientStep.TYPE);
        setRecipient(null);
        reset();
        return;
      case ChooseRecipientStep.EDIT:
        setStep(ChooseRecipientStep.CHOOSE);
        setSelectedRecipientId(0);
        setRecipient(null);
        setPayerId(null);
        dispatchGlobalState({
          type: 'REMOVE',
          key: RECIPIENT_FORM_VALUES,
        });
        return;
      case ChooseRecipientStep.PAYERS:
      case ChooseRecipientStep.ADDITIONAL:
        // workaround : handle usePrev changes when click select bank
        // eslint-disable-next-line no-case-declarations
        const prevStep = localStorage.getItem(PREV_STEP);
        if (prevStep) {
          setStep(prevStep as ChooseRecipientStep);
        }
    }
  }

  function onCancelChooseRecipient() {
    if (
      step === ChooseRecipientStep.CHOOSE ||
      step === ChooseRecipientStep.TYPE ||
      (step === ChooseRecipientStep.ADD && hasNotFilledForm)
    ) {
      setStep(ChooseRecipientStep.CHOOSE);
      localStorage.removeItem(PAYER_ROUTING_CHANNEL_TYPE);
      closeDialog();
      setRecipient(null);
      dispatchGlobalState({
        type: 'REMOVE',
        key: RECIPIENT_FORM_VALUES,
      });
      handleLeave();
      return;
    }

    pushDialog({
      body: (
        <QuitConfirmationDialog onLeave={handleLeave} onCancel={popDialog} />
      ),
    });
  }

  function handleLeave() {
    closeDialog();
    setSelectedRecipientId(0);
    setStep(ChooseRecipientStep.CHOOSE);
    if (step !== ChooseRecipientStep.CHOOSE) {
      setRecipient(null);
      localStorage.removeItem(PAYER_ROUTING_CHANNEL_TYPE);
      dispatchGlobalState({
        type: 'REMOVE',
        key: RECIPIENT_FORM_VALUES,
      });
    }
  }

  function getDynamicField(sectionName: string, fieldName: string) {
    return quotationForm?.data.form.recipientForm.sections
      ?.find((section) => section.section === sectionName)
      ?.fields.find((field) => field.name === fieldName);
  }

  function handleChangeDeliverySpeedModal(routingChannel: RoutingChannelType) {
    setHasPayerSpeed(true);
    // expected using setTimeout to show the modal 1s after the inline error
    setTimeout(() => {
      pushDialog({
        body: (
          <SwitchSpeedModal
            channel={routingChannel}
            setFieldValue={setValue}
            selectedRoutingChannel={selectedRoutingChannel}
          />
        ),
      });
    }, 1000);
  }

  function onClickBtnClose() {
    setHasPayerSpeed(true);
    onCancelChooseRecipient();
    setIsDeleteRecipient(false);
    setDeleteSelectedRecipientIdxs([]);
    onClearPersistentState();
  }

  function onClearPersistentState() {
    const keys = ['isEmptyRecipients']; //component: ChooseRecipient
    keys.forEach((key) => {
      localStorage.removeItem(key);
    });
  }

  function onSelectBank() {
    if (!payerId) {
      addNotification({
        message: t('select_bank_first'),
        type: 'danger',
      });
      return;
    }
    const prevStep = localStorage.getItem(PREV_STEP);
    if (prevStep) {
      setStep(prevStep as ChooseRecipientStep);
    }
  }

  useDebounce(
    () => {
      dispatchGlobalState({
        type: 'ADD',
        payload: watch(),
        key: SYNC_FORM_KEY,
      });
    },
    [strFormRecipientValues],
    500,
  );

  useDebounce(
    () => {
      const bankBranchCode = watch('creditParty.bankBranchCode');

      if (typeof bankBranchCode === 'undefined') {
        return;
      }

      if (
        bankBranchCode.length < bankBranchCodeField?.hitLength! &&
        bankBranchCodeField?.type === 'lookup'
      ) {
        setError('creditParty.bankBranchCode', {
          message: t('calculator:min_char_field'),
        });
        return;
      }
      if (canValidateBankBranchCode) {
        setIsValidatingBankCode(true);
        clearErrors('creditParty.bankBranchCode');
        refetchBankBranchCodeValidation();
      }
    },
    [creditParty?.bankBranchCode],
    600,
  );

  useDebounce(
    () => {
      dispatchGlobalState({
        type: 'ADD',
        payload: watch(),
        key: RECIPIENT_FORM_VALUES,
      });
    },
    [strFormRecipientValues],
    500,
  );

  useEffect(() => {
    if (!watch('payerId')) {
      setPayerId(watch('payerId'));
    }
  }, [watch('payerId')]);

  useEffect(() => {
    if (payerId) {
      setValue('payerId', payerId);
      if (
        payers?.data.some(
          ({ routingChannels, id }) =>
            id === payerId &&
            routingChannels.some(({ isMaintenance }) => isMaintenance),
        )
      ) {
        setShowMaintenancesNotes(true);
      } else {
        setShowMaintenancesNotes(false);
      }
    }
  }, [payerId]);

  useShallowEffect(() => {
    if (quotation?.referenceId && !quotationForm) {
      refetchQuotationForm();
    }
  }, [quotation?.referenceId, quotationForm]);

  useEffect(() => {
    const isNeededDataEmpty =
      serviceId < 1 ||
      isStringEmpty(destinationCountry) ||
      isStringEmpty(destinationCurrency);

    const canRefetchSwiftPayer =
      payerField?.hidden &&
      payerField.type === 'api' &&
      payerField.url === 'swift-payer';

    const canRefetchPayers =
      !payerField?.hidden &&
      payerField?.type === 'api' &&
      isEmptyObject(payers);

    if (!isNeededDataEmpty) {
      if (canRefetchSwiftPayer) {
        refetchSwiftPayer();
      } else if (canRefetchPayers) {
        refetchPayers();
      }
    }
  }, [step, serviceId, destinationCountry, destinationCurrency, payerField]);

  useEffect(() => {
    const onBack = step !== ChooseRecipientStep.CHOOSE && onClickBtnBack;
    const footer = (() => {
      if (step === ChooseRecipientStep.PAYERS) {
        return (
          <Button fullWidth onClick={onSelectBank}>
            <span>{t('common:continue')}</span>
          </Button>
        );
      }
    })();
    /**
     * Workaround: disable escape key because cannot show confirmation dialog before close
     */
    showDialog((props) => ({
      ...props,
      onBack,
      onClose: onClickBtnClose,
      footer: footer || props.footer,
      disableEscKey: true,
    }));
  }, [step, payerId]);

  useShallowEffect(() => {
    if (!isEmptyObject(quotationFormState)) {
      Object.entries(quotationFormState).forEach(([key, value]) => {
        setValue(key, value as any);
      });
    }
  }, [quotationFormState, step]);

  useEffect(() => {
    if (selectedRecipientId === 0 && step === ChooseRecipientStep.EDIT) {
      setStep(ChooseRecipientStep.CHOOSE);
      dispatchGlobalState({
        type: 'REMOVE',
        key: RECIPIENT_FORM_VALUES,
      });
    }
  }, [step, selectedRecipientId]);

  useEffect(() => {
    const defaultValueSegment =
      disabledSegments.length > 1
        ? 'PERSONAL'
        : disabledSegments[0] === '0'
          ? 'BUSINESS'
          : 'PERSONAL';
    setValue('segment', defaultValueSegment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledSegments.length]);

  return (
    <Flex id="recipient-modal" column className={styled.dialogContent}>
      <FormProvider {...methods}>{renderContent()}</FormProvider>
    </Flex>
  );
}

const styled = {
  dialogContent: css`
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    @media (max-width: ${tabletMd}px) {
      padding-left: 0;
    }
  `,
};

export default memo(RecipientModal);
