import { css, cx } from '@emotion/css';
import {
  Card,
  ErrorMessage,
  ListMenu,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';

interface IChooseRecipientCard {
  title?: string;
  className?: string;
  isError?: boolean;
  isDisabled?: boolean;
  description?: string;
  errorMessage?: string;
  onClick: () => void;
}

export default function ChooseRecipientCard(props: IChooseRecipientCard) {
  const { t } = useTranslation('home');

  const {
    isError,
    className,
    isDisabled,
    errorMessage = t('choose_recipient_first'),
    title = t('choose_recipient'),
    description = t('choose_add_from_recipient'),
    onClick,
  } = props;

  return (
    <>
      <Card
        p={0}
        variant="outline"
        borderColor={isError ? color.red500 : color.neutral200}
        className={cx(styled.root, className)}
      >
        <ListMenu
          type="arrow"
          isDisabled={isDisabled}
          title={title}
          description={description}
          titleClassName="bold-text"
          descriptionClassName="sm-text"
          onClick={onClick}
        />
      </Card>
      {isError && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </>
  );
}

const styled = {
  root: css`
    .wrapper {
      position: relative;
    }
    .title,
    .desc {
      max-width: 90%;
    }
    .title {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .right-element {
      position: absolute;
      right: 0;
    }
  `,
};
