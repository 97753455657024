import iconAlipayImage from '@public/images/svg/icon-alipay.svg';
import iconBuildingImage from '@public/images/svg/icon-building.svg';
import iconCashPickupImage from '@public/images/svg/icon-cash-pickup.svg';
import iconHomeDeliveryImage from '@public/images/svg/icon-home-delivery.svg';
import iconMobileTopupImage from '@public/images/svg/icon-mobile-topup.svg';
import iconSwiftImage from '@public/images/svg/icon-swift.svg';
import iconUnionPayImage from '@public/images/svg/icon-unionpay.svg';
import iconWalletImage from '@public/images/svg/icon-wallet.svg';
import iconWechatPayImage from '@public/images/svg/icon-wechatpay.svg';

export type ServiceIconName =
  | 'at'
  | 'bt'
  | 'cp'
  | 'ew'
  | 'home-delivery'
  | 'swift'
  | 'alipay'
  | 'union-pay'
  | 'wechat-pay';

/**
 * link to figma:
 * https://www.figma.com/file/2G0ziY73RlynI3RyDfWjTH/RTF-2.0-Calculator?type=design&node-id=10-126877&t=8tpIFoo6zFne5Bbt-4
 */
export const SERVICE_ICONS = new Map<ServiceIconName, any>([
  ['at', iconMobileTopupImage],
  ['bt', iconBuildingImage],
  ['cp', iconCashPickupImage],
  ['ew', iconWalletImage],
  ['home-delivery', iconHomeDeliveryImage],
  ['swift', iconSwiftImage],
  ['alipay', iconAlipayImage],
  ['union-pay', iconUnionPayImage],
  ['wechat-pay', iconWechatPayImage],
]);
