import { AmountSummaryCard } from '@elements';
import { css } from '@emotion/css';
import { screenSize } from '@topremit/shared-web/common/size';
import { useDebounce, useTranslation } from '@topremit/shared-web/hooks';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import useDomesticFlow from './hooks/use-domestic-flow';

function SingleAmountSummaryCard() {
  const { t } = useTranslation();
  const {
    control,
    clearErrors,
    setError: setErrorForm,
  } = useFormContext<{ amount: number | undefined }>();

  const amountFormValue = useWatch({ name: 'amount', control });

  const {
    values: { note, amount: amountStoreValue },
    errors: { hardLimitMessage, setError },
    handlers: { handleNoteChange, handleAmountChange },
  } = useDomesticFlow();

  useDebounce(
    () => {
      setError({ type: 'hardLimit', error: '' });
      handleAmountChange(amountFormValue);
    },
    [amountFormValue],
    1500,
  );

  useEffect(() => {
    if (!hardLimitMessage) {
      clearErrors('amount');
      return;
    }
    setErrorForm('amount', { message: hardLimitMessage });
  }, [hardLimitMessage, clearErrors, setErrorForm]);

  return (
    <AmountSummaryCard
      notesValue={note}
      amountName="amount"
      amountLabel={t('amount')}
      amountValue={amountStoreValue}
      className={styled.amountSummaryCard}
      onNotesValueChange={handleNoteChange}
    />
  );
}

const styled = {
  amountSummaryCard: css`
    margin-top: 24px;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin-top: 16px;
    }
  `,
};
export default SingleAmountSummaryCard;
