import { css } from '@emotion/css';
import {
  AddOnTextField,
  Divider,
} from '@topremit/shared-web/components/elements';
import { useTranslation } from '@topremit/shared-web/hooks';

export interface IDealingRateField {
  status: 'active' | 'disable' | 'success';
  isAppliedDealCode: boolean;
  refetchDealingCode: () => void;
}

export default function DealingRateField({
  isAppliedDealCode,
  status,
  refetchDealingCode,
}: IDealingRateField) {
  const { t } = useTranslation('transaction');

  const buttonLabel = {
    active: t('deal_rate.apply'),
    disable: t('deal_rate.apply'),
    success: t('deal_rate.applied'),
  };

  return (
    <>
      <AddOnTextField
        name="dealingCode"
        disabled={isAppliedDealCode}
        label={t('deal_rate.input')}
        status={status}
        buttonLabel={buttonLabel}
        onClickButton={refetchDealingCode}
        className={styled.root}
      />
      <Divider />
    </>
  );
}

const styled = {
  root: css`
    margin: 0 !important;
  `,
};
