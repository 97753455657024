import { useGetMe } from '@api-hooks/common';
import { css, cx } from '@emotion/css';
import { useClientKycProps, useGetKycAbad, useShowKycBlocking } from '@hooks';
import { KycAbadStatus } from '@modules/kyc';
import { ChooseRecipientCard } from '@modules/landing/jumbotron';
import { useDialogStore } from '@topremit/shared-web/stores';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useState } from 'react';

import useDomesticFlow from './hooks/use-domestic-flow';
import { DomesticRecipientStep } from './types';

const RecipientBankAccountDetails = dynamic(
  () => import('@elements/RecipientBankAccountDetails'),
);

function RecipientCard() {
  const router = useRouter();

  const showDialog = useDialogStore((s) => s.show);

  const {
    errors: { errorRecipientMessage },
    stepper: { handleStepChange },
    values: { recipient },
  } = useDomesticFlow();

  const { handleKycBlockingModal } = useShowKycBlocking();
  const { statusKycAbad, isKycAbadStatusReady } = useGetKycAbad();
  const { isShowClientBlockingModal, clientBlockingModal } =
    useClientKycProps();

  const { data: meData } = useGetMe();
  const me = meData?.data;

  const [isRecipientCardClicked, setIsRecipientCardClicked] = useState(false);

  function handleBlockingKycAbad() {
    if (me) {
      showDialog({
        body: handleKycBlockingModal(),
      });
    }
  }

  function handleClickChooseRecipientCard() {
    if (isShowClientBlockingModal) {
      clientBlockingModal();
      return;
    }
    if (
      me &&
      isKycAbadStatusReady &&
      statusKycAbad !== KycAbadStatus.APPROVE_KYC_ABAD
    ) {
      handleBlockingKycAbad();
      return;
    }

    setIsRecipientCardClicked(true);
    handleStepChange(router, DomesticRecipientStep.TYPE);
  }

  function handleClickRecipientBankAccountDetails() {
    handleStepChange(router, DomesticRecipientStep.CHOOSE);
  }

  return recipient ? (
    <RecipientBankAccountDetails
      withArrow
      error={errorRecipientMessage}
      logoUrl={recipient?.payerLogo}
      className={styled.recipientCard}
      name={String(recipient?.beneficiary.fullName)}
      accountNumber={String(recipient?.accountNumber)}
      onClick={handleClickRecipientBankAccountDetails}
    />
  ) : (
    <ChooseRecipientCard
      isError={!!errorRecipientMessage}
      isDisabled={isRecipientCardClicked}
      errorMessage={errorRecipientMessage}
      onClick={handleClickChooseRecipientCard}
      className={cx('card card-secondary', styled.recipientCard)}
    />
  );
}

const styled = {
  recipientCard: css`
    cursor: pointer;
  `,
};

export default RecipientCard;
