import { requestFn } from '@common/client';
import { useQuery as useCustomQuery } from '@hooks';
import { ApiError, ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import { UseQueryOptions } from 'react-query';

import {
  IGetCurrentRateParams,
  RateAlertChannelsModel,
  RateAlertModel,
  RateAlertRateModel,
} from './rate-alert.model';

export function useGetRateAlert(
  options?: UseQueryOptions<ApiResult<RateAlertModel[]>, ApiError>,
) {
  return useCustomQuery<RateAlertModel[]>(
    ['rate-alert'],
    async () =>
      await requestFn({
        path: `rate-alert`,
        method: 'get',
      }),
    { ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetRateAlertDetail(
  id: string,
  options?: UseQueryOptions<ApiResult<RateAlertModel>, ApiError>,
) {
  return useCustomQuery<RateAlertModel, ApiError>(
    ['rate-alert', id],
    async () =>
      await requestFn({
        path: `rate-alert/${id}`,
        method: 'get',
      }),
    {
      enabled: !!id,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}

export function useGetRateAlertChannels(
  options?: UseQueryOptions<ApiResult<RateAlertChannelsModel[]>, ApiError>,
) {
  return useCustomQuery<RateAlertChannelsModel[]>(
    ['rate-alert-channel'],
    async () =>
      await requestFn({
        path: `rate-alert/channels`,
        method: 'get',
      }),
    { ...options },
    {
      hasLang: false,
    },
  );
}

export function useGetCurrentRate(
  params: IGetCurrentRateParams,
  options?: UseQueryOptions<ApiResult<RateAlertRateModel>, ApiError>,
) {
  const { country, currency, serviceId, routingChannel } = params;
  return useCustomQuery<RateAlertRateModel>(
    ['current-rate'],
    async () =>
      await requestFn(
        {
          path: `rate-alert/rate`,
          method: 'get',
        },
        {
          searchParams: {
            country,
            currency,
            serviceId,
            routingChannel,
          },
        },
      ),
    {
      enabled: false,
      ...options,
    },
    {
      hasLang: false,
    },
  );
}
