import { dynamicImportWithSpinner } from '@topremit/shared-web/common/import';
import { Flex } from '@topremit/shared-web/components/elements';
import {
  Select,
  SelectItem,
  SelectList,
} from '@topremit/shared-web/components/elements/select';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useState } from 'react';

import { SelectBankProps } from '..';
const SelectBankModalBody = dynamicImportWithSpinner(
  () => import('./SelectBankModalBody'),
);

export default function SelectBank({
  value,
  error,
  className,
  transactionType,
  showSpeedPills,
  onValueChange,
}: SelectBankProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const showDialog = useDialogStore((s) => s.push);
  const popDialog = useDialogStore((s) => s.pop);

  function handleClick() {
    showDialog({
      body: (
        <SelectBankModalBody
          value={value}
          showSpeedPills={showSpeedPills}
          transactionType={transactionType}
          onValueChange={(value) => {
            onValueChange(value);
            setOpen(false);
          }}
        />
      ),
      onClose: () => {
        setOpen(false);
        popDialog();
      },
    });
  }

  return (
    <Flex column className={className}>
      <Select
        fullWidth
        open={open}
        name="bank"
        error={error}
        onClick={handleClick}
        onOpenChange={setOpen}
        value={String(value?.id)}
        renderValue={() => value?.name}
        label={t('abad.form.label.bank')}
      >
        {/* Need to render this selected value, so the Select component can get the hasValue state.
        The SelectList (inside a dialog) has been handled by the handleClick function */}
        <SelectList>
          <SelectItem value={String(value?.id)}>{value?.name}</SelectItem>
        </SelectList>
      </Select>
    </Flex>
  );
}
