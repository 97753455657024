import { useEditRecipient } from '@api-hooks/recipient';
import {
  useCreateBulkQuotation,
  usePostRemitQuotation,
} from '@api-hooks/transaction';
import { useAnalytics } from '@hooks';
import useSoftHardLimit from '@hooks/use-soft-hard-limit';
import { useNotification } from '@topremit/shared-web/hooks';
import { useRouter } from 'next/router';
import { useShallow } from 'zustand/react/shallow';

import useDomesticError from './use-domestic-error';
import { useDomesticFlowStore } from './use-domestic-flow-store';
import useDomesticRecipientIdAndPayerId from './use-domestic-recipient-id-and-payer-id';

export default function useDomesticQuotation() {
  const router = useRouter();
  const { addNotification } = useNotification();
  const { trackerPersonalBusiness } = useAnalytics();

  const { handleSoftLimit, handleHardLimit } = useSoftHardLimit();

  const { isLoadingQuotation, quotation, setField } = useDomesticFlowStore(
    useShallow((s) => s),
  );
  const { setError } = useDomesticError();

  const { recipientIdByDeliverySpeed } = useDomesticRecipientIdAndPayerId();

  const quotationMutation = usePostRemitQuotation({
    mutationKey: 'single-dom-quotation',
    onMutate: () => {
      setField('isLoadingQuotation', true);
      setError({ type: 'services', error: '' });
      setError({ type: 'recipient', error: '' });
    },
    onSettled: () => {
      setField('isLoadingQuotation', false);
    },
    onSuccess: ({ data }) => {
      setField('quotation', data);
      if (data.showLimitWarning && data.limitType) {
        handleSoftLimit(data.limitType);
      }
    },
    onError: ({ message, data }) => {
      if (data.type) {
        setError({
          type: 'hardLimit',
          error: message,
        });
        handleHardLimit(data.type);
        return;
      }
      addNotification({ type: 'danger', message });
    },
  });

  const bulkQuotationMutation = useCreateBulkQuotation({
    onMutate: () => {
      setField('isLoadingQuotation', true);
      setError({ type: 'recipient', error: '' });
      setError({ type: 'services', error: '' });
      setError({ type: 'recipientBatches', error: [] });
    },
    onSettled: () => {
      setField('isLoadingQuotation', false);
    },
    onSuccess: ({ data }) => {
      trackerPersonalBusiness?.addToCartDomesticMultiple({
        ref_id: data.quotationBatchId,
      });

      router.push(
        `/activity/transaction/create/${data.quotationBatchId}/DOMESTIC/confirm-transaction-details`,
      );
    },
    onError: ({ data, message }) => {
      if (data) {
        setError({
          type: 'recipient',
          error: data.message,
        });
        setError({
          type: 'recipientBatches',
          error: data.data.recipientBatches as number[],
        });
        return;
      }
      addNotification({ type: 'danger', message });
    },
  });

  const quotationRecipientMutation = useEditRecipient(
    {
      id: Number(recipientIdByDeliverySpeed ?? 0),
      refId: quotation?.referenceId,
    },
    {
      onMutate: () => {
        setField('isLoadingQuotation', true);
      },
      onSettled: () => {
        setField('isLoadingQuotation', false);
      },
      onSuccess: () => {
        trackerPersonalBusiness?.addToCartDomesticSingle({
          ref_id: String(quotation?.referenceId),
        });

        router.push(
          `/activity/transaction/create/${quotation?.referenceId}/DOMESTIC/confirm-transaction-details`,
        );
      },
      onError: ({ message }) => {
        addNotification({ type: 'danger', message });
      },
    },
  );

  return {
    quotation,
    quotationMutation,
    isLoadingQuotation,
    bulkQuotationMutation,
    quotationRecipientMutation,
  };
}
