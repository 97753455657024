import { css } from '@emotion/css';
import { useBanner } from '@hooks';
import { SkeletonWrapper } from '@topremit/shared-web/components/elements';
import { useTimer, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { useEffect, useState } from 'react';

import ExpireDealRateModal from './ExpireDealRateModal';

interface ITimerProps {
  expiredTime: string;
  skeletonStyle?: string;
}

type ColorScheme = 'red' | 'blue' | 'orange';
const tenMinToSec = 600;
export default function DealRateTimer({
  expiredTime,
  skeletonStyle,
}: ITimerProps) {
  const { t } = useTranslation('transaction');
  const { duration, startTimer } = useTimer();
  const { changeColor } = useBanner();
  const [textColor, setTextColor] = useState<ColorScheme>('blue');

  const showDialog = useDialogStore((store) => store.show);
  const splitDuration = duration.split(':');
  const remainderTime =
    splitDuration.length === 2
      ? Number(splitDuration[0]) * 60 + Number(splitDuration[1])
      : 0;

  const isAlreadyExpired =
    remainderTime && remainderTime <= 0 && splitDuration.length === 2;

  function showExpireModal() {
    showDialog({
      body: <ExpireDealRateModal />,
    });
  }

  function showUnitOnTimer() {
    if (splitDuration.length === 3) {
      return `${splitDuration[0] + t('common:timer.hour')} : ${
        splitDuration[1] + t('common:timer.minutes')
      } : ${splitDuration[2] + t('common:timer.seconds')}`;
    } else if (splitDuration.length === 2) {
      return `${splitDuration[0] + t('common:timer.minutes')} : ${
        splitDuration[1] + t('common:timer.seconds')
      }`;
    }
  }
  useEffect(() => {
    if (typeof expiredTime !== 'undefined') {
      startTimer(expiredTime);
    }
  }, [expiredTime]);

  useEffect(() => {
    if (duration !== '' && splitDuration.length === 2) {
      if (remainderTime === 0) {
        changeColor('red');
        setTextColor('red');
        showExpireModal();
      } else if (
        remainderTime &&
        remainderTime <= tenMinToSec &&
        remainderTime !== 0
      ) {
        setTextColor('orange');
      }
    }
  }, [duration]);

  return (
    <SkeletonWrapper
      loading={duration === ''}
      className={skeletonStyle || skeletonField}
    >
      <span>
        {isAlreadyExpired
          ? t('deal_rate.already_expired')
          : t('deal_rate.expire_in')}
        <span className={styled[textColor]}>{showUnitOnTimer()}</span>
      </span>
    </SkeletonWrapper>
  );
}

const skeletonField = css`
  width: 10.625rem;
  border-radius: 0.5rem;
`;

const styled = {
  red: css`
    color: var(--red-500);
  `,
  blue: css`
    color: var(--blue-500);
  `,
  orange: css`
    color: var(--orange-500);
  `,
};
