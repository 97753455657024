import { css } from '@emotion/css';
import { UploadedFile } from '@topremit/shared-web/components/elements/fields/types';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';
import { SetStateStore, setStore } from 'landing/src/stores/helper';
import Link from 'next/link';
import Trans from 'next-translate/Trans';
import { createElement } from 'react';
import { create } from 'zustand';

export type RecipientDocumentType = 'invoice' | 'payslip';

interface UseUploadRecipientDocumentArgs {
  type?: RecipientDocumentType;
}

interface IRecipientDocumentStore {
  file: UploadedFile | null;
  setFile: (value: SetStateStore<UploadedFile | null>) => void;
}

const invoiceGuide = {
  invoice: {
    en: 'https://help.topremit.com/en/support/solutions/articles/43000742423',
    id: 'https://help.topremit.com/id/support/solutions/articles/43000742423',
  },
  payslip: {
    en: 'https://help.topremit.com/en/support/solutions/articles/43000742424',
    id: 'https://help.topremit.com/id/support/solutions/articles/43000742424',
  },
};

const useFileStore = create<IRecipientDocumentStore>((set) => ({
  file: null,
  setFile: (value) => set((state) => setStore(state, { file: value })),
}));

/**
 * Hook to manage the upload of recipient documents, such as invoice or payslip.
 */
export default function useUploadRecipientDocument({
  type = 'invoice',
}: UseUploadRecipientDocumentArgs = {}) {
  const { t, lang } = useTranslation('transaction');
  const { file, setFile } = useFileStore();

  const guideLink = invoiceGuide[type][lang];

  const content = {
    invoice: {
      title: t('recipient_document.invoice.title'),
      description: t('recipient_document.invoice.description'),
      notes: getNotes(
        'transaction:recipient_document.invoice.notes',
        guideLink,
      ),
      uploaderTitle: getUploaderTitle(
        'transaction:recipient_document.invoice.uploader_title',
      ),
    },
    payslip: {
      title: t('recipient_document.payslip.title'),
      description: t('recipient_document.payslip.description'),
      notes: getNotes(
        'transaction:recipient_document.payslip.notes',
        guideLink,
      ),
      uploaderTitle: getUploaderTitle(
        'transaction:recipient_document.payslip.uploader_title',
      ),
    },
  };

  return { content: content[type], file, setFile };
}

function getNotes(i18nKey: string, href: string) {
  return createElement(Trans, {
    i18nKey,
    components: {
      link: createElement(Link, {
        href,
        rel: 'nofollow',
        prefetch: false,
        target: '_blank',
        className: styled.blue,
      }),
    },
  });
}

function getUploaderTitle(i18nKey: string) {
  return createElement(Trans, {
    i18nKey,
    components: {
      blue: createElement('span', {
        className: styled.blue,
      }),
    },
  });
}

const styled = {
  blue: css`
    color: ${color.blue500};
  `,
};
