import { AnimatedHeightWrapper } from '@elements';
import { css, cx } from '@emotion/css';
import AdditionalNotesInput from '@modules/recipient/AdditionalNotesInput';
import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import { screenSize, typography } from '@topremit/shared-web/common/size';
import { ErrorMessage, Flex } from '@topremit/shared-web/components/elements';
import { Angle, User } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { color } from '@topremit/shared-web/styles/color';

interface MultiRecipientSummaryCardProps extends RecipientSummaryProps {
  error?: string;
  className?: string;
  notesValue: string;
  notesLabel?: string;
  onNotesValueChange: (value: string) => void;
}

interface RecipientSummaryProps {
  totalRecipients: number;
  totalAmount: number;
  className?: string;
  onClick?: () => void;
}

/**
 * A card component that displays multiple recipient summary (recipient count & total amount) and an additional notes input.
 * It is designed to handle note values with callbacks for external state management.
 *
 * @component
 * @example
 * <MultiRecipientSummaryCard
 *   notesValue={notes}
 *   totalRecipients={3}
 *   totalAmount={24_000_000}
 *   notesLabel="Additional notes"
 *   onNotesValueChange={(value) => setNotes(value)}
 * />
 */
function MultiRecipientSummaryCard({
  error,
  className,
  notesLabel,
  notesValue,
  totalAmount,
  totalRecipients,
  onNotesValueChange,
  onClick,
}: MultiRecipientSummaryCardProps) {
  function handleNotesChange(value: string) {
    if (onNotesValueChange) {
      onNotesValueChange(value);
    }
  }

  return (
    <Flex className={cx('card card-main', styled.root, className)}>
      <AnimatedHeightWrapper className={styled.wrapper}>
        <Flex column className={styled.summary}>
          <RecipientSummary
            totalAmount={totalAmount}
            totalRecipients={totalRecipients}
            className={cx({ [styled.redBorder]: Boolean(error) })}
            onClick={onClick}
          />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Flex>
        <AdditionalNotesInput
          label={notesLabel}
          value={notesValue}
          onValueChange={handleNotesChange}
        />
      </AnimatedHeightWrapper>
    </Flex>
  );
}

function RecipientSummary({
  totalRecipients,
  totalAmount,
  className,
  onClick,
}: RecipientSummaryProps) {
  const { t } = useTranslation('transaction');
  return (
    <Flex className={cx(styled.recipient, className)} onClick={onClick}>
      <Flex align="center" justify="space-between" gap={12}>
        <Flex align="center">
          <Flex column className={styled.iconWrapper}>
            <Flex justify="center" align="center" className={styled.icon}>
              <User size={32} fill={color.blue500} />
            </Flex>
          </Flex>

          <Flex column>
            <Flex className={styled.title}>
              {t('domestic.total_recipients', {
                total: totalRecipients,
              })}
            </Flex>
            <Flex className={styled.description}>
              {formatToIDRCurrency(totalAmount)}
            </Flex>
          </Flex>
        </Flex>

        <Angle direction="right" size="small" />
      </Flex>
    </Flex>
  );
}

const styled = {
  root: css`
    background-color: ${color.neutral0};
    padding: 16px;
    border-radius: 16px;
  `,
  wrapper: css`
    width: 100%;
  `,
  iconWrapper: css`
    width: unset;
  `,
  icon: css`
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: ${color.blue100};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 24px;
    @media (max-width: ${screenSize.tabletMd}px) {
      margin-right: 12px;
    }
  `,
  title: css`
    font-size: ${typography.body2Medium.fontSize}rem;
    font-weight: ${typography.body2Medium.fontWeight};
    line-height: ${typography.body2Medium.lineHeight}px;

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: ${typography.body3Medium.fontSize}rem;
      line-height: ${typography.body3Medium.lineHeight}px;
    }
  `,
  description: css`
    color: ${color.neutral500};
    font-size: ${typography.body3Medium.fontSize}rem;
    line-height: ${typography.body3Medium.lineHeight}px;

    @media (max-width: ${screenSize.tabletMd}px) {
      font-size: ${typography.smallMedium.fontSize}rem;
      line-height: ${typography.smallMedium.lineHeight}px;
    }
  `,
  recipient: css`
    cursor: pointer;
    padding: 12px;
    border-radius: 16px;
    background-color: ${color.neutral0};
    border: 1px solid ${color.neutral200};
  `,
  summary: css`
    margin-bottom: 12px;
  `,
  redBorder: css`
    border-color: ${color.red500};
  `,
};

export default MultiRecipientSummaryCard;
