import { css, cx } from '@emotion/css';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { screenSize } from '@topremit/shared-web/common/size';
import { Notes } from '@topremit/shared-web/components/elements';
import { useMediaQuery } from '@topremit/shared-web/hooks';
import Image from 'next/image';

import useCorridorNote from './use-corridor-note';

interface CorridorHolidayMaintenanceNoteProps {
  routingChannel: RoutingChannelType | undefined;
  serviceId: number | undefined;
  destinationCountry: string;
  destinationCurrency: string;
  className?: string;
}

function CorridorHolidayMaintenanceNote({
  serviceId,
  className,
  routingChannel,
  destinationCountry,
  destinationCurrency,
}: CorridorHolidayMaintenanceNoteProps) {
  const isMobileSize = useMediaQuery(`(max-width:${screenSize.tabletMd}px)`);
  const iconSize = isMobileSize ? 32 : 40;

  const { descNote, titleNote, corridorIcon, corridorStatus, hasCorridorNote } =
    useCorridorNote({
      destinationCountry,
      destinationCurrency,
      serviceId,
      routingChannel,
    });

  if (!hasCorridorNote) {
    return null;
  }

  return (
    <Notes
      showIcon
      title={titleNote}
      message={descNote}
      status={corridorStatus}
      className={cx(styled.root, className)}
      icon={
        <Image alt="" src={corridorIcon} width={iconSize} height={iconSize} />
      }
    />
  );
}

const styled = {
  root: css`
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-alert-content .ant-alert-message {
      margin-bottom: 0;
    }
  `,
};

export default CorridorHolidayMaintenanceNote;
