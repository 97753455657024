import { InfoField } from '@elements';
import { css } from '@emotion/css';
import { useDomesticFlow } from '@modules';
import { useVoucherStore } from '@stores';
import {
  formatThousandSeparator,
  formatToIDRCurrency,
} from '@topremit/shared-web/common/helper';
import { Button, Flex, Text } from '@topremit/shared-web/components/elements';
import { Angle } from '@topremit/shared-web/components/shapes';
import { useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { color } from '@topremit/shared-web/styles/color';
import { MutableRefObject } from 'react';
import { createPortal } from 'react-dom';
import { useFormContext } from 'react-hook-form';

interface TotalAmountButtonProps {
  targetRef: MutableRefObject<null>;
}

function TotalAmountButton({ targetRef }: TotalAmountButtonProps) {
  const { t } = useTranslation();

  const showDialog = useDialogStore((s) => s.push);
  const setVoucher = useVoucherStore((s) => s.setVoucher);

  const formContext = useFormContext();

  const {
    values: { amount, isBulk, isExceededAmount, isLoadingQuotation },
    errors: { hardLimitMessage },
    handlers: { handleClickContinueCalculator },
  } = useDomesticFlow();

  const isDisabled = !!hardLimitMessage || (!isBulk ? isExceededAmount : false);

  function handleShowAmountDetail() {
    showDialog({
      body: <AmountDetailModal />,
    });
  }
  function handleContinue() {
    formContext.trigger('amount');
    if (formContext.formState.errors.amount?.message) {
      return;
    }
    handleClickContinueCalculator();
    setVoucher(null);
  }

  return targetRef.current
    ? createPortal(
        <div>
          {isBulk && Number(amount ?? 0) > 0 && (
            <Flex column gap={8} style={{ marginBottom: '16px' }}>
              <Flex justify="space-between">
                <Text className={styled.footerAmountLabel}>
                  {t('total_amount')}
                </Text>
                <Text
                  as="button"
                  className={styled.footerAmountValue}
                  onClick={handleShowAmountDetail}
                >
                  IDR {formatThousandSeparator(amount)}{' '}
                  <Angle direction="down" size="small" />
                </Text>
              </Flex>
            </Flex>
          )}
          <Button
            fullWidth
            disabled={isDisabled}
            isLoading={isLoadingQuotation}
            onClick={handleContinue}
          >
            {t('continue')}
          </Button>
        </div>,
        targetRef.current,
      )
    : null;
}

function AmountDetailModal() {
  const {
    data: { services },
    values: { amount, deliverySpeed, bulkRecipients },
  } = useDomesticFlow();
  const { t } = useTranslation('transaction');
  const selectedRoutingChannel = services.data?.data
    .find((service) => service.id === deliverySpeed.serviceId)
    ?.routingChannels.find(
      (routingChannel) => routingChannel.type === deliverySpeed.routingChannel,
    );

  const isExceedMinimumAmountDiscountFee =
    Number(amount ?? 0) >
    Number(selectedRoutingChannel?.discount?.minimumAmount ?? 0);

  const hasDiscountFee =
    isExceedMinimumAmountDiscountFee && selectedRoutingChannel?.discount?.value;

  const fee = hasDiscountFee
    ? selectedRoutingChannel?.fee -
      Number(selectedRoutingChannel?.discount?.value ?? 0)
    : selectedRoutingChannel?.fee;

  const totalDiscountedFee = Number(fee ?? 0) * bulkRecipients.length;
  const totalFee =
    Number(selectedRoutingChannel?.fee ?? 0) * bulkRecipients.length;

  const totalAmountWithFee = formatToIDRCurrency(
    Number(amount ?? 0) +
      (isExceedMinimumAmountDiscountFee &&
      selectedRoutingChannel?.discount?.value
        ? totalDiscountedFee
        : totalFee),
  );
  return (
    <div className={styled.root}>
      <h5 className={styled.totalAmountTitle}>
        {t('domestic.total_amount_details.total_amount')}
      </h5>
      <InfoField
        label={t('domestic.total_amount_details.total_sent_amount')}
        value={formatToIDRCurrency(amount ?? 0)}
      />
      <InfoField
        label={t('domestic.total_amount_details.total_fee', {
          fee: formatToIDRCurrency(selectedRoutingChannel?.fee || 0),
          totalRecipient: bulkRecipients.length ?? 0,
        })}
        value={formatToIDRCurrency(totalDiscountedFee)}
        {...(hasDiscountFee && {
          normalFee: formatToIDRCurrency(totalFee),
        })}
      />
      <InfoField
        label={t('domestic.total_amount_details.total_amount')}
        value={totalAmountWithFee}
      />
    </div>
  );
}

const styled = {
  root: css`
    .label,
    .value {
      margin: 0 !important;
    }

    .label {
      max-width: 55% !important;
    }

    .value {
      justify-content: right;
      max-width: 45% !important;
    }
  `,
  footerAmountLabel: css`
    font-size: var(--sm-font-size);
    color: ${color.neutral500};
  `,
  footerAmountValue: css`
    gap: 0.25rem;
    display: flex;
    align-items: center;
    font-size: var(--regular-font-size);
    font-weight: var(--bold-font-weight);
    text-align: left;
  `,
  totalAmountTitle: css`
    margin-bottom: 16px;
  `,
  hardLimitMessage: css`
    margin-bottom: 16px;
  `,
};

export default TotalAmountButton;
