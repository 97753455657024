import {
  AccountInfo,
  AccountInfoBusiness,
  AccountType,
  KycStatus,
  useGetAccountInfo,
  useGetMe,
  UserPersonal,
} from '@api-hooks/common';
import {
  useGetCorridorAnnouncement,
  useGetCountriesCurrencies,
  useGetLiveRate,
  useGetRatesHistory,
  useGetRecipient,
  useGetServices,
  usePostRemitQuotation,
} from '@api-hooks/transaction';
import { queryClient, requestFn } from '@common/client';
import { UNCOMPLETED_QUOTATION_KEY } from '@common/storage';
import { cx } from '@emotion/css';
import {
  ChooseRecipientStep,
  useAnalytics,
  useAuth,
  useClientKycProps,
  useDealingRate,
  useGetKycAbad,
  usePermission,
  usePrevQuotationFormValues,
  useRecipient,
  useShowChatUsDialog,
  useShowKycBlocking,
} from '@hooks';
import { KycAbadStatus } from '@modules/kyc';
import { useCurrentAccountStore } from '@stores';
import { ApiResult } from '@topremit/shared-web/api-hooks/api.model';
import {
  BankAccountMatchingStatus,
  RoutingChannelModel,
  ServiceId,
  ServiceModel,
} from '@topremit/shared-web/api-hooks/common';
import {
  HardLimitType,
  PersonalQuotationFormValues,
  QuotationNoteModel,
  QuotationSubmitConfirmation,
  RemitQuotationModel,
  RoutingChannelType,
  SoftLimitType,
  TransactionRecipientType,
  TransactionType,
} from '@topremit/shared-web/api-hooks/transaction';
import {
  encryptString,
  getCurrentDate,
  isEmptyObject,
  isStringEmpty,
  scrollToElement,
  validateDate,
} from '@topremit/shared-web/common/helper';
import { isDeepEqual } from '@topremit/shared-web/common/object';
import { screenSize } from '@topremit/shared-web/common/size';
import { Button, parseHtml } from '@topremit/shared-web/components/elements';
import SkeletonWrapper from '@topremit/shared-web/components/elements/SkeletonWrapper';
import { ICountryCurrencyValue } from '@topremit/shared-web/components/elements/fields/choose-country-currency/types';
import { InfoOutline } from '@topremit/shared-web/components/shapes';
import {
  useDebounce,
  useGlobalState,
  useMediaQuery,
  useNotification,
  usePrevious,
  useShallowEffect,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { FailedAddToCartError } from '@topremit/shared-web/typings/transaction.model';
import {
  HTMLReactParserOptions,
  Element as ParserElement,
} from 'html-react-parser';
import { useRouter } from 'next/router';
import {
  cloneElement,
  createElement,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { FormProvider, useForm } from 'react-hook-form';

import {
  ChooseServiceModalBody,
  ChooseServiceModalFooter,
  DealingRateField,
  HardLimitFrequencyModalBody,
  HardLimitVolumeModalBody,
  QuotationOtherDetailsModalBody,
  SoftLimitFrequencyModalBody,
  SoftLimitVolumeModalBody,
} from './';
import { ICalculatorRemit } from './Calculator.typing';
import CalculatorAmountField from './CalculatorAmountField';
import QuotationInfo from './QuotationInfo';
import { RecipientModal } from '../../dashboard';
import { DomesticNewTabCoachMark } from './domestic/coachmarks';
import { removeQuoteQueryParams } from './helper';
import { calculatorRemit as styled } from './styled';

export interface ServiceDetailModel
  extends Omit<ServiceModel, 'routingChannels'> {
  routingChannel: RoutingChannelModel;
}

enum SubmitEntryPoint {
  CONTINUE_BUTTON = 'CONTINUE_BUTTON',
  UPDATE_LATER_BUTTON = 'UPDATE_LATER_BUTTON',
}

enum RoutingChannelOrder {
  INSTANT = 0,
  REGULAR = 1,
}

const PAYER_ROUTING_CHANNEL = 'PAYER_ROUTING_CHANNEL';
const RECIPIENT_FORM_VALUES = 'RECIPIENT_FORM_VALUES';
const SELECTED_SERVICE_DETAIL = 'SELECTED_SERVICE_DETAIL';
const PAYER_ROUTING_CHANNEL_TYPE = 'PAYER_ROUTING_CHANNEL_TYPE';

function CalculatorRemit({
  country: DEFAULT_COUNTRY,
  amountSend: DEFAULT_AMOUNT_SEND = 1000000,
  targetRef,
}: ICalculatorRemit) {
  const router = useRouter();
  const isLandingPage = router.pathname === '/';
  const isSendMoneyPage = router.pathname.includes('send-money');

  const { data: me } = useGetMe();
  const { data: accountInfo } = useGetAccountInfo<
    AccountInfoBusiness & AccountInfo
  >();
  const { t, lang } = useTranslation();
  const prevLang = usePrevious(lang);
  const { trackerPersonalBusiness } = useAnalytics();
  const { statusKycAbad, isKycAbadStatusReady, refetchKycSubmittedData } =
    useGetKycAbad();

  const { addNotification } = useNotification();
  const { isBusinessAccount } = usePermission();
  const { isAuthenticated } = useAuth();

  const { showChatDialog } = useShowChatUsDialog(
    'email',
    accountInfo?.data?.email,
  );

  const showDialog = useDialogStore((store) => store.show);
  const closeDialog = useDialogStore((store) => store.close);

  const currentAccount = useCurrentAccountStore(
    (store) => store.currentAccount,
  );

  const isMobileSize = useMediaQuery(`(max-width: ${screenSize.tabletMd}px)`);

  const { clientBlockingModal, isShowClientBlockingModal } =
    useClientKycProps();

  const isKycAbadNotApproved = statusKycAbad !== KycAbadStatus.APPROVE_KYC_ABAD;
  const isLandingSwift = router.pathname === '/swift-transfer';

  const isBusinessAccountVerified =
    accountInfo?.data?.kycStatus?.value === KycStatus.DATA_APPROVED &&
    accountInfo?.data?.dashboardStatus;

  const isShowPersonalBlockingModal =
    !isBusinessAccount && me && isKycAbadStatusReady && isKycAbadNotApproved;

  const isPersonalAccountVerified =
    statusKycAbad === KycAbadStatus.APPROVE_KYC_ABAD;

  const isValidAccount = isBusinessAccount
    ? isBusinessAccountVerified
    : isPersonalAccountVerified;

  const {
    quotation: prevQuotation,
    recipient,
    hasPayerSpeed,
    isLimitExceed,
    changedAmount,
    selectedRecipientId,
    hasChangedDestinationAmount,
    setStep,
    setPayerId,
    setRecipient,
    setQuotation,
    setChangedAmount,
    setHasChangedDestinationAmount,
    setIsInvoiceChecked,
    setDestinationCurrency,
    setSelectedRecipientId,
    setServiceLimit,
  } = useRecipient();

  const {
    prevQuotationFormValues,
    emptyPrevQuotationFormValues,
    storePrevQuotationFormValues,
  } = usePrevQuotationFormValues();

  const [quotationError, setQuotationError] = useState<string>();

  const [serviceDetail, setServiceDetail] = useState<ServiceDetailModel>();

  const [isErrorChooseRecipient, setIsErrorChooseRecipient] = useState(false);
  const [isErrorChooseService, setIsErrorChooseService] = useState(false);
  const [canShowServiceModal, setCanShowServiceModal] = useState(false);
  const [canRefetchLiveRate, setCanRefetchLiveRate] = useState(false);
  const [hasChooseService, setHasChooseService] = useState(false);

  const [serviceOrder, setServiceOrder] = useState(0);
  const [routingChannelOrder, setRoutingChannelOrder] = useState(0);
  // set -1 because the service is not automatically choosed
  const [modalServiceOrder, setModalServiceOrder] = useState(-1);
  const [modalRoutingChannelOrder, setModalRoutingChannelOrder] = useState(-1);

  const [isNoteShown, setIsNoteShown] = useState<boolean>(false);

  // do render when useRef change
  const [, forceRender] = useReducer((p) => !p, false);

  const methods = useForm<PersonalQuotationFormValues>({
    defaultValues: {
      serviceId: -1,
      isSendAmount: !router.query.amount,
      destinationCountry: router.query.country?.toString() || '',
      countryAndCurrency: {
        country: router.query.country as string,
        currency: router.query.currency as string,
      },
      destinationCurrency: '',
      amountSend: DEFAULT_AMOUNT_SEND || 0,
      amountReceive: router.query.amount ? Number(router.query.amount) : 0,
      ...(isSendMoneyPage || !isEmptyObject(router.query)
        ? {}
        : prevQuotationFormValues),
    },
  });

  function handleTrackAddToCart(value: string) {
    trackerPersonalBusiness?.addToCart({
      ref_id: value,
    });
  }

  function onSubmit() {
    const { referenceId, submitConfirmation, expirationDate } =
      isAppliedDealCode ? prevQuotation : quotation?.data || {};

    if (isVerifiedKyc && (!hasChooseService || isEmptyObject(recipient))) {
      const errors: string[] = [];
      const contentCardContainer = document.querySelector(
        '.content-card-container',
      );

      if (!hasChooseService) {
        setIsErrorChooseService(true);
        const chooseServiceContainer = document.querySelector(
          '.choose-service-delivery',
        );
        if (contentCardContainer && chooseServiceContainer)
          scrollToElement(contentCardContainer, chooseServiceContainer);
        errors.push('service');
      }

      if (isEmptyObject(recipient)) {
        setIsErrorChooseRecipient(true);

        const chooseRecipientContainer = document.querySelector(
          '.choose-recipient-card',
        );
        if (contentCardContainer && chooseRecipientContainer)
          scrollToElement(contentCardContainer, chooseRecipientContainer);
        errors.push('recipient');
      }

      trackerPersonalBusiness?.failedAddToCart({
        ...(referenceId && { ref_id: referenceId }),
        error: errors.join(' ') as FailedAddToCartError,
        item_type: TransactionType.REMIT,
        type: TransactionRecipientType.SINGLE,
      });

      return;
    }

    if (!isVerifiedKyc && !hasChooseService) {
      // block un-auth user continue before choose service
      setIsErrorChooseService(true);
      trackerPersonalBusiness?.failedAddToCart({
        ...(referenceId && { ref_id: referenceId }),
        error: 'service',
        item_type: TransactionType.REMIT,
        type: TransactionRecipientType.SINGLE,
      });

      return;
    }

    const isNeedConfirmation =
      submitConfirmation !== undefined &&
      submitConfirmation !== null &&
      submitEntryPoint.current === SubmitEntryPoint.CONTINUE_BUTTON;

    if (isNeedConfirmation) {
      showSubmitConfirmation(submitConfirmation);
      return;
    }

    handleTrackAddToCart(referenceId as string);

    if (isLandingPage || isLandingSwift || isSendMoneyPage) {
      router.push('/quote');
      return;
    }

    window.localStorage.setItem(UNCOMPLETED_QUOTATION_KEY, 'null');

    emptyPrevQuotationFormValues();

    const isQuotationExpired = isExpired(expirationDate);

    if (isQuotationExpired) {
      mutateAsyncQuotation({
        isSendAmount,
        destinationCountry,
        destinationCurrency,
        amount,
        serviceId,
        routingChannel: routingChannelType,
      })
        .then(({ data }) => {
          router.replace(
            `/activity/transaction/create/${data.referenceId}/REMIT/confirm-transaction-details`,
          );
        })
        .catch(() => {});
    } else {
      router.replace(
        `/activity/transaction/create/${referenceId}/REMIT/confirm-transaction-details`,
      );
    }
  }

  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    getValues,
    clearErrors,
    formState: { errors },
  } = methods;

  const {
    dealRateData,
    refetchDealingCode,
    dealingCodeStatus,
    isAppliedDealCode,
  } = useDealingRate({ methods });

  const {
    serviceId = -1,
    amountSend,
    amountReceive,
    destinationCountry,
    destinationCurrency,
    countryAndCurrency,
    isSendAmount,
    routingChannel,
  } = watch();

  const initialCountryCurrency = usePrevious(countryAndCurrency);

  const amount = isSendAmount ? amountSend : amountReceive;
  const routingChannelType =
    (serviceDetail?.routingChannel?.type as RoutingChannelType) ||
    routingChannel;

  const prevAmountSend = usePrevious(amountSend);
  const prevAmountReceive = usePrevious(amountReceive);

  const prevValues = useRef(watch());
  const prevCorridor = useRef<string>();
  const hasJustChangedAmount = useRef(false);
  // use when got RANGE error from API LiveRate
  const temporaryLiveRate = useRef<string>();
  const prevModalServiceOrder = useRef<number>();
  const prevModalRoutingChannelOrder = useRef<number>();
  const prevCountryAndCurrency = useRef<ICountryCurrencyValue>();
  const submitEntryPoint = useRef<SubmitEntryPoint>(
    SubmitEntryPoint.CONTINUE_BUTTON,
  );

  const hasDealingCode = accountInfo?.data.hasDealingCode;

  const {
    data: countriesCurrencies,
    refetch: refetchCountriesCurrencies,
    isFetching: isFetchingCountriesCurrencies,
  } = useGetCountriesCurrencies(
    {
      serviceId: isLandingSwift ? 10 : undefined,
      currency: isLandingSwift ? 'USD' : undefined,
    },
    {
      queryKey: isLandingSwift
        ? 'countries-currencies-swift'
        : 'countries-currencies-single',
    },
  );

  const {
    data: services,
    isLoading: isLoadingServices,
    isFetching: isFetchingServices,
    refetch: refetchServices,
  } = useGetServices(
    {
      destinationCountry,
      destinationCurrency: isLandingSwift ? 'USD' : destinationCurrency,
      serviceId: isLandingSwift ? ServiceId.SWIFT : undefined,
      key: lang,
    },
    {
      onSuccess: ({ data }) => {
        if (data.length > 0) {
          if (errors.dealingCode && !isAppliedDealCode) {
            setValue('dealingCode', '');
            clearErrors('dealingCode');
          }
          if (
            (router.query.service &&
              Object.values(RoutingChannelType).includes(
                router.query.routingChannel as RoutingChannelType,
              )) ||
            isAppliedDealCode
          ) {
            const serviceType = isAppliedDealCode
              ? getValues('serviceId')
              : router.query.service;

            const routingChannelType = isAppliedDealCode
              ? dealRateData?.routingChannel
              : router.query.routingChannel;

            const routerServiceOrder = data.findIndex(
              (item) => item.id === Number(serviceType),
            );
            if (routerServiceOrder >= 0) {
              const routerRoutingChannelOrder = data[
                routerServiceOrder
              ].routingChannels.findIndex(
                (item) => item.type === routingChannelType,
              );
              setServiceDetail({
                ...data[routerServiceOrder],
                routingChannel:
                  data[routerServiceOrder]?.routingChannels[
                    routerRoutingChannelOrder
                  ],
              });
              setServiceOrder(routerServiceOrder);
              setModalServiceOrder(routerServiceOrder);
              setRoutingChannelOrder(routerRoutingChannelOrder);
              setModalRoutingChannelOrder(routerRoutingChannelOrder);

              setHasChooseService(true);
              // value of query.amount is for amount received, so fetch live to adjust the amound send value
              router.query.amount && setCanRefetchLiveRate(true);
            } else {
              addNotification({
                message: t('transaction:recreate_error'),
                type: 'danger',
                closeable: false,
              });
            }
            return removeQuoteQueryParams();
          }

          setValue('serviceId', data[0].id);
          setServiceDetail({
            ...data[0],
            routingChannel: data[0]?.routingChannels[0],
          });
          setServiceOrder(0);
          setRoutingChannelOrder(0);
          setModalServiceOrder(-1);
          setModalRoutingChannelOrder(0);
          setCanRefetchLiveRate(true);

          // auto select service and routing channel if only 1 service and 1 routing channel
          const onlyHadOneServiceAndOneRoutingChannel =
            data.length === 1 && data[0].routingChannels.length === 1;
          if (onlyHadOneServiceAndOneRoutingChannel) {
            setHasChooseService(true);
            /**
             * workaround to wait for the serviceId and routingChannel to be set
             * TODO: refactor to use react-query's key to trigger refetch
             */
            setTimeout(() => {
              refetchCorridorNote();
            }, 50);
          }
        }
      },
      onError: ({ message }) => {
        addNotification({ message, type: 'danger' });
      },
    },
  );

  const { refetch: refetchCorridorNote } = useGetCorridorAnnouncement({
    destinationCountry,
    destinationCurrency,
    serviceId,
    routingChannel: routingChannelType || '',
  });

  const { refetch: refetchGetRatesHistory } = useGetRatesHistory({
    serviceId,
    destinationCountry,
    destinationCurrency,
    routingChannel: isAppliedDealCode
      ? dealRateData?.routingChannel
      : routingChannelType,
  });

  const {
    data: liveRate,
    isLoading: isLoadingLiveRate,
    isFetching: isFetchingLiveRate,
    refetch: refetchGetLiveRate,
  } = useGetLiveRate(
    {
      amount,
      serviceId,
      isSendAmount,
      destinationCountry,
      destinationCurrency,
      routingChannel: routingChannelType,
      useDealingCode: isAppliedDealCode,
    },
    'calculator-amount',
    {
      onSuccess: ({ data }) => {
        const {
          serviceId,
          routingChannel,
          sourceAmount,
          destinationAmount,
          isServiceChanged,
        } = data || {};

        if (isServiceChanged && hasChooseService) {
          addNotification({
            message: t('calculator:service_changed_automatically'),
            type: 'success',
          });
        }

        if (hasChooseService) {
          const amount = isSendAmount ? sourceAmount : destinationAmount;
          refetchQuotation(serviceId, routingChannel, amount);
        }

        if (temporaryLiveRate.current !== undefined) {
          temporaryLiveRate.current = undefined;
        }

        setDestinationCurrency(destinationCurrency);
        setValue('serviceId', serviceId);
        setValue('amountSend', sourceAmount);
        setValue('amountReceive', destinationAmount);
      },
      onError: ({ message, data }) => {
        const { type } = data || {};

        if (!type) {
          addNotification({ message, type: 'danger' });
          return;
        }

        if (type === 'RANGE') {
          handleRangeLimit();
          setQuotationError(message);
        }
      },
      retry: (failureCount, error) => {
        return failureCount < 2 && error.statusCode !== 400;
      },
    },
  );

  const { refetch: refetchRecipient } = useGetRecipient(
    { recipientId: String(selectedRecipientId) },
    {
      enabled: false,
      onSuccess: ({ data }) => {
        setRecipient(data);
        setStep(ChooseRecipientStep.EDIT);
        localStorage.setItem(
          PAYER_ROUTING_CHANNEL,
          JSON.stringify(data.routingChannels),
        );
        showDialog({
          body: (
            <RecipientModal
              countryAndCurrency={`${countryAndCurrency.country}-${countryAndCurrency.currency}`}
              selectedRoutingChannel={routingChannelType}
            />
          ),
          fullHeight: true,
        });
      },
      onError: ({ message }) => {
        addNotification({ message, type: 'danger' });
      },
    },
  );

  const {
    data: quotation,
    mutate: mutateQuotation,
    mutateAsync: mutateAsyncQuotation,
    isLoading: isLoadingQuotation,
    reset: resetMutateQuotation,
  } = usePostRemitQuotation({
    onSuccess: ({ data }) => {
      const {
        serviceId,
        limitType,
        isServiceChanged,
        showLimitWarning,
        sourceAmount,
        destinationAmount,
        serviceOrder,
        routingChannelOrder,
        destinationCountry,
        destinationCurrency,
        referenceId,
        routingChannel,
        serviceName,
        countryFlagCode,
      } = data || {};

      const _values = {
        ...watch(),
        amountSend: sourceAmount,
        amountReceive: destinationAmount,
      };

      const hasSoftLimit = showLimitWarning && !isStringEmpty(limitType);

      if (hasSoftLimit) {
        handleSoftLimit(limitType);
      }

      if (isServiceChanged && hasChooseService) {
        setRecipient(null);
        addNotification({
          message: t('calculator:service_changed_automatically'),
          type: 'success',
        });
      }

      setServiceOrder(serviceOrder);
      setRoutingChannelOrder(routingChannelOrder);

      setQuotation({
        destinationAmount,
        destinationCountry,
        destinationCurrency,
        referenceId,
        routingChannel,
        routingChannelOrder,
        serviceId,
        serviceName,
        serviceOrder,
        sourceAmount,
        isSendAmount,
        countryAndCurrency: `${countryAndCurrency.country}-${countryAndCurrency.currency}`,
        countryFlagCode,
      } as RemitQuotationModel);

      setValue('serviceId', serviceId);
      setValue('amountSend', sourceAmount);
      setValue('amountReceive', destinationAmount);

      if (
        recipient &&
        (hasChangedDestinationAmount ||
          JSON.stringify(prevValues.current) !== JSON.stringify(watch()))
      ) {
        let maxLimit = 0;
        recipient.routingChannels?.forEach((routingChannel) => {
          if (routingChannel.type === routingChannelType) {
            maxLimit = Number(routingChannel.limit);
          }
        });
        if (destinationAmount > maxLimit) {
          setQuotationError(
            t('calculator:error_message.limit_exceed', {
              currency: destinationCurrency,
              amount: new Intl.NumberFormat('en-US').format(Number(maxLimit)),
            }),
          );
        } else {
          setQuotationError(undefined);
        }
      }

      hasJustChangedAmount.current = false;
      prevValues.current = _values;

      // Handle Set Service Limit for Confirmation Detail
      setServiceLimit({
        maxLimit: serviceDetail?.routingChannel.maxVolume,
        minLimit: serviceDetail?.routingChannel.minVolume,
      });
      selectedRecipientId !== 0 && refetchRecipient();
    },
    onError: ({ message, data }) => {
      const { type } = data || {};

      if (!type) {
        addNotification({ message, type: 'danger' });
        return;
      }

      handleHardLimit(type);
      setQuotationError(message);
    },
    retry: (failureCount, error) => {
      return failureCount < 2 && error.statusCode !== 400;
    },
  });

  const rate = useMemo(() => {
    if (isAppliedDealCode) {
      return dealRateData?.fxRate;
    }
    if (!isEmptyObject(quotation?.data)) {
      return quotation?.data.fxRate;
    }

    if (temporaryLiveRate?.current !== undefined) {
      return temporaryLiveRate?.current;
    }

    return liveRate?.data.fxRate;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    quotation?.data.fxRate,
    temporaryLiveRate.current,
    liveRate?.data.fxRate,
    isAppliedDealCode,
  ]);

  const [globalState, dispatchGlobalState] = useGlobalState();
  const changedQuotationAmount = globalState.get('changed-quotation-amount');
  const isDisabledAmountField = Boolean(
    isFetchingServices ||
      isFetchingLiveRate ||
      canRefetchLiveRate ||
      isAppliedDealCode ||
      isFetchingCountriesCurrencies,
  );

  const isLoadingQuotationInfo = Boolean(
    isLoadingServices || isLoadingLiveRate || canRefetchLiveRate,
  );

  const isLoadingBtnContinue =
    isLoadingQuotationInfo ||
    isLoadingQuotation ||
    isFetchingLiveRate ||
    isFetchingServices;

  const hasEmptyCurrencyField =
    isStringEmpty(amountSend) || isStringEmpty(amountReceive);
  const isDisabledQuotationInfo =
    !isStringEmpty(quotationError) ||
    isLoadingQuotation ||
    isFetchingServices ||
    isFetchingLiveRate ||
    hasEmptyCurrencyField;
  const isDisabledBtnContinue =
    hasEmptyCurrencyField || !isStringEmpty(quotationError);
  const canShowQuotationInfo =
    !isStringEmpty(countryAndCurrency.country) &&
    !isLoadingQuotationInfo &&
    rate;
  const canGetServices =
    destinationCurrency &&
    destinationCountry &&
    ((isSendAmount && amountSend > 0) || (!isSendAmount && amountReceive > 0));
  const quotationInfoSkeletonCount = isLandingPage || isSendMoneyPage ? 2 : 3;
  const isVerifiedKyc =
    currentAccount?.type === AccountType.PERSONAL
      ? me?.data.kycStatus === KycStatus.COMPLIANCE_APPROVED &&
        me?.data.bankAccountVerificationInfo?.matchingStatus ===
          BankAccountMatchingStatus.APPROVED
      : accountInfo?.data.kycStatus?.value === KycStatus.DATA_APPROVED;
  const { handleKycBlockingModal } = useShowKycBlocking();

  const handleReKycClick = useCallback(() => {
    router.push('/kyc').finally(() => {
      refetchKycSubmittedData();
      closeDialog();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, me?.data.idType?.value]);

  const handleContinueClick = useCallback(() => {
    handleSubmit(onSubmit)();
    closeDialog();
    submitEntryPoint.current = SubmitEntryPoint.UPDATE_LATER_BUTTON;
  }, []);

  const customConfirmationParserOption = useMemo<HTMLReactParserOptions>(
    () => ({
      replace: (domNode) => {
        if (
          domNode instanceof ParserElement &&
          domNode.attribs &&
          domNode.type === 'tag'
        ) {
          const { name } = domNode;
          const data = (domNode.children[0] as unknown as Text)?.data;

          if (!data) {
            return;
          }

          switch (name) {
            case 're-kyc':
              return <Button onClick={handleReKycClick}>{data}</Button>;
            case 'continue':
              return <Button onClick={handleContinueClick}>{data}</Button>;
            case 'close':
              return <Button onClick={closeDialog}>{data}</Button>;
            case 'customer-support':
              return (
                <span
                  className={cx('link', styled.customerSupportButton)}
                  onClick={showChatDialog}
                >
                  {data}
                </span>
              );
            default:
              return undefined;
          }
        }
      },
    }),
    [],
  );

  const showSubmitConfirmation = useCallback(
    (submitConfirmation: QuotationSubmitConfirmation) => {
      const confirmationYesOptionParsed =
        typeof submitConfirmation.optionYesLabel !== 'undefined'
          ? parseHtml(
              submitConfirmation.optionYesLabel,
              customConfirmationParserOption,
            )
          : null;
      const confirmationNoOptionParsed =
        typeof submitConfirmation.optionNoLabel !== 'undefined'
          ? parseHtml(
              submitConfirmation.optionNoLabel,
              customConfirmationParserOption,
            )
          : null;

      const ButtonEl = createElement(Button);

      const isButtonComponent = (option) =>
        typeof option !== 'string' &&
        !Array.isArray(option) &&
        option.type === ButtonEl.type;

      const confirmationYesOptionParsedEl = isButtonComponent(
        confirmationYesOptionParsed,
      )
        ? cloneElement(confirmationYesOptionParsed as ReactElement, {
            className: styled.confirmationDialogActionButton,
          })
        : null;

      const confirmationNoOptionParsedEl = isButtonComponent(
        confirmationNoOptionParsed,
      )
        ? cloneElement(confirmationNoOptionParsed as ReactElement, {
            className: styled.confirmationDialogActionButton,
            type: 'tertiary',
          })
        : null;

      showDialog({
        className: styled.modal,
        title: submitConfirmation.title,
        body: (
          <div>
            <div>
              {parseHtml(
                submitConfirmation.body,
                customConfirmationParserOption,
              )}
            </div>
            {submitConfirmation.additionalNote ? (
              <div className={styled.confirmationDialogAdditionalInfo}>
                <div className={styled.confirmationDialogAdditionalInfoIcon}>
                  <InfoOutline width={24} height={24} fill="var(--blue-500)" />
                </div>
                <div className="sm-text">
                  {parseHtml(
                    submitConfirmation.additionalNote,
                    customConfirmationParserOption,
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ),
        footer:
          confirmationYesOptionParsedEl !== null ||
          confirmationNoOptionParsedEl !== null ? (
            <div className={styled.confirmationDialogActions}>
              {confirmationYesOptionParsedEl}
              {confirmationNoOptionParsedEl}
            </div>
          ) : null,
      });
    },
    [customConfirmationParserOption],
  );

  function onChangeCurrencyField(_isSendAmount: boolean) {
    setValue('isSendAmount', _isSendAmount);
    hasJustChangedAmount.current = true;
  }

  const onClickServiceCard = useCallback(() => {
    setCanShowServiceModal(true);
    prevModalServiceOrder.current = modalServiceOrder;
    prevModalRoutingChannelOrder.current = modalRoutingChannelOrder;
    trackerPersonalBusiness?.addQuotationDetail({
      ...(quotation?.data.referenceId && {
        ref_id: quotation?.data.referenceId,
      }),
      section: 'service',
      error: '',
      item_type: TransactionType.REMIT,
    });
  }, []);

  const onClickRecipientCard = useCallback(() => {
    if (!hasChooseService) {
      setIsErrorChooseService(true);
      setIsErrorChooseRecipient(false);
      trackerPersonalBusiness?.addQuotationDetail({
        ...(quotation?.data.referenceId && {
          ref_id: quotation?.data.referenceId,
        }),
        section: 'recipient',
        error: 'service',
        item_type: TransactionType.REMIT,
      });

      return;
    }
    setIsInvoiceChecked(false);

    showDialog({
      body: (
        <RecipientModal
          countryAndCurrency={`${countryAndCurrency.country}-${countryAndCurrency.currency}`}
          selectedRoutingChannel={routingChannelType}
        />
      ),
      fullHeight: true,
    });

    trackerPersonalBusiness?.addQuotationDetail({
      ...(quotation?.data.referenceId && {
        ref_id: quotation?.data.referenceId,
      }),
      section: 'recipient',
      error: '',
      item_type: TransactionType.REMIT,
    });
  }, [hasChooseService, quotation?.data.referenceId]);

  async function handleRangeLimit() {
    // when got RANGE limit, fetch temporary rate with minVolume as amount to get current live rate
    const { minVolume } = services?.data[0].routingChannels[0] || {};
    const searchParams = {
      service_id: serviceId,
      destination_country: destinationCountry,
      destination_currency: destinationCurrency,
      routing_channel: routingChannelType,
      is_send_amount: false,
      amount: minVolume,
    } as any;

    const tempLiveRate: ApiResult<RemitQuotationModel> = await requestFn(
      { path: 'rate/live', method: 'get' },
      {
        searchParams,
        headers: {
          'X-REQ-KEY': String(
            encryptString(
              getCurrentDate(),
              String(process.env.NEXT_PUBLIC_SECRET_KEY),
            ),
          ),
        },
      },
    );

    const { fxRate } = tempLiveRate?.data || {};
    temporaryLiveRate.current = fxRate;
    forceRender();

    if (isStringEmpty(amountReceive)) {
      const _amountReceive = (
        amountSend / parseFloat(fxRate.replace(',', ''))
      ).toFixed(2);
      setValue('amountReceive', Number(_amountReceive));
    }
  }

  function handleSoftLimit(limitType: SoftLimitType) {
    switch (limitType) {
      case SoftLimitType.STATUS_EXCEED_PERIODIC_COUNT_LIMIT:
        showDialog({
          body: <SoftLimitFrequencyModalBody />,
        });
        return;
      case SoftLimitType.STATUS_EXCEED_ACCU_LIMIT:
        showDialog({
          body: <SoftLimitVolumeModalBody />,
        });
    }
  }

  function handleHardLimit(type: HardLimitType) {
    switch (type) {
      case HardLimitType.FREQUENCY:
        showDialog({
          body: <HardLimitFrequencyModalBody />,
        });
        return;
      case HardLimitType.VOLUME:
        showDialog({
          body: <HardLimitVolumeModalBody />,
        });
    }
  }

  function renderBlockingDialogKycAbad(me: UserPersonal) {
    if (me) {
      showDialog({
        body: handleKycBlockingModal(),
      });
    }
  }

  function isExpired(isoDateTime) {
    const expirationDate = new Date(isoDateTime);
    const currentDate = new Date();

    // Check if the expiration date is in the past
    return expirationDate < currentDate;
  }

  function onContinue() {
    // Business account with KYC not approved
    if (isAuthenticated && isShowClientBlockingModal) {
      return clientBlockingModal();
    }

    if (isStringEmpty(countryAndCurrency.country)) {
      return setQuotationError(t('calculator:choose_country'));
    }

    // Non-business account with KYC Abad not approved
    if (isShowPersonalBlockingModal) {
      return renderBlockingDialogKycAbad(me.data);
    }

    // Continue with form submission if there are no quotation errors
    if (isStringEmpty(quotationError)) {
      handleSubmit(onSubmit)();
      setHasChangedDestinationAmount(false);
      setIsInvoiceChecked(false);
      localStorage.removeItem(PAYER_ROUTING_CHANNEL);
    }
  }

  function refetchLiveRate() {
    const canGetLiveRate =
      amount &&
      serviceId !== -1 &&
      destinationCountry &&
      destinationCurrency &&
      !isEmptyObject(serviceDetail);

    if (canGetLiveRate) {
      refetchGetLiveRate();
      refetchGetRatesHistory();
    }
  }

  function refetchQuotation(
    _serviceId?: number,
    _routingChannel?: RoutingChannelType,
    _amount?: number,
  ) {
    const _values = {
      ...watch(),
      amountSend,
      amountReceive,
      routingChannel: routingChannelType,
    };

    const prevDiff =
      !isDeepEqual(prevValues.current, _values) || prevLang !== lang;
    const canGetQuotation =
      canGetServices &&
      serviceDetail &&
      (prevDiff || !isEmptyObject(prevQuotationFormValues)) &&
      !isAppliedDealCode;

    if (canGetQuotation && isValidAccount) {
      mutateQuotation({
        isSendAmount,
        destinationCountry,
        destinationCurrency,
        amount: _amount || amount,
        serviceId: _serviceId || serviceId,
        routingChannel: _routingChannel || routingChannelType,
      });
    }
  }

  function trackViewCountryCurrency(country: string, currency: string) {
    const prev_country = prevCountryAndCurrency.current?.country || '';
    const prev_currency = prevCountryAndCurrency.current?.currency || '';
    const payload = {
      country,
      currency,
      prev_country,
      prev_currency,
    };

    trackerPersonalBusiness?.viewCountryCurrency(payload);

    if (
      prevCountryAndCurrency.current?.country !== countryAndCurrency.country
    ) {
      prevCountryAndCurrency.current = countryAndCurrency;
    }
  }

  function onCloseRecipientModal() {
    setStep(ChooseRecipientStep.CHOOSE);
  }

  function onCloseServiceDialog() {
    closeDialog();
    setCanShowServiceModal(false);
    if (
      prevModalServiceOrder.current !== undefined &&
      modalServiceOrder !== prevModalServiceOrder.current
    ) {
      setModalServiceOrder(prevModalServiceOrder.current);
    }
    if (
      prevModalRoutingChannelOrder.current !== undefined &&
      routingChannelOrder !== prevModalRoutingChannelOrder.current
    ) {
      setModalRoutingChannelOrder(prevModalRoutingChannelOrder.current);
    }

    prevModalServiceOrder.current = undefined;
    prevModalRoutingChannelOrder.current = undefined;
  }

  function onClickOtherDetails(data: QuotationNoteModel[]) {
    const notes = data?.[data.length - 1].content;

    if (notes) {
      showDialog({
        body: <QuotationOtherDetailsModalBody notes={notes} />,
        onClose: () => closeDialog(),
      });
    }
  }

  useDebounce(
    () => {
      if (
        serviceId === -1 &&
        !isStringEmpty(destinationCountry) &&
        !isStringEmpty(destinationCurrency)
      ) {
        refetchServices();
        return;
      }

      const isAmountReceiveChanged = amountReceive !== prevAmountReceive;
      const isAmountSendChanged = amountSend !== prevAmountSend;

      /**
       * @note if the corridor has multiplier it will change both
       * so we prefent it to refetch the rate again
       */
      if (isAmountReceiveChanged && isAmountSendChanged) {
        return;
      }

      const _canRefetchLiveRate =
        ((isAmountReceiveChanged && !isSendAmount) ||
          (isAmountSendChanged && isSendAmount)) &&
        !isAppliedDealCode;

      if (_canRefetchLiveRate) {
        refetchLiveRate();
        setIsErrorChooseService(false);
        setIsErrorChooseRecipient(false);
        setQuotationError(undefined);
      }
    },
    [amountReceive, amountSend],
    1500,
  );

  useEffect(() => {
    if (
      !isEmptyObject(prevCountryAndCurrency.current?.country) &&
      countryAndCurrency.country !== prevCountryAndCurrency.current?.country
    ) {
      prevCountryAndCurrency.current = countryAndCurrency;

      queryClient.removeQueries(['available-recipients', 'personal']);
      queryClient.removeQueries(['available-recipients', 'business']);

      dispatchGlobalState({
        type: 'REMOVE',
        key: RECIPIENT_FORM_VALUES,
      });
    }
  }, [countryAndCurrency]);

  useEffect(() => {
    if (canGetServices || isAppliedDealCode) {
      refetchServices();
    }

    queryClient.resetQueries('corridor-announcements');
  }, [destinationCountry, destinationCurrency, isAppliedDealCode, lang]);

  useEffect(() => {
    if (canRefetchLiveRate) {
      refetchLiveRate();
      setCanRefetchLiveRate(false);
    }
  }, [canRefetchLiveRate]);

  useEffect(() => {
    function updatePrevQuotation() {
      if (quotation?.data && (isLandingPage || isSendMoneyPage)) {
        storePrevQuotationFormValues({
          serviceId,
          amountSend,
          amountReceive,
          isSendAmount,
          countryAndCurrency,
          destinationCountry,
          destinationCurrency,
          routingChannel: routingChannelType,
        });
        return;
      }

      if (quotation?.data && (!isLandingPage || !isSendMoneyPage)) {
        emptyPrevQuotationFormValues();
      }
    }
    function trackViewItemEvent() {
      // analytic: track when unique product get views
      if (!quotation?.data || isStringEmpty(destinationCountry)) {
        return;
      }

      const corridor =
        `${serviceDetail?.name} - ${destinationCountry} - ${routingChannelType}` as string;

      if (corridor !== prevCorridor.current) {
        trackerPersonalBusiness?.viewItem({
          item_name: corridor,
          prev_item_name: prevCorridor.current,
          item_id: quotation.data.referenceId,
          item_type: TransactionType.REMIT,
        });
      }

      prevCorridor.current = corridor;
    }

    updatePrevQuotation();
    trackViewItemEvent();
  }, [quotation?.data?.referenceId]);

  useEffect(() => {
    setIsNoteShown(false);
    function AddEventListenerOtherDetail() {
      const otherDetails = document.querySelector('#other-details');
      if (otherDetails) {
        setIsNoteShown(true);
      } else {
        setTimeout(() => AddEventListenerOtherDetail(), 1000);
      }
    }
    if (!quotation?.data && !dealRateData) {
      return;
    }
    AddEventListenerOtherDetail();
  }, [quotation?.data, canShowQuotationInfo, dealRateData]);

  useEffect(() => {
    const otherDetails = document.querySelector('#other-details');
    if (isNoteShown && otherDetails) {
      quotation?.data &&
        otherDetails.addEventListener('click', () =>
          onClickOtherDetails(quotation?.data.quotationNote),
        );
      dealRateData &&
        otherDetails.addEventListener('click', () =>
          onClickOtherDetails(dealRateData.quotationNote),
        );
    }
  }, [isNoteShown]);

  useEffect(() => {
    if (!isEmptyObject(services?.data)) {
      const selectedService = services?.data?.[serviceOrder];
      if (selectedService) {
        setServiceDetail({
          ...selectedService,
          routingChannel: selectedService?.routingChannels[0],
        });
      }
    }
  }, [serviceOrder]);

  useEffect(() => {
    if (!isEmptyObject(services?.data)) {
      const { routingChannels } = services?.data?.[serviceOrder] || {};
      const routingChannel = routingChannels?.[routingChannelOrder];
      if (routingChannel) {
        setServiceDetail((prev: ServiceDetailModel) => ({
          ...prev,
          routingChannel,
        }));
      }
    }
  }, [routingChannelOrder]);

  useEffect(() => {
    if (!isStringEmpty(countryAndCurrency)) {
      const country = countryAndCurrency.country || '';
      const currency = countryAndCurrency.currency || '';

      if (isStringEmpty(amount)) {
        setValue('isSendAmount', true);
        setValue('amountSend', DEFAULT_AMOUNT_SEND);
      }

      if (!hasJustChangedAmount.current && amountSend === 0) {
        setValue('amountSend', 0);
      }

      setPayerId(null);
      setRecipient(null);

      if (initialCountryCurrency.country) {
        setHasChooseService(false);
        setQuotationError(undefined);
        setIsErrorChooseService(false);
        setIsErrorChooseRecipient(false);
      }

      setValue('destinationCountry', country);
      setValue('destinationCurrency', currency);

      // reset mutation value since we always show existing live quotation rate
      resetMutateQuotation();

      trackViewCountryCurrency(country, currency);
    }

    onCloseRecipientModal();
  }, [countryAndCurrency.country, countryAndCurrency.currency]);

  useEffect(() => {
    if (!isEmptyObject(quotation?.data)) {
      if (
        !isStringEmpty(destinationCountry) &&
        !isStringEmpty(destinationCurrency)
      ) {
        refetchServices();
      }
      setQuotationError(undefined);
      refetchCountriesCurrencies();
      refetchQuotation();
      refetchCorridorNote();
    }
  }, [lang]);

  useEffect(() => {
    if (serviceOrder !== 0 || modalServiceOrder !== -1) {
      // only reset recipient when service order changed from default
      setRecipient(null);
    }
  }, [serviceOrder, modalServiceOrder]);

  useEffect(() => {
    const payerRoutingChannel = JSON.parse(
      localStorage.getItem(PAYER_ROUTING_CHANNEL) as string,
    );

    if (!isLimitExceed) {
      setQuotationError(undefined);
    }

    if (payerRoutingChannel) {
      let payerLimit = '';

      if (payerRoutingChannel.length === 1) {
        payerLimit = payerRoutingChannel[0].limit;
      } else {
        payerRoutingChannel.forEach(({ type, limit }) => {
          if (type === routingChannelType) {
            payerLimit = limit;
          }
        });
      }

      if (amountReceive > Number(payerLimit) && routingChannelType) {
        setQuotationError(
          t('calculator:error_message.limit_exceed', {
            currency: destinationCurrency,
            amount: new Intl.NumberFormat('en-US').format(Number(payerLimit)),
          }),
        );
      }
    }

    localStorage.removeItem(PAYER_ROUTING_CHANNEL_TYPE);
  }, [isLimitExceed, routingChannelType]);

  useEffect(() => {
    if (hasChooseService && isErrorChooseService) {
      setIsErrorChooseService(false);
    }
  }, [hasChooseService]);

  useEffect(() => {
    if (changedAmount) {
      const _serviceId = changedQuotationAmount.serviceId;
      const _routingChannel = changedQuotationAmount.routingChannel;
      const _amountReceive = changedQuotationAmount.amountReceive;
      refetchQuotation(_serviceId, _routingChannel, _amountReceive);
      setHasChangedDestinationAmount(true);
    }
    return () => {
      setChangedAmount(false);
      dispatchGlobalState({ type: 'REMOVE', key: 'changed-quotation-amount' });
    };
  }, [changedAmount]);

  useEffect(() => {
    const selectedService = services?.data?.[serviceOrder];
    dispatchGlobalState({
      type: 'ADD',
      payload: selectedService?.routingChannels,
      key: SELECTED_SERVICE_DETAIL,
    });
  }, [serviceId, services]);

  function onClickBtnApplyService() {
    const { id: serviceId, routingChannels } =
      services?.data[modalServiceOrder] || {};
    const routingChannel = routingChannels?.[modalRoutingChannelOrder].type;

    closeDialog();
    setRecipient(null);
    setHasChooseService(true);
    setQuotationError(undefined);
    setCanShowServiceModal(false);
    setIsErrorChooseRecipient(false);
    setServiceOrder(modalServiceOrder);
    setRoutingChannelOrder(modalRoutingChannelOrder);
    setValue('serviceId', serviceId);
    refetchQuotation(serviceId, routingChannel);
    if (!isAuthenticated) {
      setCanRefetchLiveRate(true);
    }
    setTimeout(() => {
      refetchCorridorNote();
    }, 50);
  }

  useShallowEffect(() => {
    const countryCurrency = countriesCurrencies?.data.find(
      ({ country }) => country === DEFAULT_COUNTRY,
    );
    const { country: destinationCountry, currencies } = countryCurrency || {};
    const destinationCurrency = currencies?.[0];
    const countryAndCurrency = {
      country: destinationCountry,
      currency: destinationCurrency,
    };

    if (isEmptyObject(countriesCurrencies)) {
      refetchCountriesCurrencies();
    }

    if (destinationCountry && destinationCurrency) {
      reset({
        ...watch(),
        destinationCountry,
        destinationCurrency,
        countryAndCurrency,
      });
    }
  }, [countriesCurrencies, DEFAULT_COUNTRY]);

  useShallowEffect(() => {
    dispatchGlobalState({
      type: 'ADD',
      payload: watch(),
      key: 'quotation-form',
    });
  }, [watch()]);

  useShallowEffect(() => {
    const isPayerLimitExisting = localStorage.getItem(PAYER_ROUTING_CHANNEL);
    if (!recipient && isPayerLimitExisting) {
      localStorage.removeItem(PAYER_ROUTING_CHANNEL);
    }
    if (isErrorChooseRecipient && !isEmptyObject(recipient)) {
      setIsErrorChooseRecipient(false);
    }
  }, [isErrorChooseRecipient, recipient]);

  useShallowEffect(() => {
    const speed = localStorage.getItem(PAYER_ROUTING_CHANNEL_TYPE);

    if (!isEmptyObject(services?.data) && speed) {
      const routingChannelOrder = RoutingChannelOrder[speed];
      const routingChannel = services?.data[serviceOrder]?.routingChannels[
        routingChannelOrder
      ] as RoutingChannelModel;

      setRoutingChannelOrder(routingChannelOrder);
      setModalRoutingChannelOrder(routingChannelOrder);
      setServiceDetail((prev: ServiceDetailModel) => ({
        ...prev,
        routingChannel,
      }));
      refetchQuotation(serviceId, routingChannel.type);
    }
  }, [hasPayerSpeed, services?.data]);

  useShallowEffect(() => {
    if (!services?.data || !canShowServiceModal) {
      return;
    }

    const modalSelectedService =
      services?.data[modalServiceOrder]?.routingChannels[
        modalRoutingChannelOrder
      ];

    showDialog({
      body: (
        <ChooseServiceModalBody
          services={services.data}
          hasChooseService={hasChooseService}
          serviceOrder={serviceOrder}
          routingChannelOrder={routingChannelOrder}
          modalServiceOrderState={[modalServiceOrder, setModalServiceOrder]}
          isFirstTransactionFree={services.meta?.isFirstTransactionFree}
          modalRoutingChannelOrderState={[
            modalRoutingChannelOrder,
            setModalRoutingChannelOrder,
          ]}
        />
      ),
      footer: (
        <ChooseServiceModalFooter
          amountSend={amountSend}
          fee={modalSelectedService?.fee || 0}
          discountValue={modalSelectedService?.discount?.value || 0}
          discountType={modalSelectedService?.discount?.discountType}
          validUntil={validateDate(modalSelectedService?.discount?.validUntil)}
          correspondentFee={modalSelectedService?.correspondentFee || 0}
          discountMinimumAmount={
            modalSelectedService?.discount?.minimumAmount || 0
          }
          onClickBtnApplyService={onClickBtnApplyService}
        />
      ),
      onClose: onCloseServiceDialog,
      fullHeight: true,
    });
  }, [
    services?.data,
    quotation?.data.correspondentFee,
    isMobileSize,
    hasChooseService,
    serviceOrder,
    routingChannelOrder,
    canShowServiceModal,
    modalServiceOrder,
    modalRoutingChannelOrder,
  ]);

  useEffect(() => {
    function handleBackNavigation() {
      const {
        destinationAmount,
        destinationCountry,
        destinationCurrency,
        isSendAmount,
        sourceAmount,
      } = prevQuotation;

      setValue('countryAndCurrency', {
        country: destinationCountry,
        currency: destinationCurrency,
      });
      setValue('destinationCountry', destinationCountry);
      setValue('destinationCurrency', destinationCurrency);

      if (isSendAmount) {
        setValue('amountSend', sourceAmount);
      } else {
        setValue('amountReceive', destinationAmount);
      }

      setCanRefetchLiveRate(true);
    }

    function handleCountryAndCurrencyQuery() {
      if (prevQuotationFormValues) {
        emptyPrevQuotationFormValues();
        return;
      }

      setValue('countryAndCurrency', {
        country: router.query.country as string,
        currency: router.query.currency as string,
      });
      setValue('destinationCountry', router.query.country as string);
      setValue('destinationCurrency', router.query.currency as string);
    }

    function handleServiceAndRoutingQuery() {
      if (prevQuotationFormValues) {
        emptyPrevQuotationFormValues();
        return;
      }

      setValue('serviceId', Number(router.query.service));
      setValue(
        'routingChannel',
        router.query.routingChannel as unknown as RoutingChannelType,
      );
    }

    if (router.query.isBack === 'true' && prevQuotation) {
      handleBackNavigation();
      return;
    }

    if (router.query.country && router.query.currency) {
      handleCountryAndCurrencyQuery();
    }

    if (
      router.query.service &&
      Object.values(RoutingChannelType).includes(
        router.query.routingChannel as RoutingChannelType,
      )
    ) {
      handleServiceAndRoutingQuery();
    }
  }, [router.isReady, prevQuotationFormValues]);

  useEffect(() => {
    if (router.query.recipient && isValidAccount) {
      setSelectedRecipientId(Number(router.query.recipient));
    }
  }, [router.isReady, isValidAccount]);

  return (
    <FormProvider {...methods}>
      <div className={styled.root}>
        {hasDealingCode && (
          <DealingRateField
            isAppliedDealCode={isAppliedDealCode}
            status={dealingCodeStatus}
            refetchDealingCode={refetchDealingCode}
          />
        )}
        {me && <DomesticNewTabCoachMark />}
        <CalculatorAmountField
          destinationCountry={DEFAULT_COUNTRY}
          isDisabled={isDisabledAmountField}
          errorMessage={quotationError}
          // quickfix : this to fix isSendAmoutn get the wrong value replaced when sendAMount change value
          onFocusAmountSend={() => onChangeCurrencyField(true)}
          onFocusAmountReceive={() => onChangeCurrencyField(false)}
        />
        <SkeletonWrapper
          count={quotationInfoSkeletonCount}
          loading={isLoadingQuotationInfo}
          wrapperClassname={styled.quotationInfoSkeleton}
        >
          {canShowQuotationInfo && (
            <QuotationInfo
              rate={rate || ''}
              quotation={quotation?.data}
              liveRate={liveRate?.data}
              isVerifiedKyc={isVerifiedKyc}
              hasChooseService={hasChooseService}
              isDisabled={isDisabledQuotationInfo}
              isErrorChooseServiceDelivery={isErrorChooseService}
              isErrorChooseRecipient={isErrorChooseRecipient}
              serviceDetail={serviceDetail}
              onClickServiceCard={onClickServiceCard}
              onClickRecipientCard={onClickRecipientCard}
              selectedRoutingChannel={routingChannelType}
              {...(isAppliedDealCode && { dealRateQuotation: dealRateData })}
            />
          )}
        </SkeletonWrapper>
        {targetRef?.current &&
          (createPortal(
            <Button
              fullWidth
              size="large"
              disabled={isDisabledBtnContinue}
              isLoading={isLoadingBtnContinue}
              onClick={onContinue}
            >
              {t('continue')}
            </Button>,
            targetRef.current,
          ) as ReactNode)}
      </div>
    </FormProvider>
  );
}

export default CalculatorRemit;
