import { ISelectedRecipient } from '@api-hooks/domestic';
import { DiscountedFeePillsContent } from '@elements';
import { ServiceModel } from '@topremit/shared-web/api-hooks/common';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { formatToIDRCurrency } from '@topremit/shared-web/common/helper';
import { ChangeDeliverySpeedModalBody } from '@topremit/shared-web/components/elements';
import {
  CreditCardFill,
  CreditCardOutline,
  InvestmentFill,
  InvestmentOutline,
  ShopFill,
  ShopOutline,
  TransactionFill,
  TransactionOutline,
} from '@topremit/shared-web/components/shapes';
import { TranslateFunction } from '@topremit/shared-web/hooks/use-translation';
import * as yup from 'yup';
export interface IPurposeOption {
  id: number;
  name: string;
  index: number;
  iconActive: JSX.Element;
  icon: JSX.Element | string;
  borderColor: string;
  borderColorActive: string;
  backgroundColorActive: string;
  backgroundColor: string;
}

const iconSettings = {
  shop: {
    backgroundColorActive: '#F4FAFF',
    borderColorActive: 'var(--blue-500)',
    icon: <ShopOutline fill="var(--blue-500)" />,
    iconActive: <ShopFill fill="var(--blue-500)" />,
  },
  investment: {
    backgroundColorActive: '#FFF9F5',
    borderColorActive: 'var(--orange-500)',
    icon: <InvestmentOutline fill="var(--orange-500)" />,
    iconActive: <InvestmentFill fill="var(--orange-500)" />,
  },
  creditcard: {
    backgroundColorActive: '#E9FCF8',
    borderColorActive: 'var(--green-500)',
    icon: <CreditCardOutline fill="var(--green-500)" />,
    iconActive: <CreditCardFill fill="var(--green-500)" />,
  },
  transaction: {
    backgroundColorActive: '#FFF8E1',
    borderColorActive: 'var(--yellow-500)',
    icon: (
      <TransactionOutline width={20} height={20} fill="var(--yellow-500)" />
    ),
    iconActive: (
      <TransactionFill width={20} height={20} fill="var(--yellow-500)" />
    ),
  },
};

export function generatePurposeOptions(purposes) {
  if (!Array.isArray(purposes)) {
    return [];
  }

  return purposes.map((purpose, idx) => {
    const defaultSettings = {
      backgroundColor: 'var(--neutral-0)',
      borderColor: 'var(--neutral-200)',
    };

    const iconConfig = iconSettings[purpose.icon] || {};

    return {
      index: idx,
      id: purpose.id,
      name: purpose.name,
      ...defaultSettings,
      ...iconConfig,
    };
  });
}

export function getMinMaxVolumeDeliverySpeed(
  servicesData: ServiceModel[] | undefined,
) {
  const volumes = servicesData?.flatMap((service) =>
    service.routingChannels.map((channel) => ({
      id: service.id,
      name: service.name,
      type: channel.type,
      minVolume: channel.minVolume,
      maxVolume: channel.maxVolume,
    })),
  );

  // Get the minVolume and maxVolume
  const minVolumeDeliverySpeed = volumes?.reduce((min, current) =>
    current.minVolume < min.minVolume ? current : min,
  );
  const maxVolumeDeliverySpeed = volumes?.reduce((max, current) =>
    current.maxVolume > max.maxVolume ? current : max,
  );

  return { minVolumeDeliverySpeed, maxVolumeDeliverySpeed };
}

export function popUpDeliverySpeedChangedAutomatically({
  t,
  popDialog,
  showDialog,
  LLGService,
  BIFastService,
}: {
  t: TranslateFunction<'common'>;
  popDialog: () => void;
  showDialog: (IDialogProps) => void;
  LLGService: ServiceModel | undefined;
  BIFastService: ServiceModel | undefined;
}) {
  const BIFastMaxVolume = BIFastService?.routingChannels[0].maxVolume;
  showDialog({
    body: (
      <ChangeDeliverySpeedModalBody
        btnOkLabel={t('okay_got_it')}
        onOk={popDialog}
        title={t('calculator:conflict_modal.speed_changed.title')}
        description={t(
          'calculator:conflict_modal.speed_changed.domestic_description',
          {
            max: formatToIDRCurrency(Number(BIFastMaxVolume ?? 0)),
            speed: LLGService?.routingChannels[0]?.label,
          },
        )}
        currentFee={
          <DiscountedFeePillsContent
            normalFee={BIFastService?.routingChannels[0]?.fee ?? 0}
            discountFeeValue={
              BIFastService?.routingChannels[0]?.discount?.value ?? 0
            }
          />
        }
        currentSpeed={BIFastService?.routingChannels[0]?.label}
        currentSpeedTitle={t('transaction:domestic.current_speed')}
        currentSpeedDescription={
          BIFastService?.routingChannels[0]?.etaDescription
        }
        suggestedFee={
          <DiscountedFeePillsContent
            normalFee={LLGService?.routingChannels[0]?.fee ?? 0}
            discountFeeValue={
              LLGService?.routingChannels[0]?.discount?.value ?? 0
            }
          />
        }
        suggestedSpeed={LLGService?.routingChannels[0]?.label}
        suggestedSpeedTitle={t('transaction:domestic.change_to')}
        suggestedSpeedDescription={
          LLGService?.routingChannels[0]?.etaDescription
        }
      />
    ),
    onClose: popDialog,
  });
}

// General function to get ID based on delivery speed
type IdType = 'recipientId' | 'payerId';

export function getIdByDeliverySpeed({
  recipient,
  routingChannel,
  services,
  idType,
}: {
  recipient: ISelectedRecipient | null;
  routingChannel: RoutingChannelType | undefined;
  services: ServiceModel[] | undefined;
  idType: IdType;
}) {
  const selectedRoutingChannel = recipient?.routingChannels?.find(
    (r) => r.type === routingChannel,
  );

  if (Number(services?.length) > 1) {
    return idType === 'recipientId'
      ? selectedRoutingChannel?.recipientId
      : selectedRoutingChannel?.payerId;
  }

  return idType === 'recipientId' ? recipient?.id : recipient?.payerId;
}

export function getValidationSchema({
  t,
  isBulk,
  minLimitAmount,
  maxLimitAmount,
  amountErrorMessage,
}: {
  isBulk: boolean;
  minLimitAmount: number;
  maxLimitAmount: number;
  amountErrorMessage: string;
  t: TranslateFunction<'transaction'>;
}) {
  return yup.object().shape({
    ...(isBulk && { amount: undefined }),
    ...(!isBulk && {
      amount: yup
        .number()
        .typeError(t('error:field.required'))
        .min(minLimitAmount, amountErrorMessage)
        .max(maxLimitAmount, amountErrorMessage)
        .required(t('error:field.required')),
    }),
  });
}
