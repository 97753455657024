import { useGetPayers } from '@api-hooks/domestic';
import { AnimatedHeightWrapper, SearchNotFound } from '@elements';
import { css, cx } from '@emotion/css';
import { TransactionMode } from '@topremit/shared-web/api-hooks/common';
import { PayersModel } from '@topremit/shared-web/api-hooks/transaction';
import { screenSize } from '@topremit/shared-web/common/size';
import {
  Button,
  ContainerSpinner,
  Flex,
  ListMenu,
  Notes,
} from '@topremit/shared-web/components/elements';
import { DelayFill } from '@topremit/shared-web/components/shapes';
import { useNotification, useTranslation } from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';
import { color } from '@topremit/shared-web/styles/color';
import { Searchbar } from '@topremit-ui/searchbar';
import { useMemo, useState } from 'react';

import { BI_FAST_ID, COUNTRY_IDN, CURRENCY_IDR } from '../constants';
import BankMaintenancePill from './BankMaintenancePill';
import BankSpeedDescription from './BankSpeedDescription';
import BankWithLogo from './BankWithLogo';

interface SelectBankModalBodyProps {
  showSpeedPills: boolean;
  /**
   * @default true
   */
  showMaintenancePills?: boolean;
  value: PayersModel | undefined;
  transactionType: TransactionMode;
  onValueChange: (value: PayersModel | undefined) => void;
}

export default function SelectBankModalBody({
  value,
  transactionType,
  showSpeedPills,
  showMaintenancePills = true,
  onValueChange,
}: SelectBankModalBodyProps) {
  const { t } = useTranslation();

  const { addNotification } = useNotification();

  const popDialog = useDialogStore((store) => store.pop);

  const [search, setSearch] = useState('');
  const [selectedBankId, setSelectedBankId] = useState<
    PayersModel['id'] | null
  >(value?.id ?? null);

  const { data: payers, isFetching: isFetchingPayers } = useGetPayers(
    {
      url: 'payers',
      transactionType,
      serviceId: BI_FAST_ID,
      destinationCountry: COUNTRY_IDN,
      destinationCurrency: CURRENCY_IDR,
    },
    {
      queryKey: [transactionType],
    },
  );

  const filteredBanks = useMemo(() => {
    if (!payers) return [];
    return payers?.data.filter((bank) => {
      return bank.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [payers, search]);

  const selectedBank = useMemo(
    () => payers?.data.find((bank) => bank.id === selectedBankId),
    [selectedBankId, payers],
  );

  const isSelectedBankMaintenance = useMemo(() => {
    return selectedBank?.routingChannels.some(
      (channel) => channel.isMaintenance,
    );
  }, [selectedBank]);

  function handleChangeBank(bank?: PayersModel) {
    onValueChange(bank);
    popDialog();
  }

  function handleClickContinue() {
    if (selectedBank) {
      handleChangeBank(selectedBank);
      return;
    }

    addNotification({
      message: t('select_bank_first'),
      type: 'danger',
    });
  }

  return (
    <div className={cx(styled.root)}>
      <AnimatedHeightWrapper>
        <h5>{t('transaction:domestic.recipient.select_bank')}</h5>
        <Searchbar
          value={search}
          placeholder={t('search_bank_name')}
          onChange={(value) => setSearch(value)}
        />
        {isSelectedBankMaintenance && showMaintenancePills && (
          <Notes
            showIcon
            status="warning"
            className={styled.maintenanceNotes}
            icon={<DelayFill width={24} height={24} />}
            title={t('payers_maintenance_notes')}
          />
        )}
        <div className="bank-list-wrapper">
          {isFetchingPayers ? (
            <ContainerSpinner />
          ) : filteredBanks.length !== 0 ? (
            filteredBanks?.map((bank, idx) => {
              const showMaintenancePill =
                showMaintenancePills &&
                bank.routingChannels.length === 1 &&
                bank.routingChannels[0].isMaintenance;
              return (
                <ListMenu
                  hasDivider={idx !== filteredBanks.length - 1}
                  type="radio"
                  key={bank.id}
                  className={styled.bankListItem}
                  title={<BankWithLogo bank={bank} />}
                  description={
                    showSpeedPills ? (
                      <BankSpeedDescription
                        routingChannels={bank.routingChannels}
                      />
                    ) : showMaintenancePill ? (
                      <div className="pills-wrapper">
                        <BankMaintenancePill />
                      </div>
                    ) : null
                  }
                  isSelected={bank.id === selectedBankId}
                  onClick={() => setSelectedBankId(bank.id)}
                />
              );
            })
          ) : (
            <SearchNotFound />
          )}
        </div>
      </AnimatedHeightWrapper>
      <Flex className={styled.footer}>
        <Button fullWidth onClick={handleClickContinue}>
          {t('continue')}
        </Button>
      </Flex>
    </div>
  );
}

const styled = {
  root: css`
    .search-bank {
      margin: 16px 0;
    }
    .bank-list-wrapper {
      height: calc(75vh - 12rem);
      min-height: 170px;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      margin-top: 0.25rem;
      padding-bottom: 0.5rem;
      .pills-wrapper {
        display: flex;
        margin-top: 0.875rem;
        gap: 0.5rem;
        .ant-tag {
          height: 1.5625rem;
        }
      }
      .bank-with-logo {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        .bank-image {
          min-width: 2rem;
          min-height: 2rem;
          border-radius: 100%;
          background-color: var(--neutral-0);
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .bank-list-item-title {
          @media (max-width: ${screenSize.tabletSm}px) {
            font-size: var(--sm-font-size);
          }
        }
      }
    }
  `,
  bankListItem: css`
    padding: 1rem 0;
  `,
  maintenanceNotes: css`
    margin-bottom: 0.5rem;
  `,
  footer: css`
    background: ${color.neutral0};
    padding-top: 16px;
  `,
};
