import { IGetPurposeResponse, ISelectedPurpose } from '@api-hooks/domestic';
import { css, cx } from '@emotion/css';
import { generatePurposeOptions, PURPOSE_ID_KEY } from '@modules';
import { screenSize } from '@topremit/shared-web/common/size';
import SkeletonWrapper from '@topremit/shared-web/components/elements/SkeletonWrapper';
import { useTranslation } from '@topremit/shared-web/hooks';
import { ReactNode, useEffect, useMemo, useState } from 'react';

interface TransactionPurposeProps {
  className?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  options?: IGetPurposeResponse[];
  onValueChange: (value: ISelectedPurpose) => void;
}

function PurposeItem({
  icon,
  name,
  isActive = false,
  borderColor = 'var(--neutral-200)',
  backgroundColor = 'var(--neutral-0)',
  onClick,
  isDisabled = false,
}: {
  icon: ReactNode;
  isActive?: boolean;
  name: string;
  borderColor?: string;
  backgroundColor?: string;
  onClick?: () => void;
  isDisabled?: boolean;
}) {
  return (
    <div
      className={styled.purposeItem(isActive, borderColor, backgroundColor)}
      {...(!isDisabled && { onClick })}
    >
      <div className="icon-wrapper">{icon}</div>
      <span className="description">{name}</span>
    </div>
  );
}

export default function TransactionPurpose({
  className,
  options = [],
  isLoading = false,
  isDisabled = false,
  onValueChange,
}: TransactionPurposeProps) {
  const { t } = useTranslation('transaction');

  const [selectedPurposeIdx, setSelectedPurposeIdx] = useState(0);

  const purposeOptions = useMemo(
    () => generatePurposeOptions(options),
    [options],
  );
  const selectedPurpose = purposeOptions[selectedPurposeIdx];

  function handleChoosePurpose(id: string, name: string, index: number) {
    localStorage.setItem(PURPOSE_ID_KEY, String(id));
    onValueChange({
      id: Number(id),
      name,
    });
    setSelectedPurposeIdx(index);
  }

  useEffect(() => {
    if (selectedPurpose?.id) {
      localStorage.setItem(PURPOSE_ID_KEY, String(selectedPurpose.id));
    }
  }, [selectedPurpose]);

  useEffect(() => {
    if (purposeOptions.length > 0) {
      onValueChange(
        (purposeOptions[selectedPurposeIdx] as ISelectedPurpose) || {},
      );
    }
  }, [purposeOptions]);

  return (
    <div className={cx(styled.root, 'transaction-purpose', className)}>
      <p className="title">{t('domestic.transaction_purpose')}</p>
      {isLoading ? (
        <SkeletonWrapper className={styled.skeleton} />
      ) : (
        <div className="purposes-wrapper">
          {purposeOptions.map(
            ({
              id,
              icon,
              name,
              index,
              iconActive,
              borderColor,
              backgroundColor,
              borderColorActive,
              backgroundColorActive,
            }) => {
              const activePurpose =
                index === selectedPurposeIdx && id === selectedPurpose?.id;
              return (
                <PurposeItem
                  key={id}
                  name={name}
                  isActive={activePurpose}
                  icon={activePurpose ? iconActive : icon}
                  borderColor={activePurpose ? borderColorActive : borderColor}
                  backgroundColor={
                    activePurpose ? backgroundColorActive : backgroundColor
                  }
                  onClick={() => handleChoosePurpose(String(id), name, index)}
                  isDisabled={isDisabled}
                />
              );
            },
          )}
        </div>
      )}
    </div>
  );
}

const styled = {
  root: css`
    cursor: pointer;
    .title {
      margin-top: 0;
      margin-bottom: 1.5rem;
      font-weight: var(--bold-font-weight);
      @media (max-width: ${screenSize.tabletSm}px) {
        margin-bottom: 1rem;
        font-size: var(--sm-font-size);
      }
    }
    .purposes-wrapper {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  `,
  purposeItem: (
    isActive: boolean,
    borderColor: string,
    backgroundColor: string,
  ) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon-wrapper {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 100%;
      background-color: ${isActive ? backgroundColor : 'var(--neutral-0)'};
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      border: 1px solid ${isActive ? borderColor : 'var(--neutral-200)'};
      animation: ${isActive ? 'pulse 1.5s infinite' : ''};
      svg {
        opacity: ${isActive ? 1 : 0.6};
      }
    }
    .description {
      text-align: center;
      font-size: var(--sm-font-size);
      font-weight: ${isActive
        ? 'var(--bold-font-weight)'
        : 'var(--regular-font-weight)'};
      @media (max-width: ${screenSize.tabletSm}px) {
        font-size: var(--xs-font-size);
      }
    }
    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.1);
      }
      100% {
        transform: scale(1);
      }
    }
  `,
  skeleton: css`
    min-height: 5rem;
  `,
};
