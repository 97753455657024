import { css } from '@emotion/css';
import { ChooseRecipientStep, useRecipient } from '@hooks';
import { RoutingChannelType } from '@topremit/shared-web/api-hooks/transaction';
import { Button } from '@topremit/shared-web/components/elements';
import {
  useGlobalState,
  useNotification,
  useTranslation,
} from '@topremit/shared-web/hooks';
import { useDialogStore } from '@topremit/shared-web/stores';

import { RecipientModal } from '../..';
import SpeedInfoCard from './SpeedInfoCard';

interface IDeliverySpeedChangeConfirmationModalBodyProps {
  channel: RoutingChannelType;
  setFieldValue: (field: string, value: any) => void;
  selectedRoutingChannel: RoutingChannelType;
}

const PREV_STEP = 'PREV_STEP';
const RECIPIENT_FORM_VALUES = 'RECIPIENT_FORM_VALUES';
const PAYER_ROUTING_CHANNEL_TYPE = 'PAYER_ROUTING_CHANNEL_TYPE';

export default function SwitchSpeedModal({
  channel,
  setFieldValue,
  selectedRoutingChannel,
}: IDeliverySpeedChangeConfirmationModalBodyProps) {
  const { t } = useTranslation('calculator');
  const { addNotification } = useNotification();
  const pushDialog = useDialogStore((store) => store.push);
  const popDialog = useDialogStore((store) => store.pop);
  const [globalState, dispatchGlobalState] = useGlobalState();
  const {
    recipient,
    quotation,
    setStep,
    setRecipient,
    setHasPayerSpeed,
    setIsValidatingBankCode,
  } = useRecipient();

  const recipientFormValues = globalState.get(RECIPIENT_FORM_VALUES);
  const prevStep = localStorage.getItem(PREV_STEP);

  const { creditParty } = recipientFormValues || {};

  const isInstant = quotation.routingChannel === RoutingChannelType.INSTANT;

  const changeButton = generateTranslation(
    'action_button.change_delivery_speed',
  );
  const cancelButton = generateTranslation('action_button.enter_another_code');

  const speed = t(
    `conflict_modal.routing_channel_type.${isInstant ? 'instant' : 'regular'}`,
  );
  const suggestedSpeed = t(
    `conflict_modal.routing_channel_type.${isInstant ? 'regular' : 'instant'}`,
  );

  const description = generateTranslation(
    'description_with_channel',
    suggestedSpeed,
  );
  const title = generateTranslation('title_with_channel', speed);

  function generateTranslation(key, speed = {}) {
    return t(`conflict_modal.change_speed_confirmation.${key}`, { speed });
  }

  function handleChangeDeliverySpeed() {
    pushDialog({
      body: <RecipientModal selectedRoutingChannel={selectedRoutingChannel} />,
    });

    addNotification({
      message: t('conflict_modal.speed_changed.speed_changed_toast'),
      type: 'success',
    });

    if (recipient) {
      setRecipient({
        ...recipient,
        creditParty: {
          ...recipient.creditParty,
          ...(creditParty?.bankBranchCode && {
            bankBranchCode: creditParty.bankBranchCode,
          }),
        },
      });
    }
    setHasPayerSpeed(false);
    localStorage.setItem(PAYER_ROUTING_CHANNEL_TYPE, String(channel));

    if (prevStep) {
      setStep(prevStep as ChooseRecipientStep);
    }
  }

  function handleEnterAnotherCode() {
    dispatchGlobalState({
      type: 'ADD',
      payload: {
        ...recipientFormValues,
        payerId: '',
        creditParty: {
          ...creditParty,
          bankBranchCode: '',
        },
      },
      key: RECIPIENT_FORM_VALUES,
    });
    if (recipient) {
      setRecipient({
        ...recipient,
        payerId: 0,
        creditParty: {
          ...recipient.creditParty,
          bankBranchCode: '',
        },
      });
    }
    setFieldValue('payerId', '');
    setIsValidatingBankCode(false);
  }

  function handleCloseButton() {
    handleEnterAnotherCode();
    popDialog();
  }

  return (
    <div className={styled.root}>
      <h5 className="title">{title}</h5>
      <div className="description">{description}</div>
      <SpeedInfoCard availableChannel={channel} />
      <div className="action-button">
        <Button fullWidth onClick={handleChangeDeliverySpeed}>
          {changeButton}
        </Button>
        <Button fullWidth type="secondary" onClick={handleCloseButton}>
          {cancelButton}
        </Button>
      </div>
    </div>
  );
}

const styled = {
  root: css`
    .title {
      margin-bottom: 0.5rem;
    }
    .description {
      color: var(--neutral-500);
      margin-bottom: 1rem;
    }
    .action-button {
      margin-top: 1.5rem;
      > :not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  `,
};
